import _ from "lodash";
import React from "react";

import { Grid, Paper } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

import Financial from "./Financial";

class Receipts extends React.Component {
  getFixed = type => {
    let value = _.get(this.props.values, type) || 0;
    if (isNaN(value)) {
      value = value
        .replace("R$", "")
        .replace(/\./g, "")
        .replace(",", ".");
    }
    value = parseFloat(value) || 0;
    return value.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <h2 className={classes.subtitle}>Plantio Próprio</h2>
            </Grid>
            <Grid item sm={12}>
              <Paper className={classes.subtitlePaper}>
                <h2 className={classes.subheader}>Receitas</h2>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper className={classes.container}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8} className={classes.external}>
                    <span className={classes.internal}>Idade de corte:</span>
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.external}>
                    <Grid container className={classes.internal}>
                      <Grid item sm={12}>
                        <span className={classes.measures}>
                          {this.props.values.cuttingAge || 0} anos
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper className={classes.container}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8} className={classes.external}>
                    <span className={classes.internal}>Área Plantada:</span>
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.external}>
                    <Grid container className={classes.internal}>
                      <Grid item sm={12}>
                        <span className={classes.measures}>
                          {this.getFixed("harvestedArea")} ha
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper className={classes.container}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8} className={classes.external}>
                    <span className={classes.internal}>
                      Produtividade média:
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.external}>
                    <Grid container className={classes.internal}>
                      <Grid item sm={12}>
                        <span className={classes.measures}>
                          {`${this.getFixed("carbonRemoval.avgProductivity")} ${
                            this.props.values.carbonRemoval.avgProductivityUnit
                          }`}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item sm={12}>
              <Paper className={classes.container}>
                <Financial
                  values={this.props.values}
                  handleChange={this.props.handleChange}
                  validate={this.props.validate}
                />
              </Paper>
            </Grid>
          </Grid>
        </main>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Receipts);
