import _ from "lodash";
import FormDefault from "../../../FormDefault";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

import MoneyMask from "../../../Masks/Money";

export const fields = { name: "", yearsOfUse: [] };

class Register extends FormDefault {
  constructor(props) {
    super(props);
    this.state = {
      inputs: [
        {
          id: "name",
          label: "Nome do Equipamento",
          required: true,
          inputProps: { autoFocus: true },
          sm: 8
        },
        {
          type: "number",
          id: "lifespan",
          label: "Vida útil (anos)",
          inputProps: { inputProps: { min: 0 } },
          sm: 4
        },
        {
          type: "multiSelect",
          id: "yearsOfUse",
          helperText: "Selecionar todos os anos em que o equipamento foi utilizado.",
          label: "Anos de uso",
          options: [],
          sm: 12
        },
        {
          id: "acquisitionValue",
          label: "Valor de Aquisição (R$)",
          inputProps: { inputComponent: MoneyMask }
        },
        {
          id: "endOfLifeValue",
          label: "Valor ao final da vida útil (R$)",
          inputProps: { inputComponent: MoneyMask }
        }
      ],
      loading: false 
    };
  }

  setOptions = array => {
    array.options = [];
    const { values } = this.props,
      startProject = _.get(values, "startProject") || 2000,
      endProject = _.get(values, "endProject") || 2050;

    for (let i = startProject; i <= endProject; i++)
      array.options.push(i);
  }

  setYearOptions = () => {
    this.setOptions(this.state.inputs[2]);
  };

  getInputs = () => {
    this.setYearOptions();
    return this.state.inputs;
  };
}

export default withStyles(styles)(Register);
