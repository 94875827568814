import _ from "lodash";
import React from "react";
import { withRouter } from "react-router-dom";

import { Divider, Grid, Paper } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

import GEECalcMachine from "./GEECalcMachine";

import Planted from "./CarbonRemoval/Planted";
import Native from "./CarbonRemoval/Native";

class CarbonRemoval extends GEECalcMachine {
  constructor(props) {
    super(props);

    this.title = "Remoção de Carbono";
    this.nextStep = "/home/viabilidade/madeira/1";
    this.startValues = {
      totalHangingWood: {
        "m³/ano": 0,
        "t/ano": 0,
        "st/ano": 0
      },
      carbonRemoval: {
        woodDemand: 0,
        avgProductivityUnit: "",
        legalReservePercent: 0,
        legalReserve: 0,
        avgProductivity: 0,
        localization: "",
        biome: "",
        situation: "",
        age: "",
        app: 0
      },
      propertyId: this.props.propertyId,
      projectId: this.props.projectId
    };

    this.state = { values: this.copy(this.startValues) };
    if (this.props.projectId) this.reset();
  }

  getFixed = type => {
    let value = _.get(this.state.values, type) || 0;
    value = parseFloat(value);
    return value.toLocaleString("pt-BR", {
      maximumFractionDigits: 1,
      minimumFractionDigits: 1
    });
  };

  main = classes => {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.container}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={7} className={classes.external}>
                  <span className={classes.internal}>
                    Área de floresta plantada
                  </span>
                </Grid>
                <Grid item xs={12} sm={5} className={classes.external}>
                  <Grid container className={classes.internal}>
                    <Grid item sm={12}>
                      <span className={classes.measures}>
                        {this.getFixed("carbonRemoval.woodDemand")} ha
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.container}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} className={classes.external}>
                  <span className={classes.internal}>
                    Área total de floresta nativa
                  </span>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.external}>
                  <Grid container className={classes.internal}>
                    <Grid item sm={12}>
                      <span className={classes.measures}>
                        {this.getFixed("carbonRemoval.totalNativeArea")} ha
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.root}>
          <Grid item xs={12} sm={6}>
            <h2 className={classes.subtitle}>Floresta Plantada</h2>
            <Paper className={classes.container}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} className={classes.external}>
                  <span className={classes.internal}>Demanda de madeira</span>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.external}>
                  <Grid container className={classes.internal}>
                  <Grid item sm={12}>
                      <span className={classes.measures}>
                        {this.getFixed('totalHangingWood["m³/ano"]')} m³/ano
                      </span>
                    </Grid>                    
                    <Grid item sm={12}>
                      <span className={classes.measures}>
                        {this.getFixed('totalHangingWood["st/ano"]')} st/ano
                      </span>
                    </Grid>
                    <Grid item sm={12}>
                      <span className={classes.measures}>
                        {this.getFixed('totalHangingWood["t/ano"]')} t/ano
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Planted
                values={this.state.values}
                handleChange={this.handleChange}
                errors={this.state.errors}
                validate={this.validate}
                updateValue={this.updateValue}
                updateCalculatedValues={this.updateCalculatedValues}
              />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={8} className={classes.external}>
                  <span className={classes.internal}>
                    De acordo com a produtividade média, para atender a demanda
                    anual de madeira para carbonização a Área colhida é:
                  </span>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.external}>
                  <Grid container className={classes.internal}>
                    <Grid item sm={12}>
                      <span className={classes.measures}>
                        {this.getFixed("carbonRemoval.woodDemand")} ha
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <h2 className={classes.subtitle}>Floresta Nativa</h2>
            <Paper className={classes.container}>
              <Native
                values={this.state.values}
                handleChange={this.handleChange}
                errors={this.state.errors}
                validate={this.validate}
                updateValue={this.updateValue}
                updateCalculatedValues={this.updateCalculatedValues}
              />
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
}

export default withRouter(withStyles(styles)(CarbonRemoval));
