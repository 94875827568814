import _ from "lodash";
import CalcMachine from "../CalcMachine/CalcMachine";

import divide from "../CalcMachine/divide";

export default class WoodCalcMachine extends CalcMachine {
  calcCaptionPeriods = values => {
    const times = {
      Anual: 1,
      Mensal: 12,
      Bimestral: 6,
      Trimestral: 4,
      Semestral: 2
    };
    values.capturePeriods = values.planningHorizon * times[values.charge];
  };

  calcDemand = values => {
    let type = _.get(values, "carbonRemoval.avgProductivityUnit");
    if (type) type = type.replace("ha", "ano");
    _.set(
      values,
      "carbonRemoval.woodDemand",
      divide(
        this.get(`totalHangingWood[${type}]`),
        this.get("carbonRemoval.avgProductivity")
      )
    );
  };

  calcWoodProduction = values => {
    let type = _.get(values, "carbonRemoval.avgProductivityUnit");
    if (type) type = type.replace("/ha", "");

    const wayOfObtainingWood = _.get(values, "wayOfObtainingWood");

    if (wayOfObtainingWood === "Compra") {
      _.set(values, "totalWoodProduction", 1);
    } else {
      this.calculate(`woodTotalProduction['${type}']`);
      _.set(
        values,
        "totalWoodProduction",
        _.get(values, `woodTotalProduction['${type}']`)
      );
    }
  };

  getMachinesAndEquipaments = (values, array, year) => {
    const machinesAndEquipaments = _.filter(values[array], 
      (o) => o.yearsOfUse && o.yearsOfUse.indexOf(values.startProject + year) > -1
    ) || [];        

    return _.sumBy(machinesAndEquipaments, "yearlyCost") || 0;    
  };

  getCasualWorkers = (values, array, year) => {
    const f = n => parseFloat(n);
    let yearCost = 0;
    values[array].forEach((worker) => {
      yearCost += _.sumBy(_.get(worker, "signings"), (o) => {
        if(o.year === values.startProject + year) return f(o.workday) * f(o.payment);
      }) || 0;
    });
    return yearCost;
  }

  getPermanentEmployees = (values, array, year) => {
    const f = n => parseFloat(n);
    let yearCost = 0;
    values[array].forEach((worker) => {
      yearCost = worker.salary * 12;
      const charges = _.reduce(worker.charges, (r, charge) => { return r + f(charge.percent) }, 0);
      yearCost += (charges / 100) * yearCost;
      yearCost += _.sumBy(_.get(worker, "ppes"), (o) => {
        if(o.year === values.startProject + year) return o.total;
      }) || 0;
    });

    return yearCost;
  }

  calcDiscriminateValues = values => {
    const size = values.planningHorizon + 1 || 0,
      cjs = [];
    let costs = [];

    const f = n => parseFloat(n);
    const _setCj = (year) => 
      cjs.push(costs[year] * Math.pow(1 + (values.interestRate / 100), - year));

    [...Array(size).keys()].forEach((year) => {
      const fields = ["plantingInputs", "maintenanceInputs", "harvestInputs", "transportInputs", "permanentEmployee", "casualWorker", "machines", "equipaments"];
      let yearCost = fields.reduce((r, array) => {
        let yearCost = 0;
        yearCost += _.sumBy(values[array], (o) => { 
          if(o.year === values.startProject + year) return f(o.total);
        }) || 0;

        yearCost += this.getMachinesAndEquipaments(values, array, year);
        if(array === "casualWorker")
          yearCost += this.getCasualWorkers(values, array, year);
        if(array === "permanentEmployee")
          yearCost += this.getPermanentEmployees(values, array, year);

        const admin = (array === "equipaments") ? r * (values.administrationFee / 100) : 0;
        return r + yearCost + admin;
      }, f(values.yearlyCost));

      costs.push(-yearCost);
      _setCj(year);
    });

    values.cjs = cjs;
    values.costs = costs;
    values.cj = -(_.sum(cjs));

    return -(_.sum(_.uniq(costs)));
  };

  calcSplitedCosts = (totalCost, wayOfObtainingData) => {
    const { values } = this.state || {},
      size = values.cuttingAge + 1 || 0,
      costs = [],
      cjs = [];

    const _setCj = (year) => 
      cjs.push(costs[year] * Math.pow(1 + (values.interestRate / 100), - year));

    if(wayOfObtainingData === "Valor fixo único") {
      [...Array(size).keys()].forEach((year) => {
        costs.push(-totalCost / size);
        _setCj(year);
      });
    } else {
      [...Array(size).keys()].forEach((year) => {
        if(year === 0) costs.push(-_.get(values, "planting.totalCost"));
        else if(year === values.cuttingAge) {
          const total = 
            _.get(values, "maintenance.totalCost") + 
            _.get(values, "harvest.totalCost") +
            _.get(values, "transport.totalCost");        
          costs.push(-total);
        }
        else costs.push(-_.get(values, "maintenance.totalCost"));
        _setCj(year);
      });
    }

    values.costs = costs;
    values.cjs = cjs;
    values.cj = -(_.sum(cjs));
  };

  calcTotalCost = values => {
    const wayOfObtainingWood = _.get(values, "wayOfObtainingWood"),
      wayOfObtainingData = _.get(values, "wayOfObtainingData");

    let totalCost = 0;
    if (wayOfObtainingData === "Valores discriminados") {
      totalCost = this.calcDiscriminateValues(values);
    } else {
      this.calculate(`totalCost['${wayOfObtainingData}']`);
      totalCost = _.get(values, `totalCost['${wayOfObtainingData}']`);
      this.calcSplitedCosts(totalCost, wayOfObtainingData);
    }

    if (wayOfObtainingWood === "Compra") _.set(values, "totalCost", 0);
    else _.set(values, "totalCost", totalCost);
    this.calculate("rj");
  };

  calcBuyWoodAndCost = values => {
    const wayOfObtainingWood = _.get(values, "wayOfObtainingWood") || "";

    if (wayOfObtainingWood.indexOf("Compra") > -1) {
      const buyWood = { st: 0, t: 0, "m³": 0 };
      let buyCost = 0;

      values.footWoodPurchase.forEach(p => {
        buyWood[p.unit] += p.count;
        buyCost += p.total;
      });
      values.courtyardWoodPurchase.forEach(p => {
        buyWood[p.unit] += p.count;
        buyCost += p.total;
      });

      _.set(values, "buyWood", buyWood);
      _.set(values, "buyCost", buyCost);
    }
  };

  calcViability = values => {
    _.set(
      values,
      "viability",
      this.get("coalPrice") >= parseFloat(_.get(values, "viableCost")).toFixed(2)
    );
  };

  calcPlanningHorizon = values => {
    const planningHorizon = values.endProject - values.startProject;
    _.set(values, "planningHorizon", planningHorizon.toFixed(0));
  }

  updateCalculatedValues = () => {
    const values = this.state.values;

    this.calcCaptionPeriods(values);
    this.calcDemand(values);
    this.calcWoodProduction(values);
    this.calcTotalCost(values);
    _.set(values, "tir", this.calcTir(values));
    this.calcBuyWoodAndCost(values);
    this.calcPlanningHorizon(values);
    this.refreshAlways();
    this.calcViability(values);
    this.updateValue("", "");
  };
}
