import React from "react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const defaultMaskOptions = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: "",
  allowDecimal: true,
  decimalSymbol: ",",
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
  requireDecimal: 1,
  decimalLimit: 1,
};

const toFixed = (value) => {
  value = value.replace(",", ".");
  value = parseFloat(value).toFixed(1);
  value = value.replace(".", ",");
  return value;  
}

const CurrencyInput = ({ inputRef, ...other }) => {
  other.value = toFixed(other.value);
  const currencyMask = createNumberMask(defaultMaskOptions);

  return (
    <MaskedInput
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={currencyMask}
      {...other}
    />
  );
};

export default CurrencyInput;