import React from "react";

import FormDefault from "../../../../FormDefault";

import { Grid } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

export const fields = {};

class Economic extends FormDefault {
    constructor(props) {
        super(props);
        this.state = {
            dataObtentionWays: [
                "Valor fixo por etapa",
                "Valor fixo único",
                "Valores discriminados",
            ],
        };
    }

    changeObtainingData = (event) => {
        this.props.updateValue("wayOfObtainingData", event.target.value);
        this.props.resetValues();
        this.props.save();
    };

    updateDataObtentionWays = (cuttingRegime) => {
        if (
            cuttingRegime ===
            "Primeiro e segundo cortes (alto fuste e talhadia)"
        )
            this.setState({ dataObtentionWays: ["Valores discriminados"] });
        else
            this.setState({
                dataObtentionWays: [
                    "Valor fixo por etapa",
                    "Valor fixo único",
                    "Valores discriminados",
                ],
            });
    };

    changeCuttingRegime = (event) => {
        this.props.updateValue("cuttingRegime", event.target.value);
        this.changeObtainingData({ target: { value: "" } });
        this.updateDataObtentionWays(event.target.value);
    };

    getInputs = () => {
        const { classes } = this.props;
        return [
            {
                type: "select",
                id: "cuttingRegime",
                label: "Regime de Corte",
                options: [
                    "Primeiro corte (alto fuste)",
                    "Talhadia",
                    "Primeiro e segundo cortes (alto fuste e talhadia)",
                    "Reforma (novo plantio)",
                ],
                inputProps: { onChange: this.changeCuttingRegime },
                sm: 12,
            },
            {
                type: "number",
                id: "planningHorizon",
                label: "Horizonte de Planejamento",
                disabled: true,
                inputProps: { inputProps: { min: 0 } },
                sm: 10,
            },
            {
                render: (
                    <Grid item sm={2} className={classes.external}>
                        <span className={classes.internal}>Anos</span>
                    </Grid>
                ),
            },
            {
                type: "select",
                id: "wayOfObtainingData",
                label: "Forma de obtenção dos dados",
                options: this.state.dataObtentionWays,
                helperText: "As opções distinguem-se entre si pelo nível de detalhamento dos dados coletados. Valor fixo único: apenas custo médio de produção. Valor fixo por etapa: custo médio de produção nas etapas de plantio, manutenção, colheita e transporte. Valores discriminados: detalhamento dos custos de produção em todas as etapas, incluindo custos com insumos, mão de obra, máquinas e equipamentos, custo da terra e taxas administrativas.",
                inputProps: { onChange: this.changeObtainingData },
                sm: 12,
            },
        ];
    };
}

export default withStyles(styles)(Economic);
