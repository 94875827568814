import React from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import axios from "axios";
import authRequest from "../authRequest";

import { ValidatorForm } from "react-material-ui-form-validator";

import {
  CssBaseline,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Snackbar
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

import Copyright from "../Copyright";
import Address from "./Address";
import Personal from "./Personal";
import Login from "./Login";

const steps = ["Cadastro Pessoal", "Endereço", "E-mail e Senha"];

class Register extends React.Component {
  constructor(props) {
    super(props);
    const user = props.location.state || {};

    this.state = {
      activeStep: 0,
      errors: {},
      open: "",
      values: {
        _id: user._id || null,
        birth: user.birth || new Date(),
        name: user.name || "",
        genre: user.genre || "",
        cellphone: user.cellphone || "",
        telephone: user.telephone || "",
        schooling: user.schooling || "",
        cpf: user.cpf || "",
        zipcode: user.zipcode || "",
        street: user.street || "",
        number: user.number || "",
        complement: user.complement || "",
        district: user.district || "",
        city: user.city || "",
        state: user.state || "",
        region: user.region || "",
        email: user.email || "",
        emailConfirm: "",
        password: "",
        passwordConfirm: ""
      }
    };
  }

  handleOpen = message => {
    this.setState({ open: message });
  };

  handleClose = (event, reason) => {
    this.setState({ open: "" });
  };

  freeButton = step => {
    let free = true;
    const steps = {
      0: ["name", "genre", "birth", "cpf", "cellphone", "schooling"],
      1: ["zipcode", "street", "number", "district", "city", "state", "region"],
      2: ["email", "emailConfirm", "password", "passwordConfirm"]
    };

    steps[step].forEach(field => {
      if (!this.state.values[field] || this.state.errors[field]) free = false;
    });

    return free;
  };

  register = async () => {
    const { values } = this.state;
    try {
      const user = {
        email: values.email,
        password: values.password,
        profile: {
          name: values.name,
          birth: values.birth,
          genre: values.genre,
          cellphone: values.cellphone,
          telephone: values.telephone,
          schooling: values.schooling,
          cpf: values.cpf,
          zipcode: values.zipcode,
          street: values.street,
          number: values.number,
          complement: values.complement,
          district: values.district,
          city: values.city,
          state: values.state,
          region: values.region
        },
        roles: ["engineer"]
      };

      if (this.state.values._id)
        await authRequest("PUT", `users/${this.state.values._id}`, user);
      else {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/register`, user);
        this.setState({ open: "Usuário cadastrado com sucesso" });
        const token = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/login`,
          { email: values.email, password: values.password }
        );

        window.localStorage.authToken = token.data.token;
      }

      this.props.history.push("/home/dashboard");
    } catch (ex) {
      this.setState({ open: ex.response.data || "Ocorreu algum erro." });
    }
  };

  handleNext = async () => {
    if (this.state.activeStep !== steps.length - 1)
      this.setState({ activeStep: this.state.activeStep + 1 });
    else await this.register();
  };

  handleBack = () => {
    if (this.state.activeStep === 0) this.props.history.push("/");
    else this.setState({ activeStep: this.state.activeStep - 1 });
  };

  handleChange = name => event => {
    this.setState({
      values: { ...this.state.values, [name]: event.target.value }
    });
  };

  handleDateChange = date => {
    this.setState({
      values: { ...this.state.values, birth: date }
    });
  };

  removeSpecialCharacters = (field = "") => {
    return field.toString().replace(/[^A-Za-z0-9]+/g, "");
  };

  validate = (field, message, condition) => {
    const errors = this.state.errors;
    if (!condition)
      condition =
        _.get(this.state, `values.${field}`) === null ||
        this.removeSpecialCharacters(_.get(this.state, `values.${field}`))
          .length === 0;
    if (condition) _.set(errors, field, message);
    else _.set(errors, field, "");
    this.setState({ errors });
  };

  getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <Personal
            values={this.state.values}
            errors={this.state.errors}
            validate={this.validate}
            handleOpen={this.handleOpen}
            handleChange={this.handleChange}
            handleDateChange={this.handleDateChange}
          />
        );
      case 1:
        return (
          <Address
            values={this.state.values}
            errors={this.state.errors}
            validate={this.validate}
            handleChange={this.handleChange}
          />
        );
      case 2:
        return (
          <Login
            values={this.state.values}
            errors={this.state.errors}
            validate={this.validate}
            handleOpen={this.handleOpen}
            handleChange={this.handleChange}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <img src="/logo.png" alt="logo" className={classes.backimage} />
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Stepper
              activeStep={this.state.activeStep}
              className={classes.stepper}
            >
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              <ValidatorForm onSubmit={this.register}>
                {this.getStepContent(this.state.activeStep)}
                <div className={classes.buttons}>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={this.handleBack}
                    className={classes.button}
                  >
                    {this.state.activeStep === 0 ? "Cancelar" : "Voltar"}
                  </Button>
                  <Button
                    fullWidth
                    disabled={!this.freeButton(this.state.activeStep)}
                    variant="contained"
                    color="primary"
                    onClick={this.handleNext}
                    className={classes.button}
                  >
                    {this.state.activeStep === steps.length - 1
                      ? this.state.values._id
                        ? "Atualizar"
                        : "Cadastrar"
                      : "Próximo"}
                  </Button>
                </div>
              </ValidatorForm>
            </React.Fragment>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              key="message"
              open={this.state.open ? true : false}
              onClose={this.handleClose}
              ContentProps={{
                "aria-describedby": "message-id"
              }}
              message={<span id="message-id">{this.state.open}</span>}
            />
          </Paper>
          <Copyright />
        </main>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(Register));
