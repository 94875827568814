const calcs = {
  always: {
    avgProductivity:
      "(30 - (downtime || 30)) / (avgDurationOfCarbonizationCicle || 1)",
    "woodHangedByYear['m³/forno']":
      "woodHangedByCicle['m³/forno'] * avgProductivity * 12",
    "woodHangedByYear['t/forno']":
      "woodHangedByCicle['t/forno'] * avgProductivity * 12",
    "totalHangingWood['m³/ano']": "woodHangedByYear['m³/forno'] * ovenAmmount",
    "totalHangingWood['t/ano']": "woodHangedByYear['t/forno'] * ovenAmmount",
    "totalHangingWood['st/ano']": "totalHangingWood['m³/ano'] / stackingFactor",
    "drySeasonProductionPeriod['t/forno']":
      "(drySeasonProductionPeriod['mdc/forno'] * bulkDensity) / 1000",
    "drySeasonProductionPeriod['mdc/ano']":
      "drySeasonProductionPeriod['mdc/forno'] * avgProductivity * drySeasonProductionPeriod.workingPeriod * ovenAmmount",
    "drySeasonProductionPeriod['t/ano']":
      "drySeasonProductionPeriod['t/forno'] * avgProductivity * drySeasonProductionPeriod.workingPeriod * ovenAmmount",
    "rainySeasonProductionPeriod['mdc/forno']":
      "drySeasonProductionPeriod['mdc/forno'] * 0.75",
    "rainySeasonProductionPeriod['t/forno']":
      "drySeasonProductionPeriod['t/forno'] * 0.75",
    "rainySeasonProductionPeriod['mdc/ano']":
      "rainySeasonProductionPeriod['mdc/forno'] * avgProductivity * rainySeasonProductionPeriod.workingPeriod * ovenAmmount",
    "rainySeasonProductionPeriod['t/ano']":
      "rainySeasonProductionPeriod['t/forno'] * avgProductivity * rainySeasonProductionPeriod.workingPeriod * ovenAmmount",
    "totalProduction['mdc/ano']":
      "drySeasonProductionPeriod['mdc/ano'] + rainySeasonProductionPeriod['mdc/ano']",
    "totalProduction['t/ano']":
      "drySeasonProductionPeriod['t/ano'] + rainySeasonProductionPeriod['t/ano']",
    totalGenerationOfServings:
      "totalHangingWood['t/ano'] * (generationOfServings / 100)",
    "coalProductionUnit.total":
      "totalHangingWood['t/ano'] * (coalProductionUnit.value / 100)",
    "loadingAndShipping.total":
      "totalHangingWood['t/ano'] * (loadingAndShipping.value / 100)",
    "sifting.total": "totalHangingWood['t/ano'] * (sifting.value / 100)",
    totalGenerationOfFines:
      "coalProductionUnit.total + loadingAndShipping.total + sifting.total",
    "carbonRemoval.legalReserve":
      "(carbonRemoval.legalReservePercent / 100) * carbonRemoval.woodDemand",
    "carbonRemoval.totalNativeArea":
      "carbonRemoval.legalReserve + carbonRemoval.app",
    totalProductionPerPlantingCycle:
      "carbonRemoval.avgProductivity * harvestedArea",
    totalRecipes: "totalProductionPerPlantingCycle * avgWoodSellingPrice",
    totalCostOfProduction: "avgCost * totalProductionPerPlantingCycle",
    avgCostOfProduction: "totalCostOfProduction / cuttingAge",
    "planting.totalCost": "planting.avgCost * harvestedArea",
    "maintenance.totalCost": "maintenance.avgCost * harvestedArea",
    "harvest.totalCost": "harvest.avgCost * harvestedArea",
    "transport.totalCost":
      "transport.avgCost * harvestedArea * carbonRemoval.avgProductivity",
    nativeForestArea: "carbonRemoval.legalReserve + carbonRemoval.app",
    totalArea: "nativeForestArea + harvestedArea + maintenanceAreas",
    yearlyCost: "avgLandPrice * totalArea * (interestRate / 100)",
    productionMaxViableCost:
      "(coalPrice - (coalProductionCost + fareCost)) / 1.35",
    detailedObtentionCost: "totalCost / totalWoodProduction",
    woodTotalProductionBuy:
      "buyWood['m³'] + (buyWood['st'] * stackingFactor) + ((buyWood['t'] / basicDensity) * 1000)",
    detailedObtentionBuyCost: "buyCost / woodTotalProductionBuy",
    woodTotalProductionSum:
      "woodTotalProductionBuy + woodTotalProduction['m³']",
    detailedObtentionCostSum:
      "(totalCost + buyCost) / (totalWoodProduction + woodTotalProductionBuy)",
    viableCost: "1.35 * detailedObtentionCost + coalProductionCost + fareCost",
    "ovens.ovenAmmount": "ovenAmmount",
    "ovens.totalCost": "ovens.avgCost * ovens.ovenAmmount",
    "ovens.totalYearlyCost": "ovens.avgYearlyCost * ovens.ovenAmmount",
    "infrastructure.totalCost":
      "(infrastructure.construction / 100) * ovens.totalCost",
    "infrastructure.totalYearlyCost":
      "(infrastructure.maintenance / 100) * ovens.totalYearlyCost",
    "furnace.totalCost": "furnace.avgCost * furnace.furnaceAmmount",
    "furnace.totalYearlyCost": "furnace.avgYearlyCost * furnace.furnaceAmmount",
    earthYearlyCost:
      "charcoalProductionUnitArea * avgLandPrice * (interestRate / 100)",
    vpl: "rj - cj",
    vpe:
      "(vpl * (((1 + (interestRate / 100)) ** (+ capturePeriods)) - 1)) / (1 - ((1 + (interestRate / 100)) ** (- planningHorizon * capturePeriods)))",
    bc: "rj / cj",
    hectareVpl: "vpl / harvestedArea",
    hectareVpe: "vpe / harvestedArea",
    "emissionFactor['ch4']": "(217.12 - (534.76 * (rg.value / 100))) / 1000",
    totalEmission: "totalProduction['t/ano'] * emissionFactor['ch4']",
    burn: "totalEmission * (burnPercent / 100)",
    "methane.year['tCH4']": "totalEmission - burn",
    "methane.cicle['tCH4']": "methane.year['tCH4'] / (avgProductivity * 12)",
    "methane.tonne['kgCH4']":
      "(methane.year['tCH4'] / totalProduction['t/ano']) * 1000",
    "methane.mdc['kgCH4']":
      "(methane.year['tCH4'] / totalProduction['mdc/ano']) * 1000",
    "methane.year['tCO2e']": "methane.year['tCH4'] * 25",
    "methane.cicle['tCO2e']": "methane.year['tCO2e'] / (avgProductivity * 12)",
    "methane.tonne['kgCO2e']":
      "(methane.year['tCO2e'] / totalProduction['t/ano']) * 1000",
    "methane.mdc['kgCO2e']":
      "(methane.year['tCO2e'] / totalProduction['mdc/ano']) * 1000",
    "emissionFactor['co2']":
      "114.165574 - (0.025565 * classCenter) + (0.027518 * (classCenter * classCenter))",
    "efectiveEmission['Sem Queimador']":
      "(totalHangingWood['t/ano'] * emissionFactor['co2']) / 1000",
    "efectiveEmission['Com Queimador']":
      "efectiveEmission['Sem Queimador'] * 1.8993",
    "carbonEmission.cicle['tCO2e']":
      "carbonEmission.year['tCO2e'] / (avgProductivity * 12)",
    "carbonEmission.tonne['kgCO2e']":
      "(carbonEmission.year['tCO2e'] / totalProduction['t/ano']) * 1000",
    "carbonEmission.mdc['kgCO2e']":
      "(carbonEmission.year['tCO2e'] / totalProduction['mdc/ano']) * 1000",
    "totalEmission.year['tCO2e']":
      "carbonEmission.year['tCO2e'] + methane.year['tCO2e']",
    "totalEmission.cicle['tCO2e']":
      "carbonEmission.cicle['tCO2e'] + methane.cicle['tCO2e']",
    "totalEmission.tonne['tCO2e']":
      "(carbonEmission.tonne['kgCO2e'] + methane.tonne['kgCO2e']) / 1000",
    "totalEmission.mdc['tCO2e']":
      "(carbonEmission.mdc['kgCO2e'] + methane.mdc['kgCO2e']) / 1000",
    "mgAvgProductivity['t/ha']": "carbonRemoval.avgProductivity",
    "mgAvgProductivity['st/ha']":
      "(carbonRemoval.avgProductivity * stackingFactor * basicDensity) / 1000",
    "mgAvgProductivity['m³/ha']":
      "(carbonRemoval.avgProductivity * basicDensity) / 1000",
    areaRl:
      "(carbonRemoval.legalReservePercent / 100) * carbonRemoval.woodDemand",
    areaApp: "(carbonRemoval.app * carbonRemoval.woodDemand) / size",
    totalCarbonContent: "totalProduction['t/ano'] * (fixedCarbonContent / 100)",
    "totalBalance['tCO2e/t Carbono fixo']":
      "totalBalance['tCO2e/ano'] / totalCarbonContent",
    "pigIronProduction['t Carbono fixo/t Gusa']": "450 / 1000",
    "pigIronProduction['tCO2e/t Gusa']":
      "pigIronProduction['t Carbono fixo/t Gusa'] * totalBalance['tCO2e/t Carbono fixo']",
  },
  "woodHangedByCicle['m³/forno']":
    "woodHangedByCicle['st/forno'] * stackingFactor",
  "woodHangedByCicle['st/forno']":
    "woodHangedByCicle['m³/forno'] / stackingFactor",
  "woodHangedByCicle['t/forno']":
    "(woodHangedByCicle['m³/forno'] * basicDensity) / 1000",
  "woodTotalProduction['m³']": "totalProductionPerPlantingCycle",
  "woodTotalProduction['st']":
    "(totalProductionPerPlantingCycle * stackingFactor)",
  "woodTotalProduction['t']":
    "(totalProductionPerPlantingCycle / bulkDensity) * 1000",
  "totalCost['Valor fixo único']": "avgCostOfProduction * (cuttingAge + 1)",
  "totalCost['Valor fixo por etapa']":
    "planting.totalCost + (maintenance.totalCost * cuttingAge) + harvest.totalCost + transport.totalCost",
  "rg.value": "(totalProduction['t/ano'] / totalHangingWood['t/ano']) * 100",
  "increment['plantedArea']": "avgProductivityMg * 0.47",
  plantedArea:
    "increment['plantedArea'] * carbonRemoval.woodDemand * (44 / 12)",
  nativeFlorest: "(areaRl + areaApp) * increment['nativeFlorest'] * (44 / 12)",
  totalRemotion: "plantedArea + nativeFlorest",
  "totalBalance['tCO2e/ano']": "totalRemotion - totalEmission.year['tCO2e']",
  bulkDensity: "basicDensity * 0.399",
  rj: "totalRecipes * (1 + (interestRate / 100)) ** (- cuttingAge)",
  detailedCoalObtentionCost: "totalCost / coalTotalProduction",
  stackingFactor: "1 / 1.2879",
};

export default calcs;
