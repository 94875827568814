import MoneyMask from "../../../../Masks/Money";
import FormDefault from "../../../../FormDefault";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

export const fields = {
  woodPurchase: []
};

class Register extends FormDefault {
  constructor(props) {
    super(props);
    this.state = {
      Receitas: [
        {
          id: "recipes.0.year",
          label: "Ano",
          type: "select",
          helperText: "Ano de venda do carvão.",
          rightBaloon: true,
          options: [],
          sm: 2
        },
        {
          type: "select",
          id: "recipes.0.unit",
          label: "Unidade",
          options: ["mdc", "t"],
          sm: 2
        },
        {
          id: "recipes.0.count",
          label: "Quantidade",
          type: "number",
          inputProps: { inputProps: { min: 0 } },
          sm: 2
        },
        {
          id: "recipes.0.avgPrice",
          label: "Preço médio (R$/unidade)",
          helperText: "Preço médio de venda do carvão.",
          inputProps: { inputComponent: MoneyMask },
          sm: 4
        }
      ],
      inputs: [
        {
          title: "Receitas",
          value: "recipes",
          fields: []
        }
      ]
    };
  }

  setOptions = array => {
    array.options = [];

    const { values } = this.props;

    for (let i = values.startProject; i <= values.endProject; i++)
      array.options.push(i);
  }

  setYearOptions = () => {
    this.setOptions(this.state["Receitas"][0]);
  };

  getInputs = () => {
    this.setYearOptions();
    return this.state.inputs;
  };
}

export default withStyles(styles)(Register);
