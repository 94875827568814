import _ from "lodash";
import FormDefault from "../../../FormDefault";
import MoneyMask from "../../../Masks/Money";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

export const fields = {
  name: "",
  charges: [],
  ppes: [],
  signings: []
};

class Register extends FormDefault {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      Encargos: [
        {
          type: "select",
          id: "charges.0.type",
          label: "Tipo",
          options: [
            "13º Salário",
            "Férias",
            "FGTS",
            "Seguros",
            "Vale Transporte",
            "Vale Alimentação",
            "Auxílio Doença",
            "Outro"
          ],
          sm: 5
        },
        {
          id: "charges.0.percent",
          label: "Percentual (%)",
          type: "number",
          inputProps: { inputProps: { min: 0, max: 100 } },
          sm: 5
        }
      ],
      "EPI's": [
        {
          id: "ppes.0.year",
          label: "Ano",
          type: "select",
          helperText: "Ano de compra do EPI.",
          options: [],
          sm: 2
        },
        {
          type: "select",
          id: "ppes.0.type",
          label: "Tipo",
          options: [
            "Capuz ou Balaclava",
            "Braçadeira",
            "Calça",
            "Calçado",
            "Capacete",
            "Luvas",
            "Macacão",
            "Meia",
            "Óculos",
            "Perneira",
            "Protetor Auditivo",
            "Protetor Facial",
            "Respirador Purificador",
            "Vestimenta",
            "Outros"
          ],
          sm: 4
        },        
        {
          id: "ppes.0.count",
          label: "Quantidade",
          type: "number",
          inputProps: { inputProps: { min: 0 } },
          sm: 2
        },
        {
          id: "ppes.0.unitValue",
          label: "Valor Unitário",
          inputProps: { inputComponent: MoneyMask },
          sm: 2
        }
      ],
      Contratações: [
        {
          id: "signings.0.year",
          label: "Ano",
          helperText: "Ano(s) em que o funcionário foi contratado.",
          type: "select",
          options: [],
          sm: 2
        },
        {
          id: "signings.0.workday",
          label: "Jornada de Trabalho",
          type: "number",
          inputProps: { inputProps: { min: 0 } },
          sm: 2
        },
        {
          type: "select",
          id: "signings.0.unit",
          label: "Unidade",
          options: ["Dias", "Horas", "Semanas"],
          sm: 2
        },
        {
          id: "signings.0.payment",
          label: "Pagamento (R$/unid. de trabalho)",
          inputProps: { inputComponent: MoneyMask },
          sm: 4
        }
      ],
      permanentInputs: [
        {
          id: "name",
          label: "Nome do Funcionário",
          required: true,
          inputProps: { autoFocus: true },
          sm: 8
        },
        {
          id: "salary",
          label: "Salário (mensal)",
          required: true,
          sm: 4,
          inputProps: { inputComponent: MoneyMask }
        },
        {
          title: "Encargos",
          value: "charges",
          fields: []
        },
        {
          title: "EPI's",
          value: "ppes",
          fields: []
        }
      ],
      casualInputs: [
        {
          id: "name",
          label: "Nome do Funcionário",
          required: true,
          inputProps: { autoFocus: true },
          sm: 12
        },
        {
          title: "Contratações",
          value: "signings",
          fields: []
        }
      ],
      current: null
    };
  }

  setOptions = array => {
    array.options = [];
    const { values } = this.props,
      startProject = _.get(values, "startProject") || 2000,
      endProject = _.get(values, "endProject") || 2100;

    for (let i = startProject; i <= endProject; i++)
      array.options.push(i);
  }

  setYearOptions = () => {
    this.setOptions(this.state["Contratações"][0]);
    this.setOptions(this.state["EPI's"][0]);
  };

  getInputs = () => {
    if (this.props.values.type === "permanentEmployee")
      this.state.inputs = this.state.permanentInputs;
    else this.state.inputs = this.state.casualInputs;

    this.setYearOptions();
    return this.state.inputs;
  };
}

export default withStyles(styles)(Register);
