import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon,
  Collapse
} from "@material-ui/core";

import DashboardIcon from "@material-ui/icons/Dashboard";
import ListIcon from "@material-ui/icons/List";
import HomeIcon from "@material-ui/icons/Home";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import PeopleIcon from "@material-ui/icons/People";
import BuildIcon from '@material-ui/icons/Build';
import AssignmentIcon from "@material-ui/icons/Assignment";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ContactsIcon from '@material-ui/icons/Contacts';
import InfoIcon from '@material-ui/icons/Info';

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#284243"
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  imageIcon: {
    height: "100%",
    width: "100%",
    color: "white"
  },
  iconRoot: {
    textAlign: "center",
    color: "white"
  }
}));

export default function LeftList({
  selectedIndex,
  handleListItemClick,
  initialOpen
}) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(initialOpen);

  const handleClick = (event, index) => {
    if (open === index) setOpen(null);
    else setOpen(index);
  };

  const getIcon = src => {
    return (
      <Icon classes={{ root: classes.iconRoot }}>
        <img className={classes.imageIcon} src={src} alt="madeira" />
      </Icon>
    );
  };

  const menu = [
    {
      icon: <DashboardIcon />,
      primary: "Dashboard",
      secondary: "",
      route: "/dashboard",
      divider: true
    },
    {
      icon: <ListIcon />,
      primary: "Cadastros",
      route: "/cadastro",
      subitens: [
        {
          icon: <HomeIcon />,
          primary: "Propriedades",
          secondary: "",
          route: "/cadastro/propriedades"
        },
        {
          icon: <AccountTreeIcon />,
          primary: "Projetos",
          secondary: "",
          route: "/cadastro/projetos"
        },
        {
          icon: <PeopleIcon />,
          primary: "Funcionários",
          secondary: "",
          route: "/cadastro/funcionarios"
        },
        {
          icon: <BuildIcon />,
          primary: "Equipamentos",
          secondary: "",
          route: "/cadastro/equipamentos"
        },
        {
          icon: getIcon("/assets/tractor.png"),
          primary: "Máquinas",
          secondary: "",
          route: "/cadastro/maquinas"
        }
      ]
    },
    {
      icon: getIcon("/assets/carbon.png"),
      primary: "Emissão de gases de efeito estufa",
      route: "/emissoes",
      subitens: [
        {
          icon: getIcon("/assets/wood.png"),
          primary: "Madeira",
          secondary: "",
          route: "/emissoes/madeira"
        },
        {
          icon: getIcon("/assets/charcoal.png"),
          primary: "Carvão Vegetal",
          secondary: "",
          route: "/emissoes/carvao"
        }
      ]
    },
    {
      icon: getIcon("/assets/carbonremove.png"),
      primary: "Remoção de Carbono",
      secondary: "",
      route: "/remocao"
    },
    {
      icon: getIcon("/assets/wood.png"),
      primary: "Viabilidade Econômica",
      secondary: "(Madeira)",
      route: "/viabilidade/madeira/1"
    },
    {
      icon: <MonetizationOnIcon />,
      primary: "Custo máximo da madeira e mínimo do carvão viáveis",
      route: "/viabilidade/custo"
    },
    {
      icon: getIcon("/assets/charcoal.png"),
      primary: "Viabilidade Econômica",
      secondary: "(Carvão Vegetal)",
      route: "/viabilidade/carvao/1",
      divider: true
    },
    {
      icon: <AssignmentIcon />,
      primary: "Relatórios",
      route: "/relatorios",
      subitens: [
        {
          icon: getIcon("/assets/carbon.png"),
          primary: "Balanço de carbono",
          secondary: "",
          route: "/relatorios/balanco"
        },
        {
          icon: getIcon("/assets/carbonremove.png"),
          primary: "Emissões da siderurgia pela carbonização",
          secondary: "",
          route: "/relatorios/emissoes"
        },
        {
          icon: getIcon("/assets/wood.png"),
          primary: "Obtenção de madeira",
          secondary: "",
          route: "/relatorios/madeira"
        },
        {
          icon: getIcon("/assets/charcoal.png"),
          primary: "Produção de carvão vegetal",
          secondary: "",
          route: "/relatorios/carvao"
        }
      ],
      divider: true
    },
    {
      icon:<ContactsIcon/>,
      primary: "Contatos",
      route: "/contatos"
    },
    {
      icon:<InfoIcon/>,
      primary: "Sobre",
      route: "/sobre"
    }
  ];

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      {menu.map(item => {
        return (
          <React.Fragment key={item.route || item.primary}>
            <ListItem
              key={item.route || item.primary}
              button
              selected={selectedIndex === item.route}
              onClick={
                item.subitens
                  ? event => handleClick(event, item.route)
                  : event => handleListItemClick(event, item.route)
              }
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.primary} secondary={item.secondary} />
              {item.subitens ? (
                open === item.route ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )
              ) : null}
            </ListItem>
            {item.divider ? <Divider /> : null}
            {item.subitens ? (
              <Collapse in={open === item.route} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.subitens.map(subitem => (
                    <ListItem
                      key={subitem.route || subitem.primary}
                      button
                      className={classes.nested}
                      selected={selectedIndex === subitem.route}
                      onClick={event =>
                        handleListItemClick(event, subitem.route)
                      }
                    >
                      <ListItemIcon>{subitem.icon}</ListItemIcon>
                      <ListItemText
                        primary={subitem.primary}
                        secondary={subitem.secondary}
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            ) : null}
          </React.Fragment>
        );
      })}
    </List>
  );
}
