import FormDefault from "../../../FormDefault";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

import React from "react";
import { Grid } from "@material-ui/core";

export const fields = {
  ovenType: "",
  ovenAmmount: 0,
  carbTempMonitoring: "",
  gasBurner: ""
};

class Register extends FormDefault {
  constructor(props) {
    super(props);
    this.state = {};
  }

  changeGasBurnType = event => {
    this.props.updateValue("gasBurnerType", event.target.value);
    const burnPercent = event.target.value === "Contínuo" ? 80 : 50;
    this.props.updateValue("burnPercent", burnPercent);
  };

  getInputs = () => {
    return [
      {
        type: "number",
        disabled: true,
        id: "number",
        label: "Nº do Projeto",
        sm: 2,
        required: true
      },
      { render: <Grid item xs={12} sm={10}></Grid> },
      {
        type: "select",
        id: "ovenType",
        label: "Tipo do Forno",
        required: true,
        options: [
          "Circular",
          "Fornos de Encosta",
          "Rabo-quente",
          "Retangular",
          "Outro"
        ]
      },
      {
        type: "number",
        id: "ovenAmmount",
        label: "Quantidade de Fornos",
        inputProps: { inputProps: { min: 0 } },
        required: true
      },
      {
        type: "number",
        id: "ovenMeasures.diameter",
        label: "Diâmetro (m)",
        inputProps: { inputProps: { min: 0 } },
        show: this.props.values.ovenType === "Circular"
      },
      {
        type: "number",
        id: "ovenMeasures.height",
        label: "Altura (m)",
        inputProps: { inputProps: { min: 0 } },
        show: this.props.values.ovenType === "Circular"
      },
      {
        type: "number",
        id: "ovenMeasures.length",
        label: "Comprimento (m)",
        inputProps: { inputProps: { min: 0 } },
        show: this.props.values.ovenType === "Retangular",
        sm: 4
      },
      {
        type: "number",
        id: "ovenMeasures.height",
        label: "Altura (m)",
        inputProps: { inputProps: { min: 0 } },
        show: this.props.values.ovenType === "Retangular",
        sm: 4
      },
      {
        type: "number",
        id: "ovenMeasures.width",
        label: "Largura (m)",
        inputProps: { inputProps: { min: 0 } },
        show: this.props.values.ovenType === "Retangular",
        sm: 4
      },
      {
        id: "other",
        label: "Tipo do Forno",
        show: this.props.values.ovenType === "Outro",
        sm: 12
      },
      {
        type: "select",
        id: "carbTempMonitoring",
        label: "Monitoramento da Temperatura de Carbonização",
        required: true,
        helperText: "O monitoramento da temperatura de carbonização pode ser realizado “com ferramenta” (com auxílio de algum instrumento para medição da temperatura) ou “sem ferramenta” (método empírico).",
        options: ["Com Ferramenta", "Sem Ferramenta"]
      },
      {
        show: this.props.values.carbTempMonitoring !== "Com Ferramenta",
        render: <Grid item xs={12} sm={6}></Grid>
      },
      {
        type: "select",
        id: "toolType",
        label: "Tipo de Ferramenta",
        options: ["Pirometria", "Sistema Supervisório"],
        show: this.props.values.carbTempMonitoring === "Com Ferramenta"
      },
      {
        type: "select",
        id: "gasBurner",
        label: "Queimador de Gases",
        required: true,
        options: ["Com Queimador", "Sem Queimador"],
        sm: 5
      },
      {
        show: this.props.values.gasBurner !== "Com Queimador",
        render: <Grid item xs={12} sm={7}></Grid>
      },
      {
        type: "select",
        id: "gasBurnerType",
        label: "Tipo de Queimador de Gases",
        options: ["Contínuo", "Não Contínuo"],
        helperText: "O queimador de gases pode ser tipo “contínuo” (quando há disponibilidade contínua do gás para a queima) ou não-contínuo (quando não há disponibilidade contínua do gás para a queima).",
        show: this.props.values.gasBurner === "Com Queimador",
        inputProps: { onChange: this.changeGasBurnType },
        sm: 4
      },
      {
        type: "number",
        id: "burnPercent",
        label: "% de queima dos gases",
        helperText: "O sistema fornece valor default, mas que pode ser alterado.",
        inputProps: { inputProps: { min: 0, max: 100 } },
        show: this.props.values.gasBurner === "Com Queimador",
        sm: 3
      }
    ];
  };
}

export default withStyles(styles)(Register);
