import _ from "lodash";
import React from "react";
import { withRouter } from "react-router-dom";
import authRequest from "../../../../authRequest";

import {
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";
import Helper from "../../../../Components/Helper/Helper";

class Workers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      registerOpen: false,
      confirmOpen: false,
      selectedId: "",
      casualWorker: [],
      permanentEmployee: [],
      selected: null
    };
    this.getWorkers();
  }

  getWorkers = async () => {
    const permanentEmployee = await authRequest(
      "GET",
      "worker?type=permanentEmployee"
    );
    const casualWorker = await authRequest("GET", "worker?type=casualWorker");
    this.setState({
      casualWorker: casualWorker.data,
      permanentEmployee: permanentEmployee.data
    });
  };

  refresh = () => {
    this.props.reset();
  };

  getFixed = value => {
    if (typeof value === "string") {
      value = value
        .replace("R$", "")
        .replace(/\./g, "")
        .replace(",", ".");
    }
    value = parseFloat(value) || 0;
    return value.toLocaleString("pt-BR", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });
  };

  redirect = () => {
    this.props.save();
    const { startProject } = this.props.values;
    const { endProject } = this.props.values;

    this.props.history.push({
      pathname: "/home/cadastro/funcionarios",
      state: { values: { startProject, endProject }, canReturn: true }
    });
  };

  handleToggle = (worker, type) => {
    const list = this.props.values[type],
      currentIndex = _.findIndex(list, { _id: worker._id });

    if (currentIndex === -1) {
      list.push(worker);
    } else {
      list.splice(currentIndex, 1);
    }

    this.props.updateValue(type, list);
  };

  renderList = type => {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid item sm={12}>
          <List className={classes.workerList}>
            {this.state[type].map(worker => {
              const labelId = `checkbox-list-label-${worker._id}`;

              return (
                <ListItem
                  key={worker._id}
                  role={undefined}
                  dense
                  button
                  onClick={() => this.handleToggle(worker, type)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={
                        _.findIndex(this.props.values[type], {
                          _id: worker._id
                        }) !== -1
                      }
                      tabIndex={-1}
                      disableRipple
                      color="primary"
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={worker.name}
                    secondary={
                      type === "casualWorker"
                        ? `Total pago: R$ ${this.getFixed(worker.totalSigning)}`
                        : `Salário: R$ ${this.getFixed(worker.salary)}`
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </Grid>
        <Grid item sm={3}></Grid>
        <Grid item sm={6}>
          <Button
            fullWidth
            variant="contained"
            onClick={this.redirect}
            className={classes.editButton}
          >
            Editar Trabalhadores
          </Button>
        </Grid>
        <Grid item sm={3}></Grid>
      </React.Fragment>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main>
          <Grid container>
            <Grid item sm={8}>
              <h2 className={classes.subtitle}>Valores Discriminados</h2>
            </Grid>
            <Grid item sm={12}>
              <div className="grid-input">
                <Paper>
                  <h2 className={classes.subheader}>Trabalhador Eventual</h2>
                </Paper>
                <Helper helperText="Selecione os funcionários eventuais contratados para esse projeto."/>
              </div>
            </Grid>
            {this.renderList("casualWorker")}
            <Grid item sm={12}>
              <div className="grid-input">
                <Paper>
                  <h2 className={classes.subheader}>Trabalhador Fixo</h2>
                </Paper>
                <Helper helperText="Selecione os funcionários fixos contratados para esse projeto."/>
              </div>
            </Grid>
            {this.renderList("permanentEmployee")}
          </Grid>
        </main>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(Workers));
