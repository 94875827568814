const drawerWidth = 280;

const styles = theme => ({
  backimage: {
    position: "absolute",
    bottom: "-150px",
    right: "-100px",
    width: "570px",
    opacity: 0.2,
    zIndex: "-1"
  },
  root: {
    display: "flex",
    overflowY: "scroll",
    height: "100vh"
  },
  toolbar: {
    paddingRight: 28 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#284243",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  controlsContent: {
    flexGrow: 1,
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  controls: {
    maxWidth: "70vh"
  },
  title: {
    fontFamily: "Khmer MN"
  },
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    whiteSpace: "nowrap",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  avatar: {
    width: "20%"
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden"
  },
  userMenuButton: {
    color: "white"
  },
  container: {
    height: "90%",
    maxWidth: "100%"
  },
  columnGrid: {
    height: "96%",
    overflow: "hidden",
    overflowY: "auto"
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  select: {
    "&:before": {
      borderColor: "white"
    },
    "&:after": {
      borderColor: "white"
    }
  },
  label: {
    color: "white"
  },
  icon: {
    fill: "white"
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
      borderRadius: "50px"
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      borderRadius: "50px"
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
      borderRadius: "50px"
    }
  }
});

export default styles;
