import React from "react";
import _ from "lodash";
import authRequest from "../authRequest";

import { fields as property } from "./Registers/Property/Register";
import { fields as project } from "./Registers/Project/Register";
import { fields as worker } from "./Registers/Worker/Register";
import { fields as equipament } from "./Registers/Equipament/Register";
import { fields as machine } from "./Registers/Machine/Register";
import { fields as gee } from "./GEE/Wood/Quality";
import { fields as wood } from "./WoodViability/Modules/ConsideredOfWood";
import { fields as coal } from "./CoalViability/Modules/Viability/Viability";

export default class FormControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.reset(null);
    }

    copy = (obj) => JSON.parse(JSON.stringify(obj));

    reset = (snackmessage) => {
        const values = this.copy({
                property,
                project,
                worker,
                equipament,
                machine,
                gee,
                wood,
                coal,
            }),
            fields = Object.keys(values[this.props.type]),
            errors = fields.reduce((r, field) => {
                r[field] = "";
                return r;
            }, {});

        return {
            values: values[this.props.type],
            errors,
            fields,
            snackmessage,
            loading: false,
            property: {},
            project: {}
        };
    };

    resetState = async (snackmessage, dynamicInitialValues = {}) => {
        if (this.props.type === "project") {
            const data = await authRequest(
                "GET",
                `project/next/${window.localStorage.propertyId}`
            );

            project.number = data.data.number;
            project.propertyId = window.localStorage.propertyId;
        } else if (this.props.type === "worker") {
            Object.assign(worker, dynamicInitialValues);
        } else if (this.props.type === "equipament") {
            Object.assign(equipament, dynamicInitialValues);
        } else if (this.props.type === "machine") {
            Object.assign(machine, dynamicInitialValues);
        }

        this.setState(this.reset(snackmessage));
    };

    handleChange = (name) => (event) => {
        const values = this.state.values;
        _.set(values, name, event.target.value);
        this.setState({ values });
        localStorage.setItem("saveQuestion", true)
        if (this.updateCalculatedValues) this.updateCalculatedValues();
    };

    updateValue = (name, value) => {
        const values = this.state.values;
        _.set(values, name, value);
        this.setState({ values });
    };

    changeChip = (name, chips, type) => {
        const values = this.state.values;
        if (Array.isArray(chips)) _.set(values, name, chips);
        else values[name].splice(values[name].indexOf(chips), 1);
        this.setState({ values });
    };

    freeButton = () => {
        let free = true;

        this.state.fields.forEach((field) => {
            if (!_.get(this.state, `values.${field}`)) free = false;
        });

        return free;
    };

    save = async () => {
        localStorage.removeItem("saveQuestion")
        const _id = this.state.values._id;
        if (_id && _id !== "new")
            await authRequest(
                "PUT",
                `${this.props.type}/${this.state.values._id}`,
                this.state.values
            );
        else
            await authRequest("POST", `${this.props.type}`, {
                ...this.state.values,
                userId: window.localStorage.userId,
            });
    };

    removeSpecialCharacters = (field = "") => {
        return field.toString().replace(/[^A-Za-z0-9]+/g, "");
    };

    validate = (field, message, condition) => {
        const errors = this.state.errors || {};
        if (!condition)
            condition =
                _.get(this.state, `values.${field}`) === null ||
                this.removeSpecialCharacters(
                    _.get(this.state, `values.${field}`)
                ).length === 0;
        if (condition)  {
            console.log(errors, field, message)
            _.set(errors, field, message);
        }
        else _.set(errors, field, "");
        this.setState({ errors });
    };
}
