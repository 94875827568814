import _ from "lodash";

import FormDefault from "../../../../FormDefault";
import MoneyMask from "../../../../Masks/Money";
import NumberMask from "../../../../Masks/Number";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

export const fields = {};

const unit = {
  "m³/ha": { total: "m³", avg: "/m³" },
  "st/ha": { total: "st", avg: "/st" },
  "t/ha": { total: "t", avg: "/t" }
};

class Financial extends FormDefault {
  getInputs = () => {
    const { values } = this.props || {},
      { avgProductivityUnit } = values.carbonRemoval;

    values.ppcUnit = _.get(unit, `${avgProductivityUnit}.total`);
    values.avgWoodSellingPriceUnit = _.get(unit, `${avgProductivityUnit}.avg`);

    return [
      {
        id: "avgWoodSellingPrice",
        label: "Preço médio de venda da madeira (R$)",
        inputProps: { inputComponent: MoneyMask },
        sm: 5
      },
      {
        id: "avgWoodSellingPriceUnit",
        label: "Unidade",
        disabled: true,
        sm: 1
      },
      {
        id: "totalProductionPerPlantingCycle",
        label: "Produção total por ciclo de plantio",
        disabled: true,
        inputProps: { inputComponent: NumberMask },
        sm: 5
      },
      {
        id: "ppcUnit",
        label: "Unidade",
        disabled: true,
        sm: 1
      }
    ];
  };
}

export default withStyles(styles)(Financial);
