import React from "react";

import FormDefault from "../../../../FormDefault";
import MoneyMask from "../../../../Masks/Money";

import { Grid } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

export const fields = {};

class Planting extends FormDefault {
  getInputs = () => {
    const { classes } = this.props;
    return [
      {
        id: "furnace.lifespan",
        label: "Vida útil da fornalha (anos)",
        inputProps: { inputProps: { min: 0 } }
      },
      {
        id: "furnace.furnaceAmmount",
        label: "Quantidade de fornalhas",
        inputProps: { inputProps: { min: 0 } }
      },
      {
        render: (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <h3 className={classes.insideHeader}>1) Construção</h3>
            </Grid>
          </React.Fragment>
        )
      },
      {
        id: "furnace.avgCost",
        label: "Custo médio (/fornalha)",
        helperText: "Custo médio de construção da(s) fornalha(s).",
        inputProps: { inputComponent: MoneyMask },
        sm: 12
      },
      {
        render: (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <h3 className={classes.insideHeader}>2) Manutenção</h3>
            </Grid>
          </React.Fragment>
        )
      },
      {
        id: "furnace.avgYearlyCost",
        label: "Custo médio anual (/fornalha)",
        helperText: "Custo médio anual de manutenção da(s) fornalha(s).",
        inputProps: { inputComponent: MoneyMask },
        sm: 12
      }
    ];
  };
}

export default withStyles(styles)(Planting);
