import FormDefault from "../../../FormDefault";
import { withStyles } from "@material-ui/core/styles";
import styles from "../styles";

import NumberMask from "../../../Masks/Number";

import React from "react";
import { Grid } from "@material-ui/core";

class Production extends FormDefault {
  getInputs = () => {
    const { classes } = this.props;
    return [
      {
        render: (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <h3 className={classes.subheader}>Produtividade do forno</h3>
            </Grid>
          </React.Fragment>
        )
      },
      {
        type: "number",
        id: "avgDurationOfCarbonizationCicle",
        label: "Duração média do ciclo de carbonização (dias)",
        helperText: "O ciclo de carbonização compreende as etapas de enchimento dos fornos, carbonização, resfriamento e descarga dos fornos.",
        inputProps: { inputProps: { min: 0 } },
        sm: 12
      },
      {
        type: "number",
        id: "downtime",
        label: "Período de inatividade (dias/mês)",
        helperText: "O período de inatividade corresponde ao tempo em que os fornos não estão em funcionamento. Por exemplo: períodos de manutenção dos fornos; intervalo de tempo entre o fim do descarregamento e o início do próximo enchimento; períodos inativos devido às chuvas etc.",
        inputProps: { inputProps: { min: 0, max: 31 } },
        sm: 12
      },
      {
        id: "avgProductivity",
        label: "Produtividade média (ciclos/mês)",
        disabled: true,
        inputProps: { inputComponent: NumberMask },
        sm: 12
      },
      {
        render: (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <h3 className={classes.subheader}>
                Período de produção (estação seca)
              </h3>
            </Grid>
          </React.Fragment>
        )
      },
      {
        type: "number",
        id: "drySeasonProductionPeriod.workingPeriod",
        label: "Período útil de utilização do forno (meses/ano)",
        inputProps: { inputProps: { min: 0, max: 12 } },
        sm: 12
      },
      {
        type: "number",
        id: "drySeasonProductionPeriod['mdc/forno']",
        label: "Produção por ciclo (mdc/forno)",
        inputProps: { inputProps: { min: 0 } }
      },
      {
        id: "drySeasonProductionPeriod['t/forno']",
        label: "Produção por ciclo (t/forno)",
        disabled: true,
        inputProps: { inputComponent: NumberMask }
      },
      {
        id: "drySeasonProductionPeriod['mdc/ano']",
        label: "Produção por ano (mdc/ano)",
        disabled: true,
        inputProps: { inputComponent: NumberMask }
      },
      {
        id: "drySeasonProductionPeriod['t/ano']",
        label: "Produção por ano (t/ano)",
        disabled: true,
        inputProps: { inputComponent: NumberMask }
      },
      {
        render: (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <h3 className={classes.subheader}>
                Período de produção (estação chuvosa)
              </h3>
            </Grid>
          </React.Fragment>
        )
      },
      {
        type: "number",
        id: "rainySeasonProductionPeriod.workingPeriod",
        label: "Período útil de utilização do forno (meses/ano)",
        inputProps: { inputProps: { min: 0, max: 12 } },
        sm: 12
      },
      {
        id: "rainySeasonProductionPeriod['mdc/forno']",
        label: "Produção por ciclo (mdc/forno)",
        disabled: true,
        inputProps: { inputComponent: NumberMask }
      },
      {
        id: "rainySeasonProductionPeriod['t/forno']",
        label: "Produção por ciclo (t/forno)",
        disabled: true,
        inputProps: { inputComponent: NumberMask }
      },
      {
        id: "rainySeasonProductionPeriod['mdc/ano']",
        label: "Produção por ano (mdc/ano)",
        disabled: true,
        inputProps: { inputComponent: NumberMask }
      },
      {
        id: "rainySeasonProductionPeriod['t/ano']",
        label: "Produção por ano (t/ano)",
        disabled: true,
        inputProps: { inputComponent: NumberMask }
      }
    ];
  };
}

export default withStyles(styles)(Production);
