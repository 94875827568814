import axios from "axios";

import CellphoneMask from "../Masks/Cellphone";
import CPFMask from "../Masks/CPF";
import TelephoneMask from "../Masks/Telephone";

import FormDefault from "../FormDefault";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

class Personal extends FormDefault {
  removeSpecialCharacters = (field = "") => {
    return field.toString().replace(/[^A-Za-z0-9]+/g, "");
  };

  _validateCpf = strCPF => {
    var sum = 0;
    var rest;
    let i;
    if (strCPF === "00000000000") return false;

    for (i = 1; i <= 9; i++)
      sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(strCPF.substring(9, 10))) return false;

    sum = 0;
    for (i = 1; i <= 10; i++)
      sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
  };

  validateCPF = async () => {
    const cpf = this.removeSpecialCharacters(this.props.values.cpf);
    if (cpf.length === 0) this.props.validate("cpf", "CPF é obrigatório");
    else
      this.props.validate("cpf", "O CPF é inválido", !this._validateCpf(cpf));
    try {
      if (cpf.length > 10 && !this.props.values._id)
        await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/register/cpf/${this.props.values.cpf}`
        );
    } catch (ex) {
      this.props.handleOpen(ex.response.data);
      this.props.values.cpf = "";
    }
  };

  getInputs = () => {
    return [
      {
        id: "name",
        label: "Nome Completo",
        required: true,
        sm: 12,
        inputProps: { autoFocus: true }
      },
      {
        type: "select",
        id: "genre",
        label: "Gênero",
        required: true,
        options: ["Masculino", "Feminino", "Outro"]
      },
      {
        type: "date",
        id: "birth",
        label: "Data de Nascimento",
        required: true
      },
      {
        id: "cpf",
        label: "CPF",
        required: true,
        sm: 12,
        inputProps: {
          inputComponent: CPFMask,
          onKeyDown: this.validateCPF,
          onBlur: this.validateCPF
        }
      },
      {
        id: "telephone",
        label: "Telefone",
        inputProps: { inputComponent: TelephoneMask }
      },
      {
        id: "cellphone",
        label: "Celular",
        required: true,
        inputProps: { inputComponent: CellphoneMask }
      },
      {
        type: "select",
        id: "schooling",
        label: "Escolaridade",
        required: true,
        sm: 12,
        options: [
          "Fundamental Incompleto",
          "Fundamental Completo",
          "Médio Incompleto",
          "Médio Completo",
          "Superior Incompleto",
          "Superior Completo",
          "Especialização e/ou Pós Graduação"
        ]
      }
    ];
  };
}

export default withStyles(styles)(Personal);
