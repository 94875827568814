import FormDefault from "../../../../FormDefault";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

export const fields = {};

class Wood extends FormDefault {
  getInputs = () => {
    return [
      {
        type: "number",
        id: "carbonRemoval.avgProductivity",
        label: "Volume total de madeira na idade de corte.",
        helperText: "Volume total de madeira na idade de corte, por hectare.",
        inputProps: { inputProps: { min: 0 } },
        sm: 9
      },
      {
        type: "select",
        id: "carbonRemoval.avgProductivityUnit",
        label: "Unidade",
        options: ["m³/ha", "st/ha", "t/ha"],
        sm: 3
      },
      {
        type: "number",
        id: "harvestedArea",
        helperText: "A área plantada pode ser igual a área necessária para atender a demanda anual de madeira para carbonização ou não.",
        label: "Área Plantada (ha)",
        inputProps: { inputProps: { min: 0 } }
      },
      {
        type: "number",
        id: "cuttingAge",
        label: "Idade de corte (anos)",
        inputProps: { inputProps: { min: 0 } }
      }
    ];
  };
}

export default withStyles(styles)(Wood);
