import FormDefault from "../../../FormDefault";
import { withStyles } from "@material-ui/core/styles";
import styles from "../styles";

import React from "react";
import { CircularProgress, Divider, Grid } from "@material-ui/core";

import NumberFormat from "../../../Masks/Number";

import geneticMaterials from "./geneticMaterials";

export const fields = {
    stackingFactor: 0
};

class Quality extends FormDefault {
    constructor(props) {
        super(props);
        this.state = {};
    }

    changeGeneticMaterial = (event) => {
        this.props.updateValue("geneticMaterial", event.target.value);
        this.setState({ loading: true });
        setTimeout(() => {
            const data = geneticMaterials[this.props.values.geneticMaterial];
            this.props.updateValue("basicDensity", data.estimated);
            this.props.updateValue("font", data.font);
            this.props.updateCalculatedValues();
            this.setState({ loading: false });
        }, 1500);
    };

    changeDensity = (event) => {
        this.props.updateValue("basicDensity", event.target.value);
        this.props.updateValue("font", "-");
        this.props.updateCalculatedValues();
    };

    getInputs = () => {
        const { classes } = this.props;
        return [
            {
                type: "select",
                id: "geneticMaterial",
                label: "Material Genético",
                options: Object.keys(geneticMaterials),
                inputProps: { onChange: this.changeGeneticMaterial },
                sm: 12,
            },
            {
                type: "select",
                id: "dryBaseMoistureContent",
                label: "Teor de umidade base seca (U%)",
                options: ["< 30%", "31% - 35%", "36% - 40%", "> 40%"],
                sm: 12,
            },
            {
                render: (
                    <Grid item xs={12} sm={12}>
                        <Divider />
                    </Grid>
                ),
            },
            {
                id: "basicDensity",
                label: "Densidade Básica (kg/m³)",
                helperText: this.props.values.geneticMaterial !== "Outro" ? "O sistema fornece valor default, mas que pode ser alterado." : false,
                inputProps: {
                    onChange: this.changeDensity,
                    inputComponent: NumberFormat,
                },
                sm: 8,
            },
            {
                render: (
                    <Grid item xs={12} sm={4} className={classes.external}>
                        <div className={classes.internal}>
                            <span className={classes.measures}>Fonte: </span>
                            {this.state.loading === true ? (
                                <CircularProgress size={20} />
                            ) : (
                                <span className={classes.measures}>
                                    {this.props.values.font}
                                </span>
                            )}
                        </div>
                    </Grid>
                ),
            },
        ];
    };
}

export default withStyles(styles)(Quality);
