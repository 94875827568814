import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  "@keyframes fadeIn": {
    "0%": { marginRight: "-20vw" },
    "100%": { marginRight: "0vw" }
  },
  image: {
    background: "#F9F9F9",
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "column"
  },
  logo: {
    marginLeft: "8vw",

    "& img": {
      height: "20vh"
    }
  },
  carousel: {
    "& .slide": {
      background: "transparent"
    },
  },
  contanierImg: {
    display: "flex",
    alignItems: "center",

    "& img": {
      height: "auto !important",
    }
  },
  hands: {
    height: "30vh",
    animation: `$fadeIn 1000ms ${theme.transitions.easing.easeInOut}`
  },
  helpers: {
    margin: "0 4vw",

    "& img": {
      marginTop: "2vh",
      height: "auto"
    }
  },
  row: {
    display: "flex",
    justifyContent: "space-between"
  },
  text: {
    width: "40%",
    marginLeft: "8vw",
    textAlign: "justify",

    "& p": {
      fontSize: "1.8vh"
    }
  },
  header: {
    fontFamily: "Khmer MN",
    fontSize: "2.5rem",
    fontWeight: "250",
    lineHeight: "127.8%",
    textAlign: "center"
  },
  paper: {
    padding: theme.spacing(0, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    height: "100%",
    background: "#F9F9F9",

    "& input": {
      backgroundColor: "#FFF"
    }
  },
  form: {
    width: "80%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: "2vh",
    borderRadius: "9px"
  },
  register: {
    margin: theme.spacing(0, 0, 0),
    padding: "2vh",
    borderRadius: "9px"
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
}));

export default styles;
