import React from "react";

import { Paper, Snackbar } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

import authRequest from "../../../authRequest";
import ConfirmDialog from "../../ConfirmDialog";
import DialogRegister from "../DialogRegister";
import Register from "./Register";
import List from "./List";

class Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      registerOpen: false,
      confirmOpen: false,
      projects: [],
      selectedId: "",
      selected: null
    };
    this.refresh();
  }

  refresh = async () => {
    if (this.props.propertyId) {
      const projects = await authRequest(
        "GET",
        `project/${this.props.propertyId}`
      );
      this.setState({ projects: projects.data });
    }
  };

  remove = async () => {
    await authRequest("DELETE", `project/${this.state.selectedId}`);
    this.setState({ snackmessage: "Projeto removido com sucesso" });
    this.confirmHandleClose();
    this.refresh();
  };

  registerHandleClickOpen = selected => {
    this.setState({ registerOpen: true, selected });
  };
  registerHandleClose = () => {
    this.setState({ registerOpen: false, selected: null });
    this.refresh();
  };
  confirmHandleClickOpen = selectedId =>
    this.setState({ confirmOpen: true, selectedId });
  confirmHandleClose = () => this.setState({ confirmOpen: false });

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main className={classes.main}>
          <Paper className={classes.container}>
            <h1 className={classes.title}>Projetos Cadastrados</h1>
            <List
              projects={this.state.projects}
              projectId={this.props.projectId}
              propertyId={this.props.propertyId}
              registerHandleClickOpen={this.registerHandleClickOpen}
              confirmHandleClickOpen={this.confirmHandleClickOpen}
            />
          </Paper>
        </main>
        <ConfirmDialog
          title="Excluir Projeto"
          content="Deseja realmente excluir esse projeto?"
          open={this.state.confirmOpen}
          handleClose={this.confirmHandleClose}
          action={this.remove}
        />
        <DialogRegister
          form={Register}
          type="project"
          title="Projeto"
          open={this.state.registerOpen}
          handleClose={this.registerHandleClose}
          selected={this.state.selected}
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          key="message"
          open={this.state.snackmessage ? true : false}
          onClose={() => this.setState({ snackmessage: "" })}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.snackmessage}</span>}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Project);
