import React from "react";
import _ from "lodash";

import {
    FormControl,
    Grid,
    MenuItem,
    OutlinedInput,
    TextField,
} from "@material-ui/core";

import InfoIcon from "@material-ui/icons/Info";

import ChipInput from "material-ui-chip-input";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";

export default class Input extends React.Component {
    constructor(props) {
        super(props);

        this.validations = {
            [props.id]: () => {
                if (props.type === "number") this._validateNumber();
                if (props.required)
                    props.props.validate(
                        props.id,
                        `${props.label} é obrigatório`
                    );
            },
        };

        this.state = { open: false };
    }

    _validateNumber = () => {
        const min = _.get(this.props, "inputProps.inputProps.min"),
            max = _.get(this.props, "inputProps.inputProps.max");

        let condition;

        if (min || min === 0)
            condition =
                _.get(this.props, `props.values.${this.props.id}`) < min;
        if (max)
            condition =
                condition ||
                _.get(this.props, `props.values.${this.props.id}`) > max;

        this.props.props.validate(
            this.props.id,
            `${this.props.label} inválido`,
            condition
        );
    };

    renderText = (type) => {
        const error = _.get(this.props, `props.errors.${this.props.id}`);
        let value = _.get(this.props, `props.values.${this.props.id}`) || "",
            inputProps = this.props.inputProps || { inputProps: {} };

        if (type === "number") {
            if (!inputProps.inputProps) inputProps.inputProps = {};
            inputProps.inputProps.step = "any";
        } else if (!isNaN(value)) {
            value = value.toString().replace(".", ",");
        }

        return (
            <TextField
                key={this.props.id}
                type={type}
                id={this.props.id}
                label={this.props.label}
                name={this.props.id}
                disabled={this.props.disabled}
                fullWidth
                onChange={this.props.props.handleChange(this.props.id)}
                onBlur={this.validations[this.props.id]}
                value={value}
                required={this.props.required}
                error={error ? true : false}
                helperText={error ? error : null}
                variant={this.props.variant || "outlined"}
                InputProps={inputProps}
            />
        );
    };

    renderSelect = (multiple = false) => {
        const error = _.get(this.props, `props.errors.${this.props.id}`);
        multiple = multiple === "multiSelect" ? true : false;

        return (
            <TextField
                select
                variant={this.props.variant || "outlined"}
                id={this.props.id}
                value={_.get(this.props, `props.values.${this.props.id}`) || ""}
                onBlur={this.validations[this.props.id]}
                onChange={this.props.props.handleChange(this.props.id)}
                input={
                    <OutlinedInput name={this.props.id} id={this.props.id} />
                }
                label={this.props.label}
                key={this.props.id}
                required={this.props.required}
                fullWidth
                error={error ? true : false}
                disabled={this.props.disabled}
                helperText={error ? error : null}
                InputProps={this.props.inputProps}
                SelectProps={{ multiple: multiple }}
            >
                {this.props.options.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
        );
    };

    renderDate = () => {
        const error = _.get(this.props, `props.errors.${this.props.id}`);
        return (
            <FormControl key={this.props.id}>
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    inputVariant={this.props.variant || "outlined"}
                    format={this.props.format || "dd/MM/yyyy"}
                    margin="normal"
                    id={this.props.id}
                    label={this.props.label}
                    value={_.get(this.props, `props.values.${this.props.id}`)}
                    onChange={this.props.props.handleDateChange}
                    onBlur={this.validations[this.props.id]}
                    error={error ? true : false}
                    helperText={error}
                    required
                    KeyboardButtonProps={{ "aria-label": this.props.label }}
                />
            </FormControl>
        );
    };

    renderChips = () => {
        return (
            <ChipInput
                fullWidth
                variant="outlined"
                label={this.props.label}
                defaultValue={_.get(
                    this.props,
                    `props.values.${this.props.id}`
                )}
                value={_.get(this.props, `props.values.${this.props.id}`)}
                onChange={(chips) =>
                    this.props.props.changeChip(this.props.id, chips)
                }
                onDelete={(chips) =>
                    this.props.props.changeChip(this.props.id, chips)
                }
            />
        );
    };

    renderType = (type = "text") => {
        const inputs = {
            text: this.renderText,
            number: this.renderText,
            select: this.renderSelect,
            date: this.renderDate,
            chips: this.renderChips,
            multiSelect: this.renderSelect,
        };

        return inputs[type](type);
    };

    toggleHelper = () => {
        this.setState({ open: !this.state.open })
    }

    getBaloonClass = () => {
        let className = this.state.open ? "baloon" : "hide";
        if(this.props.rightBaloon) className += " baloon-right";
        return className;
    }

    helper = () => {
        return (
            <div className="info-icon" onMouseEnter={this.toggleHelper} onMouseLeave={this.toggleHelper}>
                <InfoIcon />
            </div>
        );
    };

    render() {
        return (
            <React.Fragment key={this.props.id}>
                <div className={this.state.open ? "dimmer" : "hide"}>
                    <div className={this.getBaloonClass()}>
                        {this.props.helperText}
                    </div>
                </div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item xs={12} sm={this.props.sm || 6} className="grid-input">
                        {this.props.helperText ? this.helper() : null}
                        {this.renderType(this.props.type)}
                    </Grid>
                </MuiPickersUtilsProvider>
            </React.Fragment>
        );
    }
}
