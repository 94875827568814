import React from "react";
import _ from "lodash";
import axios from "axios";

import FormDefault from "../../../FormDefault";
import ZipcodeMask from "../../../Masks/Zipcode";
import CoordinatesMask from "../../../Masks/Coordinates";

import { Grid } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

export const fields = {
  name: "",
  size: 0,
  zipcode: "",
  city: "",
  state: "",
  region: "",
  geoCoordinates: []
};

class Register extends FormDefault {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  getAddress = async () => {
    let rawzip = _.get(this.props, "values.zipcode") || "";
    rawzip = rawzip.replace(/\s/g, "").replace("-", "");
    if (rawzip.length === 0)
      this.props.validate("zipcode", "CEP é obrigatório");
    else if (rawzip.length > 7) {
      this.setState({ loading: true });
      const response = await axios.get(
        `https://viacep.com.br/ws/${rawzip}/json/`
      );
      if (response.data.erro)
        this.props.validate("zipcode", "CEP Inválido", true);
      else {
        this.props.values.street = response.data.logradouro || "";
        this.props.values.district = response.data.bairro || "";
        this.props.values.city = response.data.localidade || "";
        this.props.values.state = response.data.uf || "";
        this.props.validate("zipcode", "CEP Inválido", false);
      }
      this.setState({ loading: false });
    } else {
      this.props.validate("zipcode", "CEP Inválido", rawzip.length <= 7);
    }
  };

  getInputs = () => {
    return [
      {
        id: "name",
        label: "Nome da Propriedade",
        required: true,
        sm: 9,
        inputProps: { autoFocus: true }
      },
      {
        type: "number",
        id: "size",
        label: "Tamanho (em ha)",
        required: true,
        inputProps: { inputProps: { min: 0 } },
        sm: 3
      },
      {
        id: "zipcode",
        label: "CEP",
        required: true,
        sm: 2,
        inputProps: { inputComponent: ZipcodeMask, onKeyDown: this.getAddress }
      },
      { id: "city", label: "Município", required: true, sm: 8 },
      { id: "state", label: "Estado", required: true, sm: 2 },
      {
        type: "select",
        id: "region",
        label: "Região do país",
        options: ["Centro-oeste", "Nordeste", "Norte", "Sul", "Sudeste"]
      },
      { render: <Grid item sm={6}></Grid> },
      {
        id: "geoCoordinates[0]",
        label: "Coordenadas Geográficas (Latitude)",
        inputProps: { inputComponent: CoordinatesMask }
      },
      {
        id: "geoCoordinates[1]",
        label: "Coordenadas Geográficas (Longitude)",
        inputProps: { inputComponent: CoordinatesMask }
      }
    ];
  };
}

export default withStyles(styles)(Register);
