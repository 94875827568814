import React from "react";

import { Typography } from "@material-ui/core";

export default class Empty extends React.Component {
  render() {
    return (
      <React.Fragment>
        <img
          src={this.props.img || "/assets/empty.png"}
          alt="empty"
          className="empty"
        />
        <Typography className="emptyMessage">{this.props.message}</Typography>
      </React.Fragment>
    );
  }
}
