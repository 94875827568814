import React from "react";

import {
  Button,
  Grid,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

import DialogRegister from "../../../Registers/DialogRegister";
import Register from "./Register";

class WoodPurchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      registerOpen: false,
      confirmOpen: false,
      selectedId: "",
      selected: null
    };
  }

  refresh = () => {
    this.props.reset();
  };

  getFixed = value => {
    if (typeof value === "string") {
      value = value
        .replace("R$", "")
        .replace(/\./g, "")
        .replace(",", ".");
    }
    value = parseFloat(value) || 0;
    return value.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
  };

  registerHandleClickOpen = () => {
    this.setState({ registerOpen: true, selected: this.props.values });
  };
  registerHandleClose = refresh => {
    this.setState({ registerOpen: false, selected: null });
    if (refresh) this.refresh();
  };
  confirmHandleClickOpen = selectedId =>
    this.setState({ confirmOpen: true, selectedId });
  confirmHandleClose = () => {
    delete window.localStorage.propertyId;
    this.setState({ confirmOpen: false });
  };

  renderTable = fields => {
    const { classes } = this.props;
    return (
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Ano</TableCell>
            <TableCell align="center">Item</TableCell>
            <TableCell align="center">Unidade</TableCell>
            <TableCell align="center">Quantidade</TableCell>
            <TableCell align="center">Valor (R$/Unidade)</TableCell>
            <TableCell align="center">Total (R$)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((row, key) => (
            <TableRow key={key}>
              <TableCell align="center">{row.year}</TableCell>
              <TableCell align="center">{row.item}</TableCell>              
              <TableCell align="center">{row.unit}</TableCell>
              <TableCell align="center">{row.count}</TableCell>
              <TableCell align="center">
                R$ {this.getFixed(row.value)}
              </TableCell>
              <TableCell align="center">
                R$ {this.getFixed(row.total)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main>
          <Grid container>
            <Grid item sm={8}>
              <h2 className={classes.subtitle}>Valores Discriminados</h2>
            </Grid>
            <Grid item sm={4}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.registerHandleClickOpen}
                className={classes.button}
              >
                Adicionar Insumo
              </Button>
            </Grid>
            <Grid item sm={12}>
              <Paper className={classes.subtitlePaper}>
                <h2 className={classes.subheader}>Custos</h2>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h2 className={classes.subtitle}>Plantio</h2>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                {this.renderTable(this.props.values.plantingInputs)}
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <h2 className={classes.subtitle}>Manutenção</h2>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                {this.renderTable(this.props.values.maintenanceInputs)}
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <h2 className={classes.subtitle}>Colheita</h2>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                {this.renderTable(this.props.values.harvestInputs)}
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <h2 className={classes.subtitle}>Transporte</h2>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                {this.renderTable(this.props.values.transportInputs)}
              </TableContainer>
            </Grid>
          </Grid>
        </main>
        <DialogRegister
          form={Register}
          type="wood"
          title="Insumos"
          open={this.state.registerOpen}
          handleClose={this.registerHandleClose}
          selected={this.state.selected}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(WoodPurchase);
