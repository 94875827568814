import _ from "lodash";
import React from "react";
import { Chart } from "react-google-charts";

import {
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Grid,
  Grow,
  Paper
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import authRequest from "../../authRequest";

import Empty from "../Empty";
import Property from "../Registers/Property/Property";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { values: {}, projects: [], gee: {} };
  }

  _getProjects = async () => {
    let projects = this.state.projects;
    projects = await authRequest(
      "GET",
      `project/${window.localStorage.propertyId}`
    );
    projects = _.get(projects, "data");
    return projects;
  };

  _getDashboard = async projectId => {
    const gee = await authRequest("GET", `gee/${this.state.values.projectId}`);
    const wood = await authRequest(
      "GET",
      `wood/${this.state.values.projectId}`
    );
    const coal = await authRequest(
      "GET",
      `coal/${this.state.values.projectId}`
    );
    this.setState({
      gee: gee.data || {},
      wood: wood.data || {},
      coal: coal.data || {}
    });
  };

  openProperty = property => async event => {
    if (this.state.values.propertyId === property._id) {
      this.setState({ values: { propertyId: null }, projects: [] });
    } else {
      const values = this.state.values;
      let projects = this.state.projects;
      window.localStorage.setItem("propertyId", property._id);
      projects = await this._getProjects();
      window.localStorage.removeItem("projectId");
      _.set(values, "propertyId", property._id);
      this.setState({ values, projects });
    }
  };

  openProject = async project => {
    const values = this.state.values;
    if (this.state.values.projectId === project._id) {
      _.set(values, "projectId", null);
    } else {
      _.set(values, "projectId", project._id);
      await this._getDashboard(project._id);
    }
    this.setState({ values });
  };

  getFixed = type => {
    let value = _.get(this.state.gee, type) || 0;
    value = parseFloat(value);
    return value.toLocaleString("pt-BR", { maximumFractionDigits: 2 });
  };

  getCoal = type => {
    let value = _.get(this.state.coal, type) || 0;
    value = parseFloat(value);
    return value.toLocaleString("pt-BR", { maximumFractionDigits: 2 });
  };

  getWood = type => {
    let value = _.get(this.state.wood, type) || 0;
    value = parseFloat(value);
    return value.toLocaleString("pt-BR", { maximumFractionDigits: 2 });
  };

  getForGraph = type => {
    const value = _.get(this.state.gee, type) || 1;
    return parseInt(value) || 1;
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main className={classes.main}>
          <Grid container className={classes.buttons} spacing={2}>
            <Grid item sm={4}>
              <h2 className={classes.subtitle}>Propriedades</h2>
              <Paper className={classes["container-larger"]}>
                <Property
                  overide={classes}
                  type="dashboard"
                  openProperty={this.openProperty}
                  propertyId={this.state.values.propertyId}
                />
              </Paper>
              <h2 className={classes.subtitle}>Projetos</h2>
              <Paper className={classes["container-small"]}>
                {this.state.projects.length ? (
                  <List
                    className={classes.mini}
                    component="nav"
                    aria-label="secondary mailbox folders"
                  >
                    {this.state.projects.map(project => {
                      return (
                        <React.Fragment key={project._id}>
                          <ListItem
                            key={project._id}
                            button
                            selected={
                              this.state.values.projectId === project._id
                            }
                            onClick={() => this.openProject(project)}
                          >
                            <ListItemText
                              primary={`Projeto Nº ${project.number}`}
                            />
                          </ListItem>
                        </React.Fragment>
                      );
                    })}
                  </List>
                ) : this.state.values.propertyId ? (
                  <Empty message="Ops, nenhum projeto ainda cadastrado" />
                ) : (
                  <Empty
                    img="/assets/seta.png"
                    message="Selecione uma propriedade para ver seus projetos"
                  />
                )}
              </Paper>
            </Grid>
            <Grow in={this.state.values.projectId ? true : false}>
              <Grid item sm={4}>
                <h2 className={classes.subtitle}>Total de Madeira Enfornada</h2>
                <Paper className={classes["container-small"]}>
                  <Chart
                    width={"100%"}
                    height={"100%"}
                    chartType="LineChart"
                    loader={
                      <Grid item className={classes.progress}>
                        <CircularProgress className={classes.center} />
                      </Grid>
                    }
                    data={[
                      ["", "Total de madeira enfornada"],
                      ["st", this.getForGraph('totalHangingWood["st/ano"]')],
                      ["m³", this.getForGraph('totalHangingWood["m³/ano"]')],
                      ["t", this.getForGraph('totalHangingWood["t/ano"]')]
                    ]}
                    options={{
                      fontSize: 13,
                      chartArea: { width: "100%", height: "68%" },
                      vAxis: { minValue: 0 },
                      colors: ["#2B3B4F"]
                    }}
                  />
                </Paper>

                <h2 className={classes.subtitle}>
                  Produção de Carvão e Ger. de Finos
                </h2>
                <Paper className={classes["container-small"]}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} className={classes.external}>
                      <span className={classes.internal}>Carvão:</span>
                    </Grid>
                    <Grid item xs={12} sm={9} className={classes.external}>
                      <Grid container className={classes.internal}>
                        <Grid item sm={12}>
                          <span className={classes.measures}>
                            {this.getFixed('totalProduction["mdc/ano"]')}{" "}
                            mdc/ano
                          </span>
                        </Grid>
                        <Grid item sm={12}>
                          <span className={classes.measures}>
                            {this.getFixed('totalProduction["t/ano"]')} t/ano
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.external}>
                      <span className={classes.internal}>Finos:</span>
                    </Grid>
                    <Grid item xs={12} sm={9} className={classes.external}>
                      <Grid container className={classes.internal}>
                        <Grid item sm={12}>
                          <span className={classes.measures}>
                            {this.getFixed(
                              "totalGenerationOfFines"
                            ).toLocaleString("pt-BR")}{" "}
                            t/ano
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
                <h2 className={classes.subtitle}>
                  Reserva Legal x Área Colhida
                </h2>
                <Paper className={classes["container-small"]}>
                  <Chart
                    width={"100%"}
                    height={"100%"}
                    chartType="PieChart"
                    loader={
                      <Grid item className={classes.progress}>
                        <CircularProgress className={classes.center} />
                      </Grid>
                    }
                    data={[
                      ["Tipo", "Valor"],
                      [
                        "Reserva Legal",
                        this.getForGraph("carbonRemoval.legalReserve")
                      ],
                      [
                        "Área Colhida",
                        this.getForGraph("carbonRemoval.woodDemand")
                      ]
                    ]}
                    options={{
                      chartArea: { width: "100%", height: "75%" },
                      colors: ["#2B3B4F", "#2B4F3C"],
                      legend: {
                        position: "right",
                        alignment: "center",
                        textStyle: { fontSize: 12.5 }
                      },
                      slices: { 1: { offset: 0.1 } }
                    }}
                    rootProps={{ "data-testid": "2" }}
                  />
                </Paper>
              </Grid>
            </Grow>
            <Grow in={this.state.values.projectId ? true : false}>
              <Grid item sm={4}>
                <h2 className={classes.subtitle}>
                  Indicadores Econômicos - Madeira
                </h2>
                <Paper className={classes["container-medium"]}>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <div className={classes.demo}>
                        <List dense={true}>
                          <ListItem>
                            <ListItemText primary="Receita Total" />
                            <ListItemSecondaryAction>
                              <span className={classes.measures}>
                                R$ {this.getWood("totalRecipes")}
                              </span>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <ListItem selected={true}>
                            <ListItemText primary="Custo Total" />
                            <ListItemSecondaryAction>
                              <span className={classes.measures}>
                                R$ {this.getWood("totalCost")}
                              </span>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="VPL" />
                            <ListItemSecondaryAction>
                              <span className={classes.measures}>
                                R$ {this.getWood("vpl")}
                              </span>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <ListItem selected={true}>
                            <ListItemText primary="VPE" />
                            <ListItemSecondaryAction>
                              <span className={classes.measures}>
                                R$ {this.getWood("vpe")}
                              </span>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="TIR" />
                            <ListItemSecondaryAction>
                              <span className={classes.measures}>
                                {this.getWood("tir")} %
                              </span>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <ListItem selected={true}>
                            <ListItemText primary="B/C" />
                            <ListItemSecondaryAction>
                              <span className={classes.measures}>
                                R$ {this.getWood("bc")}
                              </span>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </List>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>

                <h2 className={classes.subtitle}>
                  Indicadores Econômicos - Carvão Vegetal
                </h2>
                <Paper className={classes["container-medium"]}>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <div className={classes.demo}>
                        <List dense={true}>
                          <ListItem>
                            <ListItemText primary="Receita Total" />
                            <ListItemSecondaryAction>
                              <span className={classes.measures}>
                                R$ {this.getCoal("totalRecipes")}
                              </span>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <ListItem selected={true}>
                            <ListItemText primary="Custo Total" />
                            <ListItemSecondaryAction>
                              <span className={classes.measures}>
                                R$ {this.getCoal("totalCost")}
                              </span>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="VPL" />
                            <ListItemSecondaryAction>
                              <span className={classes.measures}>
                                R$ {this.getCoal("vpl")}
                              </span>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <ListItem selected={true}>
                            <ListItemText primary="VPE" />
                            <ListItemSecondaryAction>
                              <span className={classes.measures}>
                                R$ {this.getCoal("vpe")}
                              </span>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="TIR" />
                            <ListItemSecondaryAction>
                              <span className={classes.measures}>
                                {this.getCoal("tir")} %
                              </span>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <ListItem selected={true}>
                            <ListItemText primary="B/C" />
                            <ListItemSecondaryAction>
                              <span className={classes.measures}>
                                R$ {this.getCoal("bc")}
                              </span>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </List>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grow>
          </Grid>
        </main>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Dashboard);
