const ages = {
  Amazônia: [
    "Primária",
    "Primária recém manejada",
    "< 2",
    "10 < idade < 15",
    "15 < idade < 20",
    "2 < idade < 5",
    "5 < idade < 10",
    "> 20"
  ],
  "Mata Atlântica": ["< 10", "10 < idade < 20", "20 < idade < 30", "> 30"],
  Caatinga: ["< 10", "> 10"],
  Cerrado: false,
  Pampa: false,
  Pantanal: false
};

export default ages;
