import _ from "lodash";
import React from "react";
import { withRouter } from "react-router-dom";
import authRequest from "../../../../authRequest";

import {
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";
import Helper from "../../../../Components/Helper/Helper";

class Equipaments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      registerOpen: false,
      confirmOpen: false,
      selectedId: "",
      machines: [],
      equipaments: [],
      selected: null
    };
    this.getMachinesAndEquipaments();
  }

  getMachinesAndEquipaments = async () => {
    let { data: equipaments } = await authRequest("GET", "equipament");
    let { data: machines } = await authRequest("GET", "machine");

    equipaments = equipaments.map(e => {
      e.yearlyCost =
        e.acquisitionValue * (this.props.values.interestRate / 100) +
        e.anualDepreciation;
      return e;
    });

    machines = machines.map(m => {
      m.yearlyCost =
        m.acquisitionValue * (this.props.values.interestRate / 100) +
        m.anualDepreciation;
      return m;
    });

    this.setState({ equipaments, machines });
  };

  refresh = () => {
    this.props.reset();
  };

  getFixed = value => {
    if (typeof value === "string") {
      value = value
        .replace("R$", "")
        .replace(/\./g, "")
        .replace(",", ".");
    }
    value = parseFloat(value) || 0;
    return value.toLocaleString("pt-BR", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });
  };

  redirect = (type) => {
    this.props.save();
    const url = type === "machines" ? "maquinas" : "equipamentos",
      { startProject } = this.props.values,
      { endProject } = this.props.values;

    this.props.history.push({
      pathname: `/home/cadastro/${url}`,
      state: { values: { startProject, endProject }, canReturn: true }
    });
  };

  handleToggle = (equipament, type) => {
    const list = this.props.values[type],
      currentIndex = _.findIndex(list, { _id: equipament._id });

    if (currentIndex === -1) {
      list.push(equipament);
    } else {
      list.splice(currentIndex, 1);
    }

    this.props.updateValue(type, list);
  };

  renderList = type => {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid item sm={12}>
          <List className={classes.workerList}>
            {this.state[type].map(equipament => {
              const labelId = `checkbox-list-label-${equipament._id}`;

              return (
                <ListItem
                  key={equipament._id}
                  role={undefined}
                  dense
                  button
                  onClick={() => this.handleToggle(equipament, type)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={
                        _.findIndex(this.props.values[type], {
                          _id: equipament._id
                        }) !== -1
                      }
                      tabIndex={-1}
                      disableRipple
                      color="primary"
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={equipament.name}
                    secondary={`Custo anual: R$ ${this.getFixed(
                      equipament.yearlyCost
                    )}`}
                  />
                </ListItem>
              );
            })}
          </List>
        </Grid>
        <Grid item sm={3}></Grid>
        <Grid item sm={6}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => this.redirect(type)}
            className={classes.editButton}
          >
            {type === "machines" ? "Editar Máquinas" : "Editar Equipamentos"}
          </Button>
        </Grid>
        <Grid item sm={3}></Grid>
      </React.Fragment>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main>
          <Grid container>
            <Grid item sm={8}>
              <h2 className={classes.subtitle}>Valores Discriminados</h2>
            </Grid>
            <Grid item sm={12}>
              <div className="grid-input">
                <Paper>
                  <h2 className={classes.subheader}>Máquinas</h2>
                </Paper>
                <Helper helperText="Selecione as máquinas utilizadas nas atividades desse projeto."/>
              </div>
            </Grid>
            {this.renderList("machines")}
            <Grid item sm={12}>
              <div className="grid-input">
                <Paper>
                  <h2 className={classes.subheader}>Equipamentos</h2>
                </Paper>
                <Helper helperText="Selecione os equipamentos utilizados nas atividades desse projeto."/>
              </div>
            </Grid>
            {this.renderList("equipaments")}
          </Grid>
        </main>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(Equipaments));
