import _ from "lodash";
import React from "react";
import { withRouter } from "react-router-dom";

import { Grid, Paper } from "@material-ui/core";

import GEECalcMachine from "./GEECalcMachine";

import Cooproducts from "./Charcoal/Cooproducts";
import Production from "./Charcoal/Production";
import Quality from "./Charcoal/Quality";
import RG from "./Charcoal/RG";
import RV from "./Charcoal/RV";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

class Charcoal extends GEECalcMachine {
  constructor(props) {
    super(props);

    this.title = "Carvão Vegetal";
    this.nextStep = "/home/remocao";
    this.startValues = {
      totalProduction: { "mdc/ano": 0, "t/ano": 0 },
      rv: { value: 0, type: "" },
      rg: { value: 0, estimatedParam: "" },
      totalGenerationOfFines: 0,
      fixedCarbonContent: 75.3049586776859,
      avgDurationOfCarbonizationCicle: 0,
      bulkDensity: 0,
      downtime: 0,
      avgProductivity: 0,
      drySeasonProductionPeriod: {
        workingPeriod: 0,
        "mdc/forno": 0,
        "t/forno": 0,
        "mdc/ano": 0,
        "t/ano": 0
      },
      rainySeasonProductionPeriod: {
        workingPeriod: 0,
        "mdc/forno": 0,
        "t/forno": 0,
        "mdc/ano": 0,
        "t/ano": 0
      },
      generationOfServings: 4.0477522850214500,
      totalGenerationOfServings: 0,
      coalProductionUnit: { value: 3.7, total: 0 },
      loadingAndShipping: { value: 5.3, total: 0 },
      sifting: { value: 9.7, total: 0 },
      propertyId: this.props.propertyId,
      projectId: this.props.projectId
    };

    this.state = { values: this.copy(this.startValues) };
    if (this.props.projectId) this.reset("charcoal");
  }

  getFixed = type => {
    let value = _.get(this.state.values, type) || 0;
    value = parseFloat(value);
    return value.toLocaleString("pt-BR", {
      maximumFractionDigits: 1,
      minimumFractionDigits: 1
    });
  };

  main = classes => {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.container}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} className={classes.external}>
                  <span className={classes.internal}>
                    Total de madeira enfornada
                  </span>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.external}>
                  <Grid container className={classes.internal}>
                  <Grid item sm={12}>
                      <span className={classes.measures}>
                        {this.getFixed('totalHangingWood["m³/ano"]')} m³/ano
                      </span>
                    </Grid>                    
                    <Grid item sm={12}>
                      <span className={classes.measures}>
                        {this.getFixed('totalHangingWood["st/ano"]')} st/ano
                      </span>
                    </Grid>
                    <Grid item sm={12}>
                      <span className={classes.measures}>
                        {this.getFixed('totalHangingWood["t/ano"]')} t/ano
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.container}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} className={classes.external}>
                  <span className={classes.internal}>
                    Produção total de carvão
                  </span>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.external}>
                  <Grid container className={classes.internal}>
                    <Grid item sm={12}>
                      <span className={classes.measures}>
                        {this.getFixed('totalProduction["mdc/ano"]')} mdc/ano
                      </span>
                    </Grid>
                    <Grid item sm={12}>
                      <span className={classes.measures}>
                        {this.getFixed('totalProduction["t/ano"]')} t/ano
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.container}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} className={classes.external}>
                  <Grid container className={classes.internal}>
                    <Grid item sm={12}>
                      <span>Geração total de finos</span>
                    </Grid>
                    <Grid item sm={12}>
                      <span>Geração total de atiços</span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.external}>
                  <Grid container className={classes.internal}>
                    <Grid item sm={12}>
                      <span className={classes.measures}>
                        {this.getFixed("totalGenerationOfFines")} t/ano
                      </span>
                    </Grid>
                    <Grid item sm={12}>
                      <span className={classes.measures}>
                        {this.getFixed("totalGenerationOfServings")} t/ano
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.root}>
          <Grid item xs={12} sm={8}>
            <h2 className={classes.subtitle}>Produção</h2>
            <Paper className={classes.container}>
              <Production
                values={this.state.values}
                handleChange={this.handleChange}
                errors={this.state.errors}
                validate={this.validate}
                updateValue={this.updateValue}
                project={this.state.project}
                updateCalculatedValues={this.updateCalculatedValues}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <h2 className={classes.subtitle}>Qualidade</h2>
            <Paper className={classes.container}>
              <Quality
                values={this.state.values}
                handleChange={this.handleChange}
                errors={this.state.errors}
                validate={this.validate}
                updateValue={this.updateValue}
                updateCalculatedValues={this.updateCalculatedValues}
              />
            </Paper>

            <h2 className={classes.subtitle}>Rendimento volumétrico (RV)</h2>
            <Paper className={classes.container}>
              <RV
                values={this.state.values}
                handleChange={this.handleChange}
                errors={this.state.errors}
                validate={this.validate}
                updateValue={this.updateValue}
                updateCalculatedValues={this.updateCalculatedValues}
              />
            </Paper>

            <h2 className={classes.subtitle}>Rendimento gravimétrico (RG)</h2>
            <Paper className={classes.container}>
              <RG
                values={this.state.values}
                project={this.state.project}
                handleChange={this.handleChange}
                errors={this.state.errors}
                validate={this.validate}
                updateValue={this.updateValue}
                updateCalculatedValues={this.updateCalculatedValues}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12}>
            <h2 className={classes.subtitle}>Coprodutos</h2>
            <Paper className={classes.container}>
              <Cooproducts
                values={this.state.values}
                handleChange={this.handleChange}
                errors={this.state.errors}
                validate={this.validate}
                updateValue={this.updateValue}
                updateCalculatedValues={this.updateCalculatedValues}
              />
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
}

export default withRouter(withStyles(styles)(Charcoal));
