import _ from "lodash";
import React from "react";

import { Grid, Paper } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

import FormDefault from "../../../../FormDefault";

import Ovens from "./Ovens";
import Furnace from "./Furnace";
import Infraestructure from "./Infraestructure";
import Transport from "./Transport";

class Input extends FormDefault {
  getInputs = () => {
    return [
      { render: <Grid item sm={1}></Grid> },
      {
        type: "select",
        id: "operationalLabor",
        label: "Mão de Obra Operacional",
        options: ["Operações Primarizadas", "Operações Terceirizadas"],
        sm: 5
      }
    ];
  };
}

Input = withStyles(styles)(Input);

class ValueFixedByStep extends React.Component {
  getFixed = type => {
    let value = _.get(this.props.values, type) || 0;
    if (isNaN(value)) {
      value = value
        .replace("R$", "")
        .replace(/\./g, "")
        .replace(",", ".");
    }
    value = parseFloat(value) || 0;
    return value.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <h2 className={classes.subtitle}>Produção de carvão vegetal</h2>
            </Grid>
            <Grid item sm={12}>
              <Paper className={classes.subtitlePaper}>
                <h2 className={classes.subheader}>Valor Fixo por Etapa</h2>
              </Paper>
            </Grid>
            <Input
              className={classes.container}
              values={this.props.values}
              handleChange={this.props.handleChange}
              validate={this.props.validate}
            />
            <Grid item xs={12} sm={6}>
              <Paper className={classes.container}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} className={classes.external}>
                    <span className={classes.internal}>
                      Total em construção
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.external}>
                    <Grid container className={classes.internal}>
                      <Grid item sm={12}>
                        <span className={classes.measures}>
                          Fornos: R$ {this.getFixed("ovens.totalCost")}
                        </span>
                      </Grid>
                      <Grid item sm={12}>
                        <span className={classes.measures}>
                          Infraestrutura: R${" "}
                          {this.getFixed("infrastructure.totalCost")}
                        </span>
                      </Grid>
                      <Grid item sm={12}>
                        <span className={classes.measures}>
                          Fornalha: R$ {this.getFixed("furnace.totalCost")}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.container}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} className={classes.external}>
                    <span className={classes.internal}>
                      Total anual em manutenção
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.external}>
                    <Grid container className={classes.internal}>
                      <Grid item sm={12}>
                        <span className={classes.measures}>
                          Fornos: R$ {this.getFixed("ovens.totalYearlyCost")}
                        </span>
                      </Grid>
                      <Grid item sm={12}>
                        <span className={classes.measures}>
                          Infraestrutura: R${" "}
                          {this.getFixed("infrastructure.totalYearlyCost")}
                        </span>
                      </Grid>
                      <Grid item sm={12}>
                        <span className={classes.measures}>
                          Fornalha: R${" "}
                          {this.getFixed("furnace.totalYearlyCost")}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item sm={6}>
              <Paper className={classes.subtitlePaper}>
                <h2 className={classes.subheader}>1 - Fornos</h2>
              </Paper>
            </Grid>
            <Grid item sm={6}>
              <Paper className={classes.subtitlePaper}>
                <h2 className={classes.subheader}>2 - Infraestrutura</h2>
              </Paper>
            </Grid>
            <Grid item sm={6}>
              <Paper className={classes.container}>
                <Ovens
                  values={this.props.values}
                  handleChange={this.props.handleChange}
                  validate={this.props.validate}
                />
              </Paper>
            </Grid>
            <Grid item sm={6}>
              <Paper className={classes.container}>
                <Infraestructure
                  values={this.props.values}
                  handleChange={this.props.handleChange}
                  validate={this.props.validate}
                />
              </Paper>
            </Grid>
            <Grid item sm={6}>
              <Paper className={classes.subtitlePaper}>
                <h2 className={classes.subheader}>3 - Fornalha</h2>
              </Paper>
            </Grid>
            <Grid item sm={6}>
              <Paper className={classes.subtitlePaper}>
                <h2 className={classes.subheader}>4 - Frete</h2>
              </Paper>
            </Grid>
            <Grid item sm={6}>
              <Paper className={classes.container}>
                <Furnace
                  values={this.props.values}
                  handleChange={this.props.handleChange}
                  validate={this.props.validate}
                />
              </Paper>
            </Grid>
            <Grid item sm={6}>
              <Paper className={classes.container}>
                <Transport
                  values={this.props.values}
                  handleChange={this.props.handleChange}
                  validate={this.props.validate}
                />
              </Paper>
            </Grid>
          </Grid>
        </main>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ValueFixedByStep);
