const estimated = {
  Circular: {
    "Sem Ferramenta": {
      "< 30%": 34,
      "31% - 35%": 32,
      "36% - 40%": 30,
      "> 40%": 28
    },
    "Com Ferramenta": {
      "< 30%": 36,
      "31% - 35%": 34,
      "36% - 40%": 32,
      "> 40%": 30
    }
  },
  Retangular: {
    "Sem Ferramenta": {
      "< 30%": 34,
      "31% - 35%": 32,
      "36% - 40%": 30,
      "> 40%": 28
    },
    "Com Ferramenta": {
      "< 30%": 36,
      "31% - 35%": 34,
      "36% - 40%": 32,
      "> 40%": 30
    }
  },
  "Fornos de Encosta": {
    "Sem Ferramenta": {
      "< 30%": 34,
      "31% - 35%": 32,
      "36% - 40%": 30,
      "> 40%": 28
    },
    "Com Ferramenta": {
      "< 30%": 0,
      "31% - 35%": 0,
      "36% - 40%": 0,
      "> 40%": 0
    }
  }
};

export default estimated;
