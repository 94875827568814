import FormDefault from "../../../FormDefault";
import { withStyles } from "@material-ui/core/styles";
import styles from "../styles";

import React from "react";
import { Divider, Grid } from "@material-ui/core";

class Production extends FormDefault {
  getInputs = () => {
    return [
      {
        type: "number",
        id: "stackingFactor",
        label: "Fator de Empilhamento (m³/st)",
        inputProps: {
          inputProps: { min: 0 }
        },
        sm: 12
      },
      {
        render: (
          <Grid item xs={12} sm={12}>
            <Divider />
          </Grid>
        )
      },
      {
        type: "number",
        id: `woodHangedByCicle[${this.props.values.woodHangedByCicleType}]`,
        label: "Madeira enfornada por ciclo",
        inputProps: { inputProps: { min: 0 } },
        sm: 8
      },
      {
        type: "select",
        id: "woodHangedByCicleType",
        label: "Unidade",
        options: ["st/forno", "m³/forno"],
        sm: 4
      }
    ];
  };
}

export default withStyles(styles)(Production);
