import React from "react";

import FormDefault from "../../../../FormDefault";

import { Grid } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

export const fields = {};

class Economic extends FormDefault {
  changeObtainingData = event => {
    this.props.updateValue("wayOfObtainingData", event.target.value);
    this.props.resetValues();
    this.props.save();
  };

  getInputs = () => {
    const { classes } = this.props;
    return [
      {
        type: "number",
        id: "planningHorizon",
        label: "Horizonte de Planejamento",
        disabled: true,
        sm: 10
      },
      {
        render: (
          <Grid item sm={2} className={classes.external}>
            <span className={classes.internal}>Anos</span>
          </Grid>
        )
      },
      {
        type: "select",
        id: "wayOfObtainingData",
        label: "Forma de obtenção dos dados",
        helperText: "As opções distinguem-se entre si pelo nível de detalhamento dos dados coletados. Valor fixo por etapa: custo médio de produção para construção e manutenção dos fornos, infraestruturas e fornalhas e custo de frete do carvão. Valores discriminados: detalhamento dos custos de produção em todas as etapas, incluindo custos com insumos, mão de obra, máquinas e equipamentos, custo da terra, frete e taxas administrativas.",
        options: ["Valor fixo por etapa", "Valores discriminados"],
        inputProps: { onChange: this.changeObtainingData },
        sm: 12
      }
    ];
  };
}

export default withStyles(styles)(Economic);
