const styles = theme => ({
  root: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(0),
    height: "57.5vh",
    maxWidth: "98%",
    overflow: "auto"
  },
  main: {
    width: "100%",
    overflow: "hidden"
  },
  container: {
    padding: theme.spacing(2)
  },
  external: {
    display: "table",
    height: "12vh"
  },
  internal: {
    fontSize: "2vh",
    verticalAlign: "middle",
    textAlign: "center",
    color: theme.palette.primary.main,
    display: "table-cell"
  },
  title: {
    fontSize: "4vh",
    fontFamily: "Khmer MN"
  },
  subtitle: {
    fontSize: "2.5vh",
    fontFamily: "Khmer MN"
  },
  subheader: {
    fontSize: "2vh",
    fontFamily: "Khmer MN",
    color: theme.palette.primary.main,
    display: "block",
    textAlign: "center"
  },
  measures: {
    color: "blue",
    fontSize: "2vh"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  buttons: {
    alignItems: "center"
  }
});

export default styles;
