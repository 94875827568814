import React from "react";

import { Grid } from "@material-ui/core";

import FormDefault from "../../../FormDefault";
import { withStyles } from "@material-ui/core/styles";
import styles from "../styles";

export const fields = {
  startProject: "",
  endProject: ""
};

class Production extends FormDefault {
  getInputs = () => {
    return [
        { render: <Grid item sm={1}></Grid> },
        {
            type: "number",
            id: "startProject",
            label: "Início do Projeto",
            helperText: "Ano de início do projeto.",
            inputProps: { inputProps: { min: 2000, max: this.props.values.endProject || 2100 } },
            sm: 5
        },
        {
            type: "number",
            id: "endProject",
            label: "Final do Projeto",
            helperText: "Ano final do projeto.",
            inputProps: { inputProps: { min: this.props.values.startProject || 2000, max: 2100 } },
            sm: 5
        },
    ];
  };
}

export default withStyles(styles)(Production);
