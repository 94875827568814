import FormDefault from "../../../../FormDefault";
import MoneyMask from "../../../../Masks/Money";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

export const fields = {};

class Freight extends FormDefault {
  getInputs = () => {
    return [
      {
        id: "freight.avgFreightCost",
        label: "Custo médio (R$)",
        helperText: "Custo médio de frete do carvão. Esse campo só deve ser preenchido se o custo de frete for de responsabilidade do produtor.",
        inputProps: { inputComponent: MoneyMask },
        sm: 9
      },
      {
        type: "select",
        id: "freight.unit",
        label: "Unidade",
        options: ["/mdc", "/t"],
        sm: 3
      }
    ];
  };
}

export default withStyles(styles)(Freight);
