import FormDefault from "../../../FormDefault";
import { withStyles } from "@material-ui/core/styles";
import styles from "../styles";

import React from "react";
import { Divider, Grid } from "@material-ui/core";

class Planted extends FormDefault {
  getInputs = () => {
    const { classes } = this.props;
    return [
      {
        render: (
          <Grid item sm={12}>
            <Divider />
          </Grid>
        )
      },
      {
        render: (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <h3 className={classes.subheader}>Produtividade</h3>
            </Grid>
          </React.Fragment>
        )
      },
      {
        type: "number",
        id: "carbonRemoval.avgProductivity",
        label: "Volume total de madeira na idade de corte",
        helperText: "Volume total de madeira na idade de corte, por hectare.",
        inputProps: { inputProps: { min: 0 } },
        sm: 8
      },
      {
        type: "select",
        id: "carbonRemoval.avgProductivityUnit",
        label: "Unidade",
        options: ["m³/ha", "st/ha", "t/ha"],
        sm: 4
      }
    ];
  };
}

export default withStyles(styles)(Planted);
