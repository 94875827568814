import React from "react";

import {
  Button,
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanelActions,
  Icon,
  Grid,
  Typography
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Empty from "../../Empty";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

class List extends React.Component {
  getDetails = property => {
    const { classes } = this.props;

    if (this.props.type === "dashboard") {
      return (
        <React.Fragment>
          <Grid item sm={12}>
            <Typography className={classes.heading}>
              <b>Tamanho:</b> {property.size} ha
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <Typography className={classes.heading}>
              {property.city}/{property.state} - {property.zipcode}
            </Typography>
          </Grid>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Grid item xs={12} sm={8}>
            <Typography className={classes.heading}>
              <b>Nome da Propriedade:</b> {property.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.heading}>
              <b>Tamanho da propriedade:</b> {property.size} (ha)
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.heading}>
              <b>CEP:</b> {property.zipcode}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.heading}>
              <b>Cidade:</b> {property.city}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.heading}>
              <b>Estado:</b> {property.state}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.heading}>
              <b>Região:</b> {property.region}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography className={classes.heading}>
              <b>Coordenadas Geográficas: </b>
              {property.geoCoordinates}
            </Typography>
          </Grid>
        </React.Fragment>
      );
    }
  };

  render() {
    const { classes } = this.props;
    classes.root = this.props.overide.root;
    classes.heading = this.props.overide.heading;

    return (
      <React.Fragment>
        <Container className={classes.root}>
          {this.props.properties.length ? (
            this.props.properties.map(property => (
              <ExpansionPanel
                key={property._id}
                expanded={
                  this.props.openProperty
                    ? this.props.propertyId === property._id
                    : null
                }
                onChange={
                  this.props.openProperty
                    ? this.props.openProperty(property)
                    : null
                }
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    {property.name}
                  </Typography>
                  {this.props.type === "dashboard" ? null : (
                    <Typography className={classes.secondaryHeading}>
                      {property.zipcode} - {property.city}/{property.state}
                    </Typography>
                  )}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container className={classes.main} spacing={2}>
                    {this.getDetails(property)}
                  </Grid>
                </ExpansionPanelDetails>
                <ExpansionPanelActions>
                  <Button
                    size="small"
                    onClick={() => this.props.registerHandleClickOpen(property)}
                    startIcon={<Icon>create</Icon>}
                  >
                    Editar
                  </Button>
                  <Button
                    size="small"
                    onClick={() =>
                      this.props.confirmHandleClickOpen(property._id)
                    }
                    startIcon={<Icon>delete</Icon>}
                  >
                    Excluir
                  </Button>
                </ExpansionPanelActions>
              </ExpansionPanel>
            ))
          ) : (
            <Empty message="Ops, nenhuma propriedade ainda cadastrada" />
          )}
        </Container>
        <Grid container spacing={2}>
          {this.props.type === "dashboard" ? (
            <React.Fragment>
              <Grid item sm={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={this.props.registerHandleClickOpen}
                >
                  Cadastrar Propriedade
                </Button>
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid item sm={3}></Grid>
              <Grid item sm={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={this.props.registerHandleClickOpen}
                >
                  Cadastrar Propriedade
                </Button>
              </Grid>
              <Grid item sm={3}></Grid>
            </React.Fragment>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(List);
