import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import authRequest from "./authRequest";

import { Backdrop, Grid } from "@material-ui/core";

export default function WithAuth(ComponentToProtect) {
  return class extends Component {
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false
      };
    }

    async componentDidMount() {
      try {
        const res = await authRequest("GET", "checkToken");
        if (res.status === 200) {
          window.localStorage.username = res.data.profile.name;
          window.localStorage.userId = res.data._id;
          this.setState({ loading: false });
        } else {
          window.localStorage.clear();
          const error = new Error(res.error);
          this.setState({ loading: false, redirect: true });
          throw error;
        }
      } catch (err) {
        window.localStorage.clear();
        this.setState({ loading: false, redirect: true });
      }
    }

    render() {
      const { loading, redirect } = this.state;
      if (loading)
        return (
          <Backdrop open={loading}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div id="FireLoading">
                  <img src="/assets/fireloading.gif" alt="Fire" />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div id="Loading">
                  <img src="/assets/loading.gif" alt="Loading" />
                </div>
              </Grid>
            </Grid>
          </Backdrop>
        );
      if (redirect) return <Redirect to="/login" />;
      return <ComponentToProtect {...this.props} />;
    }
  };
}
