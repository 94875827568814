import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

import ReportGenerate from "./ReportGenerate";

class CharcoalProduction extends ReportGenerate {
  constructor(props) {
    super(props);
    this.title = "Produção de Carvão Vegetal";
  }

  getBase = () => {
    return [
      {
        title: "PRODUÇÃO DE CARVÃO VEGETAL",
        papers: [
          {
            fields: [
              { label: "Nome Completo:", value: window.localStorage.username },
              {
                label: "Nome da propriedade:",
                value: this.state.property.name,
                sm: 7
              },
              {
                label: "Tamanho:",
                value: this.state.property.size,
                unit: "ha",
                sm: 5
              },
              {
                label: "Cidade:",
                value: this.state.property.city,
                sm: 7
              },
              {
                label: "Estado:",
                value: this.state.property.state,
                sm: 5
              }
            ]
          },
          {
            fields: [
              { label: "Número do projeto:", value: this.state.project.number },
              {
                label: "Tipo de forno:",
                value: this.state.project.ovenType,
                sm: 7
              },
              {
                label: "Quantidade de fornos:",
                value: this.state.project.ovenAmmount,
                sm: 5
              },
              {
                label: "Queimador de gases:",
                value: this.state.project.gasBurner,
                sm: 7
              },
              {
                label: "Tipo de Queimador:",
                value: this.state.project.gasBurnerType || "-",
                sm: 5
              },
              {
                label: "Monitoramento da temperatura de carbonização:",
                value: this.state.project.carbTempMonitoring,
                sm: 7
              },
              {
                label: "Tipo de Ferramenta:",
                value: this.state.project.toolType || "-",
                sm: 5
              }
            ]
          },
          {
            fields: [
              {
                label: "Forma de obtenção dos dados:",
                value: `${this.state.values.wayOfObtainingData} (${this.state.values.operationalLabor})`
              }
            ]
          },
          {
            fields: [
              {
                label: "Horizonte de Planejamento (anos)",
                value: this.state.values.planningHorizon,
                unit: "anos"
              },
              {
                label: "Taxa Mínima de Atratividade (TMA)",
                value: this.state.values.minimumRateOfAttractiveness,
                unit: "%"
              },
              {
                label: "Períodos de capitalização",
                value: this.state.values.capturePeriods,
                unit: "anos"
              }
            ]
          }
        ]
      },
      {
        title: "TOTAL",
        papers: [
          {
            fields: [
              {
                label: "VPL",
                value: `R$ ${this.getFixed("vpl")}`,
                unit: this.state.values.vpl > 0 ? "Viável" : "Inviável"
              },
              {
                label: "VPE",
                value: `R$ ${this.getFixed("vpe")}`,
                unit: this.state.values.vpe > 0 ? "Viável" : "Inviável"
              },
              {
                label: "TIR",
                value: `${this.getFixed("tir")} %`,
                unit:
                  this.state.values.tir >
                  this.state.values.minimumRateOfAttractiveness
                    ? "Viável"
                    : "Inviável"
              },
              {
                label: "B/C",
                value: `R$ ${this.getFixed("bc")}`,
                unit: this.state.values.bc > 1 ? "Viável" : "Inviável"
              }
            ]
          },
          {
            sm: 5,
            fields: [
              {
                label: "Quantidade total de carvão vegetal",
                value: this.getFixed("coalTotalProduction"),
                unit: "mdc"
              }
            ]
          },
          {
            sm: 7,
            fields: [
              {
                label: "Custo médio de produção de carvão vegetal",
                value: `R$ ${this.getFixed("detailedCoalObtentionCost")}`,
                unit: "/mdc"
              }
            ]
          }
        ]
      }
    ];
  };
}

export default withRouter(withStyles(styles)(CharcoalProduction));
