import _ from "lodash";
import React from "react";
import { withRouter } from "react-router-dom";

import { Grid, Step, Paper, Stepper, StepLabel } from "@material-ui/core";

import ProjectDuration from "./Modules/ProjectDuration";

import CoalCalcMachine from "./CoalCalcMachine";
import Viability from "./Modules/Viability/Viability";
import ValueFixedByStep from "./Modules/ValueFixedByStep/ValueFixedByStep";
import Workers from "./Modules/Workers/Workers";
import Equipaments from "./Modules/Equipaments/Equipaments";
import EarthCost from "./Modules/EarthCost/EarthCost";
import Receipts from "./Modules/Receipts/Receipts";
import Inputs from "./Modules/Inputs/Inputs";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

class CoalViability extends CoalCalcMachine {
  constructor(props) {
    super(props);
    this.title = "Obtenção do Carvão";
    this.nextStep = "/home/relatorios/balanco";
    this.startValues = {
      _id: "new",
      charge: '',
      administrationFee: '',
      endProject: '',
      startProject: '',
      otherTaxes: '',
      minimumRateOfAttractiveness: '',
      interestRate: '',
      casualWorker: [],
      permanentEmployee: [],
      wayOfObtainingData: '',
      machines: [],
      equipaments: [],
      maintenanceInputs: [],
      constructionInputs: [],
      infrastructure: { construction: 70, maintenance: 70 },
      freight: { unit: "/mdc" },
      recipes: [],
      propertyId: this.props.propertyId,
      projectId: this.props.projectId
    };

    if (this.props.projectId) this.reset();
  }

  resetValues = () => {
    this.state.values = _.assign(this.state.values, {
      casualWorker: [],
      operationalLabor: '',
      permanentEmployee: [],
      machines: [],
      equipaments: [],
      maintenanceInputs: [],
      constructionInputs: [],
      infrastructure: { construction: 70, maintenance: 70 },
      ovens: {},
      furnace: {},
      freight: { unit: "/mdc" },
      recipes: []
    });
  }

  componentDidMount() {
    this.setState({
      values: this.copy(this.startValues),
      activeStep: parseInt(this.props.match.params.step) - 1,
      labelStep: ""
    });
  }

  changeStep = (step, labelStep) => {
    this.setState({ activeStep: step, labelStep });
    this.saveAndRefresh();
    this.props.history.push(`/home/viabilidade/carvao/${step + 1}`);
  };

  renderStep = step => {
    switch (step) {
      case "Viabilidade":
        return (
          <Viability
            values={this.state.values}
            handleChange={this.handleChange}
            validate={this.validate}
            reset={this.reset}
            resetValues={this.resetValues}
            updateValue={this.updateValue}
            save={this.saveAndRefresh}
          />
        );
      case "Valor fixo por etapa":
        return (
          <ValueFixedByStep
            values={this.state.values}
            handleChange={this.handleChange}
            validate={this.validate}
            reset={this.reset}
            updateValue={this.updateValue}
          />
        );
      case "Insumos":
        return (
          <Inputs
            values={this.state.values}
            handleChange={this.handleChange}
            validate={this.validate}
            reset={this.reset}
            updateValue={this.updateValue}
          />
        );
      case "Mão de obra":
        return (
          <Workers
            values={this.state.values}
            handleChange={this.handleChange}
            validate={this.validate}
            reset={this.reset}
            updateValue={this.updateValue}
            save={this.saveAndRefresh}
          />
        );
      case "Máquinas e equipamentos":
        return (
          <Equipaments
            values={this.state.values}
            handleChange={this.handleChange}
            validate={this.validate}
            reset={this.reset}
            updateValue={this.updateValue}
            save={this.saveAndRefresh}
          />
        );
      case "Utilização da terra e frete":
        return (
          <EarthCost
            values={this.state.values}
            handleChange={this.handleChange}
            validate={this.validate}
            reset={this.reset}
            updateValue={this.updateValue}
          />
        );
      case "Receitas":
        return (
          <Receipts
            values={this.state.values}
            handleChange={this.handleChange}
            validate={this.validate}
            reset={this.reset}
            updateValue={this.updateValue}
          />
        );
      default:
        return "";
    }
  };

  renderStepper = steps => {
    const { classes } = this.props;
    return (
      <Grid item xs={12} sm={12}>
        <Stepper
          activeStep={this.state.activeStep}
          className={classes.stepper}
          alternativeLabel
          nonLinear
        >
          {steps.map((label, key) => (
            <Step
              className={classes.handleCursor}
              key={label}
              onClick={() => this.changeStep(key, label)}
            >
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
    );
  };

  getSteps = wayOfObtainingData => {
    let steps = ["Viabilidade"];

    if (wayOfObtainingData === "Valor fixo por etapa")
      steps.push("Valor fixo por etapa", "Receitas");
    else if (wayOfObtainingData === "Valores discriminados")
      steps.push(
        "Insumos",
        "Mão de obra",
        "Máquinas e equipamentos",
        "Utilização da terra e frete",
        "Receitas"
      );

    return steps;
  };

  renderContent = wayOfObtainingWood => {
    const { classes } = this.props;
    const wayOfObtainingData = _.get(this.state, "values.wayOfObtainingData");
    const steps = this.getSteps(wayOfObtainingData);
    this.state.labelStep = steps[this.state.activeStep];

    const hasError = (index) => this.state.errors[index],
      wasEmpty = (index) => !this.state.values[index];

    const invalidPeriod = 
      hasError("startProject") || 
      hasError("endProject") || 
      wasEmpty("startProject") || 
      wasEmpty("endProject");
      
    if(invalidPeriod) return null;

    return (
      <React.Fragment>
        <Grid item xs={12} sm={12} className={classes.root}>
          {this.renderStep(this.state.labelStep)}
        </Grid>
        {this.renderStepper(steps)}
      </React.Fragment>
    );
  };

  main = () => {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.container}>
              <ProjectDuration
                values={this.state.values}
                handleChange={this.handleChange}
                validate={this.validate}
                updateValue={this.updateValue}
                errors={this.state.errors}
                changeStep={this.changeStep}
                save={this.saveAndRefresh}
              />
            </Paper>
          </Grid>
          {this.renderContent(this.state.values.wayOfObtainingWood)}
        </Grid>
        <Grid container spacing={3} className={classes.root}></Grid>
      </React.Fragment>
    );
  };
}

export default withRouter(withStyles(styles)(CoalViability));
