import FormDefault from "../../../FormDefault";
import { withStyles } from "@material-ui/core/styles";
import styles from "../styles";

export const fields = {
  startProject: "",
  endProject: ""
};

class Production extends FormDefault {
  changeWay = event => {
    this.props.updateValue("wayOfObtainingWood", event.target.value);
    this.props.save();
    this.props.changeStep(0);
  };

  getInputs = () => {
    return [
      {
        type: "select",
        id: "wayOfObtainingWood",
        label: "Forma de Obtenção",
        helperText: "Forma de obtenção da madeira.",
        options: ["Compra", "Plantio Próprio", "Compra e Plantio Próprio"],
        inputProps: { onChange: this.changeWay },
        sm: 6
      },
      {
        type: "number",
        id: "startProject",
        label: "Início do Projeto",
        helperText: "Ano de início do projeto.",
        inputProps: { inputProps: { min: 2000, max: this.props.values.endProject || 2100 } },
        sm: 3
      },
      {
        type: "number",
        id: "endProject",
        label: "Final do Projeto",
        helperText: "Ano final do projeto.",
        inputProps: { inputProps: { min: this.props.values.startProject || 2000, max: 2100 } },
        sm: 3
      },
    ];
  };
}

export default withStyles(styles)(Production);
