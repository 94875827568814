import _ from "lodash";
import React from "react";
import { withRouter } from "react-router-dom";

import { Grid, Paper, Step, Stepper, StepLabel } from "@material-ui/core";

import WoodCalcMachine from "./WoodCalcMachine";
import ConsideredOfWood from "./Modules/ConsideredOfWood";
import WoodPurchase from "./Modules/WoodPurchase/WoodPurchase";
import Viability from "./Modules/Viability/Viability";
import ValueFixed from "./Modules/ValueFixed/ValueFixed";
import ValueFixedByStep from "./Modules/ValueFixedByStep/ValueFixedByStep";
import Inputs from "./Modules/Inputs/Inputs";
import Workers from "./Modules/Workers/Workers";
import Equipaments from "./Modules/Equipaments/Equipaments";
import EarthCost from "./Modules/EarthCost/EarthCost";
import Receipts from "./Modules/Receipts/Receipts";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

class WoodViability extends WoodCalcMachine {
    constructor(props) {
        super(props);
        this.title = "Obtenção da Madeira";
        this.nextStep = "/home/viabilidade/custo";
        this.startValues = {
            _id: "new",
            startProject: "",
            operationalLabor: "",
            endProject: "",
            wayOfObtainingWood: "",
            wayOfObtainingData: "",
            woodProcurement: "",
            cuttingRegime: "",
            planningHorizon: "",
            interestRate: "",
            charge: "",
            administrationFee: "",
            minimumRateOfAttractiveness: "",
            courtyardWoodPurchase: [],
            footWoodPurchase: [],
            totalProductionPerPlantingCycle: 0,
            totalCostOfProduction: 0,
            avgCostOfProduction: 0,
            plantingInputs: [],
            maintenanceInputs: [],
            harvestInputs: [],
            transportInputs: [],
            casualWorker: [],
            permanentEmployee: [],
            avgWoodSellingPrice: "",
            propertyId: this.props.propertyId,
            projectId: this.props.projectId,
        };

        if (this.props.projectId) this.reset();
    }

    componentDidMount() {
        this.setState({
            values: this.copy(this.startValues),
            activeStep: parseInt(this.props.match.params.step) - 1,
            labelStep: "",
            loading: false,
        });
    }

    resetValues = () => {
        this.state.values = Object.assign(this.state.values, {
            operationalLabor: "",
            totalProductionPerPlantingCycle: 0,
            totalCostOfProduction: 0,
            avgCostOfProduction: 0,
            avgWoodSellingPrice: "",
            courtyardWoodPurchase: [],
            footWoodPurchase: [],
            plantingInputs: [],
            maintenanceInputs: [],
            harvestInputs: [],
            transportInputs: [],
            casualWorker: [],
            permanentEmployee: [],
            harvest: {},
            maintenance: {},
            planting: {},
            transport: {},
            carbonRemoval: {},
            harvestedArea: "",
            cuttingAge: "",
            avgCost: "",
        });
    };

    changeStep = (step, labelStep) => {
        this.setState({ activeStep: step, labelStep });
        this.saveAndRefresh();
        this.props.history.push(`/home/viabilidade/madeira/${step + 1}`);
    };

    renderStep = (step) => {
        switch (step) {
            case "Compra da Madeira":
                return (
                    <WoodPurchase
                        values={this.state.values}
                        handleChange={this.handleChange}
                        validate={this.validate}
                        reset={this.reset}
                        save={this.saveAndRefresh}
                    />
                );
            case "Viabilidade":
                return (
                    <Viability
                        values={this.state.values}
                        handleChange={this.handleChange}
                        validate={this.validate}
                        reset={this.reset}
                        updateValue={this.updateValue}
                        resetValues={this.resetValues}
                        save={this.saveAndRefresh}
                    />
                );
            case "Valor fixo único":
                return (
                    <ValueFixed
                        values={this.state.values}
                        handleChange={this.handleChange}
                        validate={this.validate}
                        reset={this.reset}
                    />
                );
            case "Valor fixo por etapa":
                return (
                    <ValueFixedByStep
                        values={this.state.values}
                        handleChange={this.handleChange}
                        validate={this.validate}
                        reset={this.reset}
                    />
                );
            case "Insumos":
                return (
                    <Inputs
                        values={this.state.values}
                        handleChange={this.handleChange}
                        validate={this.validate}
                        reset={this.reset}
                    />
                );
            case "Mão de obra":
                return (
                    <Workers
                        values={this.state.values}
                        handleChange={this.handleChange}
                        validate={this.validate}
                        reset={this.reset}
                        updateValue={this.updateValue}
                        save={this.saveAndRefresh}
                    />
                );
            case "Máquinas e equipamentos":
                return (
                    <Equipaments
                        values={this.state.values}
                        handleChange={this.handleChange}
                        validate={this.validate}
                        reset={this.reset}
                        updateValue={this.updateValue}
                        save={this.saveAndRefresh}
                    />
                );
            case "Utilização da terra":
                return (
                    <EarthCost
                        values={this.state.values}
                        handleChange={this.handleChange}
                        validate={this.validate}
                        reset={this.reset}
                    />
                );
            case "Receitas":
                return (
                    <Receipts
                        values={this.state.values}
                        handleChange={this.handleChange}
                        validate={this.validate}
                        reset={this.reset}
                    />
                );
            default:
                return "";
        }
    };

    renderStepper = (steps) => {
        const { classes } = this.props;
        return (
            <Grid item xs={12} sm={12}>
                <Stepper
                    activeStep={this.state.activeStep}
                    className={classes.stepper}
                    alternativeLabel
                    nonLinear
                >
                    {steps.map((label, key) => (
                        <Step
                            className={classes.handleCursor}
                            key={label}
                            onClick={() => this.changeStep(key, label)}
                        >
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Grid>
        );
    };

    getSteps = (wayOfObtainingWood, wayOfObtainingData) => {
        let steps = [];

        if (!wayOfObtainingWood) return [];

        if (
            wayOfObtainingWood === "Compra" ||
            wayOfObtainingWood === "Compra e Plantio Próprio"
        )
            steps = ["Compra da Madeira"];

        if (wayOfObtainingWood !== "Compra") {
            steps.push("Viabilidade");

            if (!wayOfObtainingData) console.log();
            else if (wayOfObtainingData === "Valor fixo por etapa")
                steps.push("Valor fixo por etapa");
            else if (wayOfObtainingData === "Valor fixo único")
                steps.push("Valor fixo único");
            else if (wayOfObtainingData === "Valores discriminados")
                steps.push(
                    "Insumos",
                    "Mão de obra",
                    "Máquinas e equipamentos",
                    "Utilização da terra"
                );

            steps.push("Receitas");
        }

        return steps;
    };

    renderContent = (wayOfObtainingWood) => {
        const { classes } = this.props;
        const wayOfObtainingData = _.get(
            this.state,
            "values.wayOfObtainingData"
        );
        const steps = this.getSteps(wayOfObtainingWood, wayOfObtainingData);
        this.state.labelStep = steps[this.state.activeStep];

        const hasError = (index) => this.state.errors[index],
            wasEmpty = (index) => !this.state.values[index];

        const invalidPeriod =
            hasError("startProject") ||
            hasError("endProject") ||
            wasEmpty("startProject") ||
            wasEmpty("endProject");

        if (invalidPeriod) return null;

        return (
            <React.Fragment>
                <Grid item xs={12} sm={12} className={classes.root}>
                    {this.renderStep(this.state.labelStep)}
                </Grid>
                {this.renderStepper(steps)}
            </React.Fragment>
        );
    };

    main = () => {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <Paper className={classes.container}>
                            <ConsideredOfWood
                                values={this.state.values}
                                handleChange={this.handleChange}
                                validate={this.validate}
                                updateValue={this.updateValue}
                                errors={this.state.errors}
                                changeStep={this.changeStep}
                                save={this.saveAndRefresh}
                            />
                        </Paper>
                    </Grid>
                    {this.renderContent(this.state.values.wayOfObtainingWood)}
                </Grid>
                <Grid container spacing={3} className={classes.root}></Grid>
            </React.Fragment>
        );
    };
}

export default withRouter(withStyles(styles)(WoodViability));
