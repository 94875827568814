const styles = theme => ({
    baloon: {
        margin: "0 auto", 
        background: "#284243",
        fontFamily: "open sans",
        fontSize: "0.85em",
        lineHeight: "1.6em",  
        borderRadius: "15px",
        width: "300px",
        height: "auto",
        color: "#fff",
        padding: "20px",
        position: "absolute",
        top: "2.5rem",
        right: "-1.5vh",
        zIndex: "999",
        boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.12)",

        "&::after": {
            content: '""',
            width: "0",
            height: "0",
            position: "absolute",
            borderLeft: "20px solid transparent",
            borderRight: "20px solid transparent",
            /*Faz seta "apontar para baixo. Definir o valor como 'top' fará ela "apontar para cima" */
            /*Aqui entra a cor da "aba" do balão */
            borderBottom: "20px solid #284243",
            top: "-20px", /*localização. Experimente alterar para 'bottom'*/
            right: "5%"
        }
    }
});
  
export default styles;
  