import _ from "lodash";
import FormDefault from "../../../../FormDefault";
import MoneyMask from "../../../../Masks/Money";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

export const fields = {};

const unit = {
  "m³/ha": "/m³",
  "st/ha": "/st",
  "t/ha": "/t"
};

class Transport extends FormDefault {
  getInputs = () => {
    const { values } = this.props || {},
    { avgProductivityUnit } = values.carbonRemoval;

    _.set(values, "transport.avgCostUnit", unit[avgProductivityUnit]);

    return [
      {
        id: "transport.avgCost",
        label: "Custo médio de transporte",
        helperText: "Custo médio de transporte da madeira, do campo até o pátio ou Unidade de Produção de Carvão Vegetal (UPC).",
        inputProps: { inputComponent: MoneyMask },
        sm: 9
      },
      {
        id: "transport.avgCostUnit",
        label: "Unidade",
        disabled: true,
        sm: 3
      },
      {
        id: "transport.totalCost",
        label: "Custo total de transporte",
        inputProps: { inputComponent: MoneyMask },
        disabled: true,
        sm: 12
      }
    ];
  };
}

export default withStyles(styles)(Transport);
