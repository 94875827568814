import React from "react";

import FormDefault from "../../../../FormDefault";

import { Grid } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

export const fields = {};

class Infraestructure extends FormDefault {
  getInputs = () => {
    const { classes } = this.props;
    return [
      {
        id: "infrastructure.lifespan",
        label: "Vida útil (anos)",
        inputProps: { inputProps: { min: 0 } },
        sm: 12
      },
      {
        render: (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <h3 className={classes.insideHeader}>1) Construção</h3>
            </Grid>
          </React.Fragment>
        )
      },
      {
        id: "infrastructure.construction",
        label: "% do custo investido em infraestrutura",
        helperText: "O custo para construção das demais infraestruturas é relativo ao custo de construção dos fornos. O sistema fornece valor default, mas que pode ser alterado.",
        inputProps: { inputProps: { min: 0, max: 100 } },
        sm: 12
      },
      {
        render: (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <h3 className={classes.insideHeader}>2) Manutenção</h3>
            </Grid>
          </React.Fragment>
        )
      },
      {
        id: "infrastructure.maintenance",
        label: "% do custo investido em infraestrutura",
        helperText: "O custo de manutenção das demais infraestruturas é relativo ao custo de manutenção dos fornos. O sistema fornece valor default, mas que pode ser alterado.",
        inputProps: { inputProps: { min: 0, max: 100 } },
        sm: 12
      }
    ];
  };
}

export default withStyles(styles)(Infraestructure);
