import _ from "lodash";

import calcs from "./calcs";
import Control from "../Control";

export default class CalcMachine extends Control {
  get = (path, source = "values") => {
    let value = _.get(this.state[source], path) || "0";
    if (isNaN(value)) {
      value = value.replace("R$", "").replace(/\./g, "").replace(",", ".");
    }
    return parseFloat(value) || "0";
  };

  calculate = (indicator) => {
    let exp = _.get(calcs, indicator);
    if (!exp) exp = _.get(calcs.always, indicator);

    if (!exp) return _.set(this.state.values, indicator, 0.0);
    const variables = exp
      .replace(/\(|\)/g, "")
      .split(/(\s[+|\-|*|/|,]\s)|(\s\|\|\s)/g);

    variables.forEach((variable) => {
      if (variable)
        variable = variable.replace(/(\s[+|\-|*|/|,]\s)|(\s\|\|\s)/g);

      if (isNaN(variable) && variable !== "Math.pow") {
        if (variable) {
          let value = this.get(variable);
          if (value !== "0") exp = exp.replace(variable, value);
          else {
            value = this.get(variable, "project");
            if (value !== "0") exp = exp.replace(variable, value);
            else {
              value = this.get(variable, "property");
              exp = exp.replace(variable, value);
            }
          }
        }
      }
    });

    // eslint-disable-next-line
    exp = eval(exp) || 0;

    _.set(this.state.values, indicator, exp);
  };

  refreshAlways = () => {
    Object.keys(calcs.always).forEach((indicator) => this.calculate(indicator));
  };

  calcTir = (values) => {
    if (!_.get(values, "costs")) values.costs = [];
    if (!_.get(values, "bruteRecipes"))
      values.bruteRecipes = [values.totalRecipes];

    const montantes = [...values.costs, ...values.bruteRecipes];
    var juros_inicial = -1.0;
    var juros_final = 1.0;
    var vpl_inicial = 0.0;
    var vpl_final = 0.0;
    var erro = 1e-10; // Valor mínimo que determina que a raiz foi encontrada

    function sinal(x) {
      return x < 0.0 ? -1 : 1;
    }

    // Procura um possível intervalo para a raiz
    // O looping deve terminar antes de i chegar a 100!
    function vpl(taxa, montantes) {
      var ret = montantes[0];

      for (var i = 1; i < montantes.length; i++)
        ret += montantes[i] / Math.pow(1.0 + taxa, i);
      return ret;
    }

    for (var i = 0; i < 100; i++) {
      vpl_inicial = vpl(juros_inicial, montantes);
      vpl_final = vpl(juros_final, montantes);
      if (sinal(vpl_inicial) !== sinal(vpl_final)) break;
      juros_inicial -= 1.0;
      juros_final += 1.0;
    }

    // Contador que evita um looping infinito
    var count = 0;

    // Busca por Bisseção
    for (;;) {
      var juros_medio = (juros_inicial + juros_final) / 2.0;
      var vpl_medio = vpl(juros_medio, montantes);

      if (Math.abs(vpl_medio) <= erro) {
        // Resultado foi encontrado
        return juros_medio * 100.0;
      }

      if (sinal(vpl_inicial) === sinal(vpl_medio)) {
        juros_inicial = juros_medio;
        vpl_inicial = vpl(juros_medio, montantes);
      } else {
        juros_final = juros_medio;
        vpl_final = vpl(juros_medio, montantes);
      }

      // Evita um possível looping infinito
      if (++count > 10000) return 0;
    }
  };
}
