import MoneyMask from "../../../../Masks/Money";
import FormDefault from "../../../../FormDefault";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

export const fields = {};

class Register extends FormDefault {
  constructor(props) {
    super(props);

    this.state = {
      "Madeira Comprada em pé": [
        {
          type: "select",
          id: "footWoodPurchase.0.year",
          label: "Ano",
          helperText: "Ano de compra da madeira.",
          rightBaloon: true,
          options: [],
          sm: 1
        },
        {
          type: "select",
          id: "footWoodPurchase.0.unit",
          label: "Unidade",
          options: ["m³", "st", "t"],
          sm: 1
        },
        {
          id: "footWoodPurchase.0.count",
          label: "Quantidade",
          type: "number",
          inputProps: { inputProps: { min: 0 } },
          sm: 2
        },
        {
          id: "footWoodPurchase.0.avgPrice",
          label: "Preço médio (R$/unid.)",
          inputProps: { inputComponent: MoneyMask },
          sm: 2
        },
        {
          id: "footWoodPurchase.0.harvestCost",
          label: "Custo de colheita (R$/unid.)",
          inputProps: { inputComponent: MoneyMask },
          sm: 2
        },
        {
          id: "footWoodPurchase.0.transportCost",
          label: "Custo de transporte (R$/unid.)",
          inputProps: { inputComponent: MoneyMask },
          sm: 2
        }
      ],
      "Madeira Comprada no pátio": [
        {
          type: "select",
          id: "courtyardWoodPurchase.0.year",
          label: "Ano",
          helperText: "Ano de compra da madeira.",
          rightBaloon: true,
          options: [],
          sm: 2
        },
        {
          type: "select",
          id: "courtyardWoodPurchase.0.unit",
          label: "Unidade",
          options: ["m³", "st", "t"],
          sm: 2
        },
        {
          id: "courtyardWoodPurchase.0.count",
          label: "Quantidade",
          type: "number",
          inputProps: { inputProps: { min: 0 } },
          sm: 2
        },
        {
          id: "courtyardWoodPurchase.0.avgPrice",
          label: "Preço médio (R$/unid.)",
          inputProps: { inputComponent: MoneyMask },
          sm: 4
        }
      ],
      inputs: [
        {
          title: "Madeira Comprada em pé",
          value: "footWoodPurchase",
          fields: []
        },
        {
          title: "Madeira Comprada no pátio",
          value: "courtyardWoodPurchase",
          fields: []
        }
      ]
    };
  }

  setOptions = array => {
    array.options = [];
    const { values } = this.props;

    for (let i = values.startProject; i <= values.endProject; i++)
      array.options.push(i);
  }

  setYearOptions = () => {
    this.setOptions(this.state["Madeira Comprada em pé"][0]);
    this.setOptions(this.state["Madeira Comprada no pátio"][0]);
  };

  getInputs = () => {
    this.setYearOptions();
    return this.state.inputs;
  };
}

export default withStyles(styles)(Register);
