const styles = theme => ({
  handleCursor: {
    cursor: "pointer"
  },
  stepper: {
    padding: "0",
    background: "none",
    cursor: "pointer"
  },
  nopadding: {
    padding: 0
  },
  workerList: {
    height: "23vh",
    maxWidth: "98%",
    overflowY: "auto",
    overflowX: "hidden"
  },
  root: {
    height: "60vh",
    maxWidth: "98%",
    overflowY: "auto",
    overflowX: "hidden"
  },
  main: {
    width: "100%",
    overflow: "hidden"
  },
  subtitlePaper: {
    background: theme.palette.primary.main
  },
  container: {
    padding: theme.spacing(2)
  },
  fittedContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  highlitedGood: {
    color: "blue",
    fontSize: "2.5vh"
  },
  highlitedBad: {
    color: "red",
    fontSize: "2.5vh"
  },
  external: {
    display: "table",
    height: "10vh"
  },
  internal: {
    fontSize: "2vh",
    verticalAlign: "middle",
    textAlign: "center",
    color: theme.palette.primary.main,
    display: "table-cell"
  },
  unitInternal: {
    fontSize: "3vh",
    verticalAlign: "middle",
    textAlign: "center",
    color: theme.palette.primary.main,
    display: "table-cell"
  },
  title: {
    fontSize: "4vh",
    fontFamily: "Khmer MN"
  },
  subtitle: {
    marginLeft: "2%",
    margin: "0",
    fontSize: "2.5vh",
    fontFamily: "Khmer MN"
  },
  subheader: {
    fontSize: "2vh",
    fontFamily: "Khmer MN",
    color: theme.palette.secondary.main,
    display: "block",
    textAlign: "center",
    padding: theme.spacing(0.5)
  },
  measures: {
    color: "blue",
    fontSize: "2vh"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  buttons: {
    alignItems: "center"
  },
  editButton: {
    color: "#FFF",
    background: "#2B4F3C"
  }
});

export default styles;
