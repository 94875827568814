import _ from "lodash";
import React from "react";

import { Grid, Paper } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

import Wood from "./Wood";
import Financial from "./Financial";

class EarthCost extends React.Component {
  getFixed = (type, fraction = 2) => {
    let value = _.get(this.props.values, type) || 0;
    if (isNaN(value)) {
      value = value
        .replace("R$", "")
        .replace(/\./g, "")
        .replace(",", ".");
    }

    value = parseFloat(value) || 0;
    return value.toLocaleString("pt-BR", { 
      minimumFractionDigits: fraction,
      maximumFractionDigits: fraction
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <h2 className={classes.subtitle}>Plantio Próprio</h2>
            </Grid>
            <Grid item sm={12}>
              <Paper className={classes.subtitlePaper}>
                <h2 className={classes.subheader}>Utilização da Terra</h2>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.container}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8} className={classes.external}>
                    <span className={classes.internal}>Demanda de Madeira</span>
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.external}>
                    <Grid container className={classes.internal}>
                    <Grid item sm={12}>
                        <span className={classes.measures}>
                          {this.getFixed('totalHangingWood["m³/ano"]', 1)} m³/ano
                        </span>
                      </Grid>                      
                      <Grid item sm={12}>
                        <span className={classes.measures}>
                          {this.getFixed('totalHangingWood["st/ano"]', 1)} st/ano
                        </span>
                      </Grid>
                      <Grid item sm={12}>
                        <span className={classes.measures}>
                          {this.getFixed('totalHangingWood["t/ano"]', 1)} t/ano
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.container}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={7} className={classes.external}>
                    <span className={classes.internal}>
                      De acordo com a produtividade média, para atender a
                      demanda anual de madeira para carbonização a Área colhida
                      é:
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={5} className={classes.external}>
                    <Grid container className={classes.internal}>
                      <Grid item sm={12}>
                        <span className={classes.measures}>
                          {this.getFixed("carbonRemoval.woodDemand", 1)} ha
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item sm={6}>
              <Paper className={classes.container}>
                <Wood
                  values={this.props.values}
                  handleChange={this.props.handleChange}
                  validate={this.props.validate}
                />
              </Paper>
            </Grid>
            <Grid item sm={6}>
              <Paper className={classes.container}>
                <Financial
                  values={this.props.values}
                  handleChange={this.props.handleChange}
                  validate={this.props.validate}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper className={classes.container}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8} className={classes.external}>
                    <span className={classes.internal}>
                      Área de Florestas Nativas:
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.external}>
                    <Grid container className={classes.internal}>
                      <Grid item sm={12}>
                        <span className={classes.measures}>
                          {this.getFixed("nativeForestArea", 1)} ha
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper className={classes.container}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8} className={classes.external}>
                    <span className={classes.internal}>Área Total:</span>
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.external}>
                    <Grid container className={classes.internal}>
                      <Grid item sm={12}>
                        <span className={classes.measures}>
                          {this.getFixed("totalArea", 1)} ha
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper className={classes.container}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8} className={classes.external}>
                    <span className={classes.internal}>Custo anual:</span>
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.external}>
                    <Grid container className={classes.internal}>
                      <Grid item sm={12}>
                        <span className={classes.measures}>
                          R$ {this.getFixed("yearlyCost")}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </main>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(EarthCost);
