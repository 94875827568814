import React from "react";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import InfoIcon from "@material-ui/icons/Info";

class Helper extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: false }
    }

    toggleHelper = () => {
        this.setState({ open: !this.state.open })
    }

    render() {
        const { classes } = this.props;

        return <div>                
            <div className={this.state.open ? classes.baloon : "hide"}>
                {this.props.helperText}
            </div>
            <div className="info-icon" onMouseEnter={this.toggleHelper} onMouseLeave={this.toggleHelper}>
                <InfoIcon />
            </div>
        </div>;
    }
}
 
export default withStyles(styles)(Helper);