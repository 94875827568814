import FormDefault from "../../../../FormDefault";
import MoneyMask from "../../../../Masks/Money";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

export const fields = {};

class Planting extends FormDefault {
  getInputs = () => {
    return [
      {
        id: "planting.avgCost",
        label: "Custo médio de implantação (/ha)",
        helperText: "Custo médio de implantação do plantio, incluindo gastos com replantio.",
        inputProps: { inputComponent: MoneyMask },
        sm: 12
      },
      {
        id: "planting.totalCost",
        label: "Custo total de implantação",
        inputProps: { inputComponent: MoneyMask },
        disabled: true,
        sm: 12
      }
    ];
  };
}

export default withStyles(styles)(Planting);
