import FormDefault from "../../../FormDefault";
import { withStyles } from "@material-ui/core/styles";
import styles from "../styles";

import NumberMask from "../../../Masks/Number";

import React from "react";
import { Grid } from "@material-ui/core";

class RG extends FormDefault {
  changeRG = event => {
    this.props.updateValue("rg.value", event.target.value);
    this.props.updateCalculatedValues(false);
  };

  getInputs = () => {
    const { classes } = this.props;
    return [
      {
        render: (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <h3 className={classes.subheader}>Rendimento Gravimétrico</h3>
            </Grid>
          </React.Fragment>
        )
      },
      {
        type: "select",
        id: "rg.estimatedParam",
        label: "Parâmetro da estimativa do RG",
        options: [
          "Produção total de carvão e quantidade de madeira enfornada",
          "Característica da carbonização e teor de umidade base seca da madeira"
        ],
        sm: 12
      },
      {
        id: "rg.value",
        label: "Rendimento Gravimétrico (%)",
        helperText: "O sistema fornece um valor estimado, mas que pode ser alterado.",
        inputProps: {
          inputComponent: NumberMask,
          onChange: this.changeRG
        },
        sm: 12
      }
    ];
  };
}

export default withStyles(styles)(RG);
