import _ from "lodash";
import React from "react";
import { withRouter } from "react-router-dom";

import {
  Button,
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanelActions,
  Grid,
  Icon,
  Paper,
  Typography,
  Snackbar
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

import authRequest from "../../../authRequest";
import ConfirmDialog from "../../ConfirmDialog";
import DialogRegister from "../DialogRegister";
import Register from "./Register";
import Empty from "../../Empty";

class Machines extends React.Component {
  constructor(props) {
    super(props);
    const yearOptions = _.get(this.props, "location.state.values") || {};
    this.state = {
      registerOpen: false,
      confirmOpen: false,
      machines: [],
      selectedId: "",
      selected: null,
      yearOptions
    };
    this.refresh();
  }

  refresh = async () => {
    const machines = await authRequest("GET", "machine");
    this.setState({ machines: machines.data });
  };

  remove = async () => {
    await authRequest("DELETE", `machine/${this.state.selectedId}`);
    this.setState({ snackmessage: "Máquina removida com sucesso" });
    this.confirmHandleClose();
    this.refresh();
  };

  rollback = () => {
    this.props.history.goBack();
  };

  registerHandleClickOpen = (selected = {}) => {
    Object.assign(selected, this.state.yearOptions);
    this.setState({ registerOpen: true, selected });
  };
  registerHandleClose = () => {
    this.setState({ registerOpen: false, selected: null });
    this.refresh();
  };
  confirmHandleClickOpen = selectedId =>
    this.setState({ confirmOpen: true, selectedId });
  confirmHandleClose = () => this.setState({ confirmOpen: false });

  render() {
    const { classes } = this.props;
    const canReturn = _.get(this.props, "location.state.canReturn");
    return (
      <React.Fragment>
        <main className={classes.main}>
          <Paper className={classes.container}>
            <Grid container>
              <Grid item sm={2} className={classes.spacing}>
                {canReturn ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={this.rollback}
                    startIcon={<ArrowBackIcon />}
                  >
                    Voltar
                  </Button>
                ) : null}
              </Grid>
              <Grid item sm={8}>
                <h1 className={classes.title}>
                  Máquinas Cadastradas
                </h1>
              </Grid>
              <Grid item sm={12}>
                <Container className={classes.root}>
                  {this.state.machines.length ? (
                    this.state.machines.map(machine => (
                      <ExpansionPanel key={machine._id}>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading}>
                            {machine.name}
                          </Typography>
                          <Typography className={classes.secondaryHeading}>
                            R${" "}
                            {machine.acquisitionValue.toLocaleString(
                              "pt-BR"
                            )}{" "}
                            - {machine.lifespan} anos
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Grid container className={classes.main} spacing={2}>
                            <Grid item xs={12} sm={8}>
                              <Typography className={classes.heading}>
                                <b>Nome do machineo:</b> {machine.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <Typography className={classes.heading}>
                                <b>Vida útil:</b> {machine.lifespan} Anos
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <Typography className={classes.heading}>
                                <b>Anos de uso:</b>{" "}
                                {machine.yearsOfUse.map((year, key) => {
                                  return key ===
                                    machine.yearsOfUse.length - 1
                                    ? year
                                    : `${year} - `;
                                })}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography className={classes.heading}>
                                <b>Valor de Aquisição:</b> R${" "}
                                {machine.acquisitionValue.toLocaleString(
                                  "pt-BR"
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography className={classes.heading}>
                                <b>Valor ao final da vida útil:</b> R${" "}
                                {machine.endOfLifeValue.toLocaleString(
                                  "pt-BR"
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </ExpansionPanelDetails>
                        <ExpansionPanelActions>
                          <Button
                            size="small"
                            onClick={() =>
                              this.registerHandleClickOpen(machine)
                            }
                            startIcon={<Icon>create</Icon>}
                          >
                            Editar
                          </Button>
                          <Button
                            size="small"
                            onClick={() =>
                              this.confirmHandleClickOpen(machine._id)
                            }
                            startIcon={<Icon>delete</Icon>}
                          >
                            Excluir
                          </Button>
                        </ExpansionPanelActions>
                      </ExpansionPanel>
                    ))
                  ) : (
                    <Empty message="Ops, nenhuma máquina ainda cadastrada" />
                  )}
                </Container>
                <Grid container spacing={2}>
                  <Grid item sm={3}></Grid>
                  <Grid item sm={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => this.registerHandleClickOpen()}
                    >
                      Cadastrar Máquina
                    </Button>
                  </Grid>
                  <Grid item sm={3}></Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </main>
        <ConfirmDialog
          title="Excluir Máquina"
          content="Deseja realmente excluir essa máquina?"
          open={this.state.confirmOpen}
          handleClose={this.confirmHandleClose}
          action={this.remove}
        />
        <DialogRegister
          form={Register}
          type="machine"
          title="Máquina"
          open={this.state.registerOpen}
          handleClose={this.registerHandleClose}
          selected={this.state.selected}
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          key="message"
          open={this.state.snackmessage ? true : false}
          onClose={() => this.setState({ snackmessage: "" })}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.snackmessage}</span>}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(Machines));
