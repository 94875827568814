import Dashboard from "../Dashboard/Dashboard";

//Cadastros
import Property from "../Registers/Property/Property";
import Project from "../Registers/Project/Project";
import Worker from "../Registers/Worker/Worker";
import Equipament from "../Registers/Equipament/Equipament";
import Machine from "../Registers/Machine/Machine";

//Emissões de carbono
import Wood from "../GEE/Wood";
import Charcoal from "../GEE/Charcoal";
import CarbonRemoval from "../GEE/CarbonRemoval";

//Viabilidade
import WoodViability from "../WoodViability/WoodViability";
import Cost from "../Cost/Cost";
import CoalViability from "../CoalViability/CoalViability";

//Relatórios
import CarbonBalance from "../Reports/CarbonBalance";
import SteelEmissions from "../Reports/SteelEmissions";
import WoodObtention from "../Reports/WoodObtention";
import CharcoalProduction from "../Reports/CharcoalProduction";
import Contacts from "../Contacts";
import About from "../About";

const routes = [
  { path: "/home/dashboard", component: Dashboard },
  { path: "/home/cadastro/propriedades", component: Property },
  { path: "/home/cadastro/projetos", component: Project },
  { path: "/home/cadastro/funcionarios", component: Worker },
  { path: "/home/cadastro/equipamentos", component: Equipament },
  { path: "/home/cadastro/maquinas", component: Machine },
  { path: "/home/emissoes/madeira", component: Wood, type: "gee" },
  { path: "/home/emissoes/carvao", component: Charcoal, type: "gee" },
  { path: "/home/remocao", component: CarbonRemoval, type: "gee" },
  {
    path: "/home/viabilidade/madeira/:step",
    component: WoodViability,
    type: "wood"
  },
  {
    path: "/home/viabilidade/custo",
    component: Cost,
    type: "wood"
  },
  {
    path: "/home/viabilidade/carvao/:step",
    component: CoalViability,
    type: "coal"
  },
  { path: "/home/relatorios/balanco", component: CarbonBalance, type: "gee" },
  { path: "/home/relatorios/emissoes", component: SteelEmissions, type: "gee" },
  { path: "/home/relatorios/madeira", component: WoodObtention, type: "wood" },
  {
    path: "/home/relatorios/carvao",
    component: CharcoalProduction,
    type: "coal"
  },
  { path: "/home/contatos", component: Contacts },
  { path: "/home/sobre", component: About }
];

export default routes;
