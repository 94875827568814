import _ from "lodash";
import React from "react";
import { withRouter } from "react-router-dom";

import { Grid, Paper } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

import GEECalcMachine from "./GEECalcMachine";
import Quality from "./Wood/Quality";
import Production from "./Wood/Production";

class Wood extends GEECalcMachine {
  constructor(props) {
    super(props);

    this.title = "Madeira";
    this.nextStep = "/home/emissoes/carvao";
    this.startValues = {
      woodHangedByCicle: {
        "st/forno": 0,
        "m³/forno": 0,
        "t/forno": 0
      },
      woodHangedByYear: {
        "m³/forno": 0,
        "t/forno": 0
      },
      totalHangingWood: {
        "m³/ano": 0,
        "t/ano": 0,
        "st/ano": 0
      },
      woodHangedByCicleType: "",
      geneticMaterial: "",
      basicDensity: "",
      font: "",
      dryBaseMoistureContent: "",
      stackingFactor: "",
      propertyId: this.props.propertyId,
      projectId: this.props.projectId
    };

    this.state = { values: this.copy(this.startValues) };
    if (this.props.projectId) this.reset();
  }

  getFixed = type => {
    let value = _.get(this.state.values, type) || 0;
    value = parseFloat(value);
    return value.toLocaleString("pt-BR", {
      maximumFractionDigits: 1,
      minimumFractionDigits: 1
    });
  };

  main = classes => {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.container}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} className={classes.external}>
                  <span className={classes.internal}>
                    Madeira enfornada por ciclo
                  </span>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.external}>
                  <Grid container className={classes.internal}>
                    <Grid item sm={12}>
                      <span className={classes.measures}>
                        {this.getFixed('woodHangedByCicle["st/forno"]')}{" "}
                        st/forno
                      </span>
                    </Grid>
                    <Grid item sm={12}>
                      <span className={classes.measures}>
                        {this.getFixed('woodHangedByCicle["m³/forno"]')}{" "}
                        m³/forno
                      </span>
                    </Grid>
                    <Grid item sm={12}>
                      <span className={classes.measures}>
                        {this.getFixed('woodHangedByCicle["t/forno"]')} t/forno
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.root}>
          <Grid item xs={12} sm={6}>
            <h2 className={classes.subtitle}>Qualidade</h2>
            <Paper className={classes.container}>
              <Quality
                values={this.state.values}
                handleChange={this.handleChange}
                errors={this.state.errors}
                validate={this.validate}
                updateValue={this.updateValue}
                updateCalculatedValues={this.updateCalculatedValues}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <h2 className={classes.subtitle}>Produção</h2>
            <Paper className={classes.container}>
              <Production
                values={this.state.values}
                handleChange={this.handleChange}
                errors={this.state.errors}
                validate={this.validate}
                updateValue={this.updateValue}
                updateCalculatedValues={this.updateCalculatedValues}
              />
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
}

export default withRouter(withStyles(styles)(Wood));
