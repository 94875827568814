import React from "react";

import { Paper, Snackbar } from "@material-ui/core";

import authRequest from "../../../authRequest";
import ConfirmDialog from "../../ConfirmDialog";
import DialogRegister from "../DialogRegister";
import Register from "./Register";
import List from "./List";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

class Property extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      registerOpen: false,
      confirmOpen: false,
      properties: [],
      selectedId: "",
      selected: null
    };
    this.refresh();
  }

  refresh = async () => {
    const properties = await authRequest("GET", "property");
    this.setState({ properties: properties.data });
  };

  remove = async () => {
    await authRequest("DELETE", `property/${this.state.selectedId}`);
    this.setState({ snackmessage: "Propriedade removida com sucesso" });
    this.confirmHandleClose();
    this.refresh();
  };

  registerHandleClickOpen = selected => {
    this.setState({ registerOpen: true, selected });
  };
  registerHandleClose = () => {
    this.setState({ registerOpen: false, selected: null });
    this.refresh();
  };
  confirmHandleClickOpen = selectedId =>
    this.setState({ confirmOpen: true, selectedId });
  confirmHandleClose = () => {
    delete window.localStorage.propertyId;
    this.setState({ confirmOpen: false });
  };

  renderContent = type => {
    const { classes } = this.props;
    if (type === "dashboard") {
      return (
        <List
          properties={this.state.properties}
          propertyId={this.props.propertyId}
          registerHandleClickOpen={this.registerHandleClickOpen}
          confirmHandleClickOpen={this.confirmHandleClickOpen}
          openProperty={this.props.openProperty}
          overide={this.props.overide || classes}
          type={this.props.type || "property"}
        />
      );
    } else {
      return (
        <Paper className={classes.container}>
          <h1 className={classes.title}>Propriedades Cadastradas</h1>
          <List
            properties={this.state.properties}
            registerHandleClickOpen={this.registerHandleClickOpen}
            confirmHandleClickOpen={this.confirmHandleClickOpen}
            overide={this.props.overide || classes}
            type={this.props.type || "property"}
          />
        </Paper>
      );
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main className={classes.main}>
          {this.renderContent(this.props.type)}
        </main>
        <ConfirmDialog
          title="Excluir Propriedade"
          content="Deseja realmente excluir essa propriedade?"
          open={this.state.confirmOpen}
          handleClose={this.confirmHandleClose}
          action={this.remove}
        />
        <DialogRegister
          form={Register}
          type="property"
          title="Propriedade"
          open={this.state.registerOpen}
          handleClose={this.registerHandleClose}
          selected={this.state.selected}
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          key="message"
          open={this.state.snackmessage ? true : false}
          onClose={() => this.setState({ snackmessage: "" })}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.snackmessage}</span>}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Property);
