const styles = theme => ({
  title: {
    textAlign: "center",
    fontSize: "3vh",
    fontFamily: "Khmer MN",
    margin: "0 auto 0 0"
  },
  content: {
    margin: theme.spacing(4)
  },
  buttons: {
    marginTop: theme.spacing(2)
  }
});

export default styles;
