import React from "react";

import { Grid, Paper } from "@material-ui/core";

import Control from "../Control";

export default class ReportGenerate extends Control {
  constructor(props) {
    super(props);
    this.startValues = {};
    this.reset();
  }

  renderField = (field, key) => {
    const { classes } = this.props;

    const content = (
      <Grid
        item
        xs={field.sm || 12}
        sm={field.sm || 12}
        className={this.color}
        key={key}
      >
        <b>{field.label} </b>
        {field.label && field.label.indexOf(":") > -1 ? (
          <span>
            {field.value} {field.unit}
          </span>
        ) : (
          <span className={classes.alignRight}>
            {field.value} {field.unit}
          </span>
        )}
      </Grid>
    );

    this.count += field.sm || 12;

    if (this.count >= 12) {
      this.count = 0;
      if (this.color === classes.internal) this.color = classes.internalGrey;
      else this.color = classes.internal;
    }

    return content;
  };

  renderPaper = (paper, key) => {
    const { classes } = this.props;

    this.count = 0;
    this.color = classes.internal;

    return (
      <Grid item xs={paper.sm || 12} sm={paper.sm || 12} key={key}>
        {paper.subtitle ? (
          <Grid item xs={12} sm={12}>
            <h2 className={classes.subtitle}>{paper.subtitle}</h2>
          </Grid>
        ) : null}
        <Paper className={classes.container}>
          <Grid container spacing={2}>
            {paper.fields.map((field, key) => this.renderField(field, key))}
          </Grid>
        </Paper>
      </Grid>
    );
  };

  renderSection = (section, key) => {
    const { classes } = this.props;
    return (
      <Grid container spacing={2} key={key}>
        <Grid item xs={section.sm || 12} sm={section.sm || 12}>
          <Paper className={classes.subtitlePaper}>
            <h2 className={classes.subheader}>{section.title}</h2>
          </Paper>
        </Grid>
        {section.papers.map((paper, key) => this.renderPaper(paper, key))}
      </Grid>
    );
  };

  main() {
    const { classes } = this.props;
    const base = this.getBase();
    return (
      <React.Fragment>
        <main className={classes.root}>
          {base.map((section, key) => this.renderSection(section, key))}
        </main>
      </React.Fragment>
    );
  }
}
