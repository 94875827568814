const legalReservePercent = {
  "Demais regiões do país": 20,
  "Amazônia Legal": {
    "Situado em área de florestas": 80,
    "Situado em área de cerrado": 35,
    "Situado em área de campos gerais": 20
  }
};

export default legalReservePercent;
