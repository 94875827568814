import FormDefault from "../../../../FormDefault";
import MoneyMask from "../../../../Masks/Money";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

export const fields = {};

const unit = {
  "m³/ha": "R$/m³",
  "st/ha": "R$/st",
  "t/ha": "R$/t"
};

class Financial extends FormDefault {
  getInputs = () => {
    const { values } = this.props || {},
      { avgProductivityUnit } = values.carbonRemoval;

    values.avgCostUnit = unit[avgProductivityUnit];

    return [
      {
        id: "avgCost",
        label: "Custo médio",
        helperText: "Custo médio de produção da madeira, incluindo gastos com insumos, mão de obra, máquinas e equipamentos, custo da terra e taxas administrativas.",
        inputProps: { inputComponent: MoneyMask },
        sm: 9
      },
      {
        id: "avgCostUnit",
        label: "Unidade",
        disabled: true,
        sm: 3
      }
    ];
  };
}

export default withStyles(styles)(Financial);
