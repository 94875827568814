import _ from "lodash";
import React from "react";

import { Grid, Paper } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

import Earth from "./Earth";
import Freight from "./Freight";

class EarthCost extends React.Component {
  getFixed = type => {
    let value = _.get(this.props.values, type) || 0;
    if (isNaN(value)) {
      value = value
        .replace("R$", "")
        .replace(/\./g, "")
        .replace(",", ".");
    }
    value = parseFloat(value) || 0;
    return value.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <h2 className={classes.subtitle}>Valores discriminados</h2>
            </Grid>
            <Grid item sm={6}>
              <Paper className={classes.subtitlePaper}>
                <h2 className={classes.subheader}>Utilização da Terra</h2>
              </Paper>
            </Grid>
            <Grid item sm={6}>
              <Paper className={classes.subtitlePaper}>
                <h2 className={classes.subheader}>Frete</h2>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.container}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8} className={classes.external}>
                    <Grid container className={classes.internal}>
                      <Grid item sm={12}>
                        <span>Vida útil dos fornos</span>
                      </Grid>
                      <Grid item sm={12}>
                        <span>Custo anual</span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.external}>
                    <Grid container className={classes.internal}>
                      <Grid item sm={12}>
                        <span className={classes.measures}>
                          {_.get(this.props.values, "planningHorizon")} anos
                        </span>
                      </Grid>
                      <Grid item sm={12}>
                        <span className={classes.measures}>
                          R$ {this.getFixed("earthYearlyCost")}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item sm={6}>
              <Paper className={classes.container}>
                <Freight
                  values={this.props.values}
                  handleChange={this.props.handleChange}
                  validate={this.props.validate}
                />
              </Paper>
            </Grid>
            <Grid item sm={6}>
              <Paper className={classes.container}>
                <Earth
                  values={this.props.values}
                  handleChange={this.props.handleChange}
                  validate={this.props.validate}
                />
              </Paper>
            </Grid>
          </Grid>
        </main>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(EarthCost);
