const geneticMaterials = {
  "Clone 3336 (E. urophylla)": {
    estimated: 494.6666666666670,
    font: "Reis et al., 2012a"
  },
  "Clone A08 (E. grandis x E. urophylla)": {
    estimated: 530.0,
    font: "Arruda et al., 2011"
  },
  "Clone GG 100 (E. grandis x E. urophylla)": {
    estimated: 520.0,
    font: "Castro et al., 2013"
  },
  "Clone GG 157 (E. urophylla)": {
    estimated: 540.0,
    font: "Castro et al., 2013"
  },
  "Clone GG 3119 (E. camaldulensis)": {
    estimated: 570.0,
    font: "Castro et al., 2013"
  },
  "Clone GG 3192 (E. urophylla)": {
    estimated: 550.0,
    font: "Castro et al., 2013"
  },
  "Clone GG 3370 (E. grandis)": {
    estimated: 570.0,
    font: "Castro et al., 2013"
  },
  "Clone GG 3403 (E. urophylla)": {
    estimated: 550.0,
    font: "Castro et al., 2013"
  },
  "Clone GG 3520 (E. urophylla)": {
    estimated: 590.0,
    font: "Castro et al., 2013"
  },
  "Clone GG 3616 (E. camaldulensis)": {
    estimated: 530.0,
    font: "Castro et al., 2013"
  },
  "Clone GG 680 (E. grandis x E. urophylla)": {
    estimated: 560.0,
    font: "Castro et al., 2013"
  },
  "E. camaldulensis": {
    estimated: 600.5523076923080,
    font: "Pereira, 2012; Teixeira, 2018"
  },
  "E. camaldulensis x E. grandis x E. urophylla x E. sp.": {
    estimated: 560.0,
    font: "Fialho, 2018"
  },
  "E. cloeziana": {
    estimated: 560.0,
    font: "Fialho, 2018"
  },
  "E. grandis": {
    estimated: 559.4214285714290,
    font: "Teixeira, 2018"
  },
  "E. grandis x E. camaldulensis": {
    estimated: 558.6600000000000,
    font: "Teixeira, 2018"
  },
  "E. grandis x E. spp.": {
    estimated: 596.1400000000000,
    font: "Teixeira, 2018"
  },
  "E. grandis x E. urophylla": {
    estimated: 560.0,
    font: "Fialho, 2018"
  },
  "E. grandis x E. urophylla x E. tereticornis": {
    estimated: 566.1500000000000,
    font: "Teixeira, 2018"
  },
  "E. pellita x E. grandis": {
    estimated: 591.6300000000000,
    font: "Teixeira, 2018"
  },
  "E. resinifera x E. grandis": {
    estimated: 570.2400000000000,
    font: "Teixeira, 2018"
  },
  "E. urophylla": {
    estimated: 573.0517241379310,
    font: "Pereira, 2012; Teixeira, 2018"
  },
  "E. urophylla x E. camaldulensis x E. grandis x E. globulus": {
    estimated: 560.0,
    font: "Fialho, 2018"
  },
  "E. urophylla x E. camaldulensis x E. grandis x E. maidenii": {
    estimated: 560.0,
    font: "Fialho, 2018"
  },
  "E. urophylla x E. camaldulensis x E. grandis x E. sp.": {
    estimated: 560.0,
    font: "Fialho, 2018"
  },
  "E. urophylla x E. grandis": {
    estimated: 577.0090909090910,
    font: "Teixeira, 2018"
  },
  "E. urophylla x E. maidenii ": {
    estimated: 560.0,
    font: "Fialho, 2018"
  },
  "E. urophylla x E. pellita x E. sp.": {
    estimated: 560.0,
    font: "Fialho, 2018"
  },
  "E. urophylla x E. sp.": {
    estimated: 560.0,
    font: "Fialho, 2018"
  },
  "E. urophylla x E. sp. x E. globulus": {
    estimated: 560.0,
    font: "Fialho, 2018"
  },
  "E. urophylla x E. spp.": {
    estimated: 557.1600000000000,
    font: "Teixeira, 2018"
  },
  "Híbrido de E. grandis": {
    estimated: 577.0,
    font: "Pereira, 2012"
  },
  "Híbrido de E. urophylla": {
    estimated: 546.5,
    font: "Pereira, 2012"
  },
  "Outro": {
    font: "-"
  }
};

export default geneticMaterials;
