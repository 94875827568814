import React from "react";
import clsx from "clsx";
import { Drawer, IconButton } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { withStyles } from "@material-ui/core/styles";

import styles from "./styles.js";
import LeftList from "./LeftList";

class LeftBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <Drawer
        id="leftMenu"
        variant="permanent"
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !this.props.open && classes.drawerPaperClose
          ),
          root: classes.root
        }}
        open={this.props.open}
      >
        <div className={classes.toolbarIcon}>
          <img className={classes.avatar} alt="logo" src="/logo.png" />
          <IconButton onClick={this.props.handleDrawerToogle}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <LeftList
          className={classes.root}
          selectedIndex={this.props.selectedIndex}
          handleListItemClick={this.props.handleListItemClick}
          initialOpen={this.props.initialOpen}
        />
      </Drawer>
    );
  }
}

export default withStyles(styles)(LeftBar);
