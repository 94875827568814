import React from "react";
import axios from "axios";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import {
  Button,
  CssBaseline,
  TextField,
  Paper,
  Box,
  Link,
  Grid,
  Typography,
  Snackbar,
} from "@material-ui/core";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import Copyright from "../Copyright";
import styles from "./styles";

export default function Login() {
  const classes = styles();

  const slider = [1,2,3,4,5,6,7,8,9];

  if (window.localStorage.authToken) window.location.href = "/home/dashboard";

  const [values, setValues] = React.useState({ email: "", password: "" });
  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const [open, setOpen] = React.useState("");
  const handleClose = (event) => {
    setOpen("");
  };

  const submit = async () => {
    try {
      const token = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/login`,
        {
          email: values.email,
          password: values.password,
        }
      );

      window.localStorage.authToken = token.data.token;
      window.location.href = "/home/dashboard";
    } catch (ex) {
      console.log(ex);
      setOpen("Seu login ou senha estão incorretos");
    }
  };

  const keySubmit = async (e) => {
    if (e.key === "Enter") submit();
  };

  const forgotPassword = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/register/forgotPassword?email=${values.email}`
      );
      setOpen(response.data);
    } catch (ex) {
      setOpen(ex.response.data);
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid
        item
        id="Background"
        xs={false}
        sm={4}
        md={8}
        className={classes.image}
      >
        <div className={classes.logo}>
          <img src="/assets/home/logo_home.png" alt="logo"/>
        </div>
        <div className={classes.row}>
          <div className={classes.text}>
            <p>
              O Charcoal System é um sistema para quantificação das emissões de gases de efeito estufa (GEE), das remoções de carbono e da viabilidade técnica e econômica na produção de carvão vegetal. O sistema faz parte dos esforços do Projeto Siderurgia Sustentável para incentivar a adoção de tecnologias produtivas mais limpas e eficientes.
            </p>
            <p>
              Com as medidas propostas pelo Projeto Siderurgia Sustentável e a utilização do Charcoal System, espera-se contribuir para que o produtor tenha uma maior viabilidade do negócio, uma melhor qualidade do ambiente de trabalho e uma maior diversificação da produção no setor rural.
            </p>
          </div>
          <img className={classes.hands} src="/assets/home/hands.png" alt="hands"/>
        </div>
        <div className={classes.row}>
          <div className={classes.helpers}>
            <h2>Apoiadores:</h2>
            <Carousel width="50%" autoPlay className={classes.carousel} showArrows={false} showStatus={false} showThumbs={false} showIndicators={false} infiniteLoop>
              {slider.map((current) => 
                <div className={classes.contanierImg}>
                  <img src={`/assets/home/${current}.png`} alt={current}/>
                </div>                
              )}
            </Carousel>
          </div>
          <div className={classes.helpers}>
            <h2>Equipe técnica:</h2>
            <img src="/assets/home/technical.png" alt="technical"/>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography
            component="h1"
            variant="h5"
            color="primary"
            className={classes.header}
          >
            Bem vindo(a) ao<br/>Charcoal System!
          </Typography>
          <ValidatorForm className={classes.form} onSubmit={submit}>
            <TextValidator
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              value={values.email}
              onChange={handleChange("email")}
              onKeyDown={keySubmit}
              validators={["required", "isEmail"]}
              errorMessages={["Campo obrigatório", "E-mail inválido"]}
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              style={{ marginTop: "5%", marginBottom: "3%" }}
              variant="filled"
              margin="normal"
              required
              fullWidth
              value={values.password}
              onChange={handleChange("password")}
              onKeyDown={keySubmit}
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Grid container>
              <Grid item xs></Grid>
              <Grid item>
                <Link
                  onClick={forgotPassword}
                  href="#"
                  variant="body2"
                >
                  Esqueceu sua senha?
                </Link>
              </Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={submit}
            >
              Entrar
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              className={classes.register}
              href="/registrar"
            >
              Cadastrar
            </Button>
            <Box mt={5}>
              <Copyright />
            </Box>
          </ValidatorForm>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            key="message"
            open={open ? true : false}
            onClose={handleClose}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{open}</span>}
          />
        </div>
      </Grid>
    </Grid>
  );
}
