import React from "react";

import { Grid, Paper } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

import Economic from "./Economic";
import Taxes from "./Taxes";

export const fields = {};

class Viability extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <h2 className={classes.subtitle}>Produção de carvão vegetal</h2>
            </Grid>
            <Grid item sm={6}>
              <Paper className={classes.subtitlePaper}>
                <h2 className={classes.subheader}>Taxas</h2>
              </Paper>
            </Grid>
            <Grid item sm={6}>
              <Paper className={classes.subtitlePaper}>
                <h2 className={classes.subheader}>Viabilidade Econômica</h2>
              </Paper>
            </Grid>
            <Grid item sm={6}>
              <Paper className={classes.container}>
                <Taxes
                  values={this.props.values}
                  handleChange={this.props.handleChange}
                  validate={this.props.validate}
                  updateValue={this.props.updateValue}
                />
              </Paper>
            </Grid>
            <Grid item sm={6}>
              <Paper className={classes.container}>
                <Economic
                  values={this.props.values}
                  handleChange={this.props.handleChange}
                  validate={this.props.validate}
                  updateValue={this.props.updateValue}
                  resetValues={this.props.resetValues}
                  save={this.props.save}
                />
              </Paper>
            </Grid>
          </Grid>
        </main>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Viability);
