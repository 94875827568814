import FormDefault from "../../../FormDefault";
import { withStyles } from "@material-ui/core/styles";
import styles from "../styles";

import NumberMask from "../../../Masks/Number";

import React from "react";
import { Grid } from "@material-ui/core";

class Quality extends FormDefault {
  getInputs = () => {
    const { classes } = this.props;
    return [
      {
        render: (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <h3 className={classes.subheader}>Densidade à granel (kg/mdc)</h3>
            </Grid>
          </React.Fragment>
        )
      },
      {
        id: "bulkDensity",
        label: "Densidade à granel (kg/mdc)",
        inputProps: { inputComponent: NumberMask },
        sm: 12
      },
      {
        render: (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <h3 className={classes.subheader}>Teor de carbono fixo (%)</h3>
            </Grid>
          </React.Fragment>
        )
      },
      {
        id: "fixedCarbonContent",
        label: "Teor de carbono fixo (%)",
        inputProps: { inputComponent: NumberMask },
        sm: 12
      }
    ];
  };
}

export default withStyles(styles)(Quality);
