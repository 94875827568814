import MoneyMask from "../../../../Masks/Money";
import FormDefault from "../../../../FormDefault";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

export const fields = {
  woodPurchase: []
};

class Register extends FormDefault {
  constructor(props) {
    super(props);
    this.state = {
      Construção: [
        {
          id: "constructionInputs.0.year",
          label: "Ano",
          type: "select",
          helperText: "Ano de compra do insumo.",
          rightBaloon: true,
          options: [],
          sm: 1
        },        
        {
          type: "select",
          id: "constructionInputs.0.item",
          label: "Item",
          options: [
            "Barras de ferro rosqueadas",
            "Cantoneiras",
            "Chapas metálicas",
            "Cilindros metálicos com tampa",
            "Cilindros metálicos sem tampa",
            "Cintas metálicas de aço carbono",
            "Grelha metálica",
            "Material para gabaritos (ferro, madeira)",
            "Pinos de ferro",
            "Porta metálica",
            "Quadro para sustentação da porta",
            "Silicato",
            "Solo",
            "Suportes perfil em U de aço carbono",
            "Tijolos",
            "Trinco para a porta",
            "Tubos metálicos",
            "Válvulas borboletas",
            "Outro"
          ],
          sm: 3
        },
        {
          type: "select",
          id: "constructionInputs.0.unit",
          label: "Unidade",
          options: ["kg", "Litros", "Saca", "Toneladas", "Unidades", "Outra"],
          sm: 2
        },
        {
          id: "constructionInputs.0.count",
          label: "Quantidade",
          type: "number",
          inputProps: { inputProps: { min: 0 } },
          sm: 2
        },
        {
          id: "constructionInputs.0.value",
          label: "Valor (R$/unidade)",
          inputProps: { inputComponent: MoneyMask },
          sm: 2
        }
      ],
      Manutenção: [
        {
          id: "maintenanceInputs.0.year",
          label: "Ano",
          helperText: "Ano de compra do insumo.",
          rightBaloon: true,
          type: "select",
          options: [],
          sm: 1
        },        
        {
          type: "select",
          id: "maintenanceInputs.0.item",
          label: "Item",
          options: [
            "Barras de ferro rosqueadas",
            "Cantoneiras",
            "Chapas metálicas",
            "Cilindros metálicos com tampa",
            "Cilindros metálicos sem tampa",
            "Cintas metálicas de aço carbono",
            "Grelha metálica",
            "Material para gabaritos (ferro, madeira)",
            "Pinos de ferro",
            "Porta metálica",
            "Quadro para sustentação da porta",
            "Silicato",
            "Solo",
            "Suportes perfil em U de aço carbono",
            "Tijolos",
            "Trinco para a porta",
            "Tubos metálicos",
            "Válvulas borboletas",
            "Outro"
          ],
          sm: 3
        },
        {
          type: "select",
          id: "maintenanceInputs.0.unit",
          label: "Unidade",
          options: ["kg", "Litros", "Saca", "Toneladas", "Unidades", "Outra"],
          sm: 2
        },
        {
          id: "maintenanceInputs.0.count",
          label: "Quantidade",
          type: "number",
          inputProps: { inputProps: { min: 0 } },
          sm: 2
        },
        {
          id: "maintenanceInputs.0.value",
          label: "Valor (R$/unidade)",
          inputProps: { inputComponent: MoneyMask },
          sm: 2
        }
      ],
      inputs: [
        {
          title: "Construção",
          value: "constructionInputs",
          fields: []
        },
        {
          title: "Manutenção",
          value: "maintenanceInputs",
          fields: []
        }
      ]
    };
  }

  setOptions = array => {
    array.options = [];

    const { values } = this.props;

    for (let i = values.startProject; i <= values.endProject; i++)
      array.options.push(i);
  }

  setYearOptions = () => {
    this.setOptions(this.state["Construção"][0]);
    this.setOptions(this.state["Manutenção"][0]);
  };


  getInputs = () => {
    this.setYearOptions();
    return this.state.inputs;
  };
}

export default withStyles(styles)(Register);
