import FormDefault from "../../../../FormDefault";
import MoneyMask from "../../../../Masks/Money";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

export const fields = {};

class Harvest extends FormDefault {
  getInputs = () => {
    return [
      {
        id: "harvest.avgCost",
        label: "Custo médio de colheita (/ha)",
        helperText: "Custo médio para colheita do plantio.",
        inputProps: { inputComponent: MoneyMask },
        sm: 12
      },
      {
        id: "harvest.totalCost",
        label: "Custo total de colheita",
        inputProps: { inputComponent: MoneyMask },
        disabled: true,
        sm: 12
      }
    ];
  };
}

export default withStyles(styles)(Harvest);
