import React, { Component } from 'react'

import "./style.css";

export default class About extends Component {
    render() {
        return (
            <section className="about">
                <h1>Sobre o Charcoal System</h1>
                <section className="content">
                    <p>
                    O Charcoal System permite quantificar as emissões de gases de
                    efeito estufa (GEE), as remoções de carbono e a viabilidade
                    técnica e econômica na produção de carvão vegetal. Possuindo
                    caráter inovador e grande relevância no auxílio à tomada de
                    decisões, o sistema faz parte dos esforços do Projeto Siderurgia
                    Sustentável para incentivar a adoção de tecnologias produtivas
                    mais limpas e eficientes.{" "}
                    </p>
                    <p>
                    O Projeto Siderurgia Sustentável está alinhado aos Objetivos de
                    Desenvolvimento Sustentável (ODS), que têm como proposta erradicar
                    a pobreza, proteger o planeta e garantir que as pessoas alcancem a
                    paz e a prosperidade. Criado para fomentar a redução de emissões
                    de GEE na siderurgia brasileira, o Projeto busca o desenvolvimento
                    e a demonstração de tecnologias e processos sustentáveis para a
                    produção e uso de carvão vegetal na indústria de aço, ferro-gusa e
                    ferroligas de forma mais competitiva, colaborando para o
                    fortalecimento tecnológico do setor industrial brasileiro, com
                    amplo apoio à pesquisa e inovação. O Projeto conta com recursos do
                    Fundo Global para o Meio Ambiente (GEF) e é implementado pelo
                    Programa das Nações Unidas para o Desenvolvimento (PNUD), com
                    coordenação técnica do Ministério do Meio Ambiente, sendo
                    executado em conjunto com o Ministério da Economia; Ministério da
                    Ciência, Tecnologia, Inovações e Comunicações; Ministério da
                    Agricultura, Pecuária e Abastecimento e o Governo de Minas Gerais,
                    além da parceria técnica do Departamento de Engenharia Florestal,
                    da Universidade Federal de Viçosa.{" "}
                    </p>
                    <p>
                    Com as medidas propostas pelo Projeto e a utilização do Charcoal
                    System, espera-se contribuir para que o produtor tenha uma maior
                    viabilidade do negócio, uma melhor qualidade do ambiente de
                    trabalho e uma maior diversificação da produção no setor rural.{" "}
                    </p>
                </section>
            </section>
        )
    }
}
