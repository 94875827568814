import axios from "axios";
import React from "react";

import { Grid, TextField } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

class Login extends React.Component {
  validateEmail = () => {
    this.props.validate("email", "e-mail obrigatório");
    this.props.validate(
      "email",
      "E-mail inválido",
      !re.test(String(this.props.values.email).toLowerCase())
    );
  };

  validateOnBlur = async () => {
    this.validateEmail();
    try {
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/register/email/${this.props.values.email}`
      );
    } catch (ex) {
      this.props.handleOpen(ex.response.data);
      this.props.values.email = "";
    }
  };

  validateEmailConfirm = () => {
    this.props.validate(
      "emailConfirm",
      "Os e-mails não correspondem",
      this.props.values.emailConfirm !== this.props.values.email
    );
    this.props.validate(
      "emailConfirm",
      "E-mail inválido",
      !re.test(String(this.props.values.emailConfirm).toLowerCase())
    );
  };

  validatePassword = () => {
    this.props.validate("password", "senha obrigatória");
    if (this.props.values.password.length !== 0)
      this.props.validate(
        "password",
        "a senha deve possuir no mínimo 8 caracteres",
        this.props.values.password.length < 8
      );
  };

  validatePasswordConfirm = () => {
    this.props.validate(
      "passwordConfirm",
      "As senhas não correspondem",
      this.props.values.passwordConfirm !== this.props.values.password
    );
  };

  render() {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <TextField
              variant="outlined"
              margin="normal"
              error={this.props.errors.email ? true : false}
              value={this.props.values.email}
              onChange={this.props.handleChange("email")}
              required
              fullWidth
              disabled={this.props.values._id ? true : false}
              id="email"
              label="Email"
              onKeyDown={this.validateEmail}
              onBlur={this.validateOnBlur}
              helperText={this.props.errors.email}
              name="email"
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              variant="outlined"
              margin="normal"
              value={this.props.values.emailConfirm}
              onChange={this.props.handleChange("emailConfirm")}
              onKeyDown={this.validateEmailConfirm}
              onBlur={this.validateEmailConfirm}
              error={this.props.errors.emailConfirm ? true : false}
              helperText={this.props.errors.emailConfirm}
              required
              fullWidth
              id="emailConfirm"
              label="Confirmação de E-mail"
              name="emailConfirm"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              value={this.props.values.password}
              onChange={this.props.handleChange("password")}
              error={this.props.errors.password ? true : false}
              onKeyDown={this.validatePassword}
              onBlur={this.validatePassword}
              helperText={this.props.errors.password}
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              value={this.props.values.passwordConfirm}
              onChange={this.props.handleChange("passwordConfirm")}
              onKeyDown={this.validatePasswordConfirm}
              onBlur={this.validatePasswordConfirm}
              error={this.props.errors.passwordConfirm ? true : false}
              helperText={this.props.errors.passwordConfirm}
              required
              fullWidth
              name="passwordConfirm"
              label="Confirmação de senha"
              type="password"
              id="passwordConfirm"
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Login);
