import React from "react";
import { withRouter } from "react-router-dom";

import _ from "lodash";

import { Grid, Paper } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

import WoodCalcMachine from "../WoodViability/WoodCalcMachine";
import Financial from "./Financial";

class Cost extends WoodCalcMachine {
  constructor(props) {
    super(props);
    this.title = "Custo máx. da madeira e preço min. do carvão viáveis";
    this.nextStep = "/home/viabilidade/carvao/1";
    this.startValues = {
      _id: "new",
      coalProductionCost: "",
      fareCost: "",
      coalPrice: "",
      plantingInputs: [],
      maintenanceInputs: [],
      harvestInputs: [],
      transportInputs: [],
      casualWorker: [],
      permanentEmployee: [],
      machines: [],
      equipaments: [],
      propertyId: this.props.propertyId,
      projectId: this.props.projectId
    };

    this.state = { values: this.copy(this.startValues) };
    if (this.props.projectId) this.reset();
  }

  getFixed = (type, fraction = 2) => {
    let value = _.get(this.state.values, type) || 0;
    if (isNaN(value)) {
      value = value
        .replace("R$", "")
        .replace(/\./g, "")
        .replace(",", ".");
    }
    value = parseFloat(value) || 0;
    return value.toLocaleString("pt-BR", { 
      minimumFractionDigits: fraction, 
      maximumFractionDigits: fraction 
    });
  };

  main = () => {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main className={classes.root}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Paper className={classes.subtitlePaper}>
                <h2 className={classes.subheader}>
                  Custo máximo da madeira e preço mínimo do carvão viáveis
                </h2>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.container}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8} className={classes.external}>
                    <span className={classes.internal}>
                      Custo máximo para produção de madeira (plantio até
                      transporte) para que o projeto seja viável:
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.external}>
                    <Grid container className={classes.internal}>
                      <Grid item sm={12}>
                        <span className={classes.measures}>
                          R$ {this.getFixed("productionMaxViableCost")} /m³
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.container}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={7} className={classes.external}>
                    <span className={classes.internal}>
                      Custo detalhado de obtenção da madeira (plantio próprio e
                      ou compra):
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={5} className={classes.external}>
                    <Grid container className={classes.internal}>
                      <Grid item sm={12}>
                        <span className={classes.measures}>
                          R$ {this.getFixed("detailedObtentionCost")} /m³
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item sm={6}>
              <Paper className={classes.container}>
                <Financial
                  values={this.state.values}
                  handleChange={this.handleChange}
                  validate={this.validate}
                />
              </Paper>
            </Grid>
            <Grid item sm={6}>
              <Paper className={classes.fittedContainer}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} className={classes.external}>
                    <span className={classes.internal}>
                      Considerando os custos de produção e frete e o preço do
                      carvão indicados, a produção de madeira para a
                      carbonização{" "}
                      {this.state.values.viability === true ? (
                        <span className={classes.highlitedGood}>É VIÁVEL</span>
                      ) : (
                        <span className={classes.highlitedBad}>
                          NÃO É VIÁVEL
                        </span>
                      )}
                      .
                    </span>
                  </Grid>
                </Grid>
              </Paper>
              {this.state.values.viability === false ? (
                <Paper className={classes.fittedContainer}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} className={classes.external}>
                      <span className={classes.internal}>
                        Neste caso, o preço mínimo de venda do carvão deve ser
                        de{" "}
                        <span className={classes.highlitedBad}>
                          R$ {this.getFixed("viableCost")}
                        </span>
                        , para que a produção de madeira para carbonização se
                        torne viável.
                      </span>
                    </Grid>
                  </Grid>
                </Paper>
              ) : null}
            </Grid>
          </Grid>
        </main>
      </React.Fragment>
    );
  };
}

export default withRouter(withStyles(styles)(Cost));
