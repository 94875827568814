import _ from "lodash";
import React from "react";
import { withRouter } from "react-router-dom";

import {
  Button,
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanelActions,
  Divider,
  Grid,
  Icon,
  Paper,
  Typography,
  Snackbar
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

import authRequest from "../../../authRequest";
import ConfirmDialog from "../../ConfirmDialog";
import DialogRegister from "../DialogRegister";
import Register from "./Register";
import Empty from "../../Empty";

class Worker extends React.Component {
  constructor(props) {
    super(props);
    const selected = _.get(this.props, "location.state.values") || {};
    this.state = {
      registerOpen: false,
      confirmOpen: false,
      permanentEmployee: [],
      casualWorker: [],
      selectedId: "",
      selected: Object.assign({ _id: "new" }, selected),
      yearOptions: selected
    };
    this.refresh();
  }

  refresh = async () => {
    const permanentEmployee = await authRequest(
      "GET",
      "worker?type=permanentEmployee"
    );
    const casualWorker = await authRequest("GET", "worker?type=casualWorker");
    this.setState({
      permanentEmployee: permanentEmployee.data,
      casualWorker: casualWorker.data
    });
  };

  remove = async () => {
    await authRequest("DELETE", `worker/${this.state.selectedId}`);
    this.setState({ snackmessage: "Funcionário removido com sucesso" });
    this.confirmHandleClose();
    this.refresh();
  };

  rollback = () => {
    this.props.history.goBack();
  };

  registerHandleClickOpen = selected => {
    if (typeof selected === "string") selected = { type: selected };
    if (selected.salary) selected.salary = selected.salary.toLocaleString();
    Object.assign(selected, this.state.yearOptions);
    this.setState({ registerOpen: true, selected });
  };
  registerHandleClose = () => {
    this.setState({ registerOpen: false, selected: null });
    this.refresh();
  };
  confirmHandleClickOpen = selectedId =>
    this.setState({ confirmOpen: true, selectedId });
  confirmHandleClose = () => this.setState({ confirmOpen: false });

  renderFixedWork = () => {
    const { classes } = this.props;
    return (
      <Grid item xs={12} sm={6}>
        <h1 className={classes.title}>Trabalhador Fixo</h1>
        <Container className={classes.root}>
          {this.state.permanentEmployee.length ? (
            this.state.permanentEmployee.map(worker => (
              <ExpansionPanel key={worker._id}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    {worker.name}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container className={classes.main} spacing={2}>
                    <Grid item xs={12} sm={8}>
                      <Typography className={classes.heading}>
                        <b>Nome:</b> {worker.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography className={classes.heading}>
                        <b>Salário:</b> R${" "}
                        {worker.salary.toLocaleString("pt-BR")}
                      </Typography>
                    </Grid>
                    {worker.charges.length ? (
                      <Grid item xs={12} sm={12}>
                        <Typography className={classes.heading}>
                          <b>Encargos:</b>
                        </Typography>
                        <Divider />
                      </Grid>
                    ) : null}
                    {worker.charges.map((charge, key) => {
                      return (
                        <React.Fragment key={key}>
                          <Grid item xs={12} sm={6}>
                            <Typography className={classes.heading}>
                              <b>Tipo do encargo:</b> {charge.type}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography className={classes.heading}>
                              <b>Percentual :</b> {charge.percent} %
                            </Typography>
                          </Grid>
                        </React.Fragment>
                      );
                    })}
                    {worker.ppes.length ? (
                      <Grid item xs={12} sm={12}>
                        <Typography className={classes.heading}>
                          <b>EPI's:</b>
                        </Typography>
                        <Divider />
                      </Grid>
                    ) : null}
                    {worker.ppes.map((ppe, key) => {
                      return (
                        <React.Fragment key={key}>
                          <Grid item xs={12} sm={5}>
                            <Typography className={classes.heading}>
                              <b>Tipo:</b> {ppe.type}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <Typography className={classes.heading}>
                              <b>Ano:</b> {ppe.year}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <Typography className={classes.heading}>
                              <b>Quant.:</b> {ppe.count}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Typography className={classes.heading}>
                              <b>Val. unit.:</b> R${" "}
                              {ppe.unitValue.toLocaleString("pt-BR")}
                            </Typography>
                          </Grid>
                        </React.Fragment>
                      );
                    })}
                  </Grid>
                </ExpansionPanelDetails>
                <ExpansionPanelActions>
                  <Button
                    size="small"
                    onClick={() => this.registerHandleClickOpen(worker)}
                    startIcon={<Icon>create</Icon>}
                  >
                    Editar
                  </Button>
                  <Button
                    size="small"
                    onClick={() => this.confirmHandleClickOpen(worker._id)}
                    startIcon={<Icon>delete</Icon>}
                  >
                    Excluir
                  </Button>
                </ExpansionPanelActions>
              </ExpansionPanel>
            ))
          ) : (
            <Empty message="Ops, nenhum trabalhador fixo ainda cadastrado" />
          )}
        </Container>
        <Grid container spacing={2}>
          <Grid item sm={3}></Grid>
          <Grid item sm={6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => this.registerHandleClickOpen("permanentEmployee")}
            >
              Adicionar Fixo
            </Button>
          </Grid>
          <Grid item sm={3}></Grid>
        </Grid>
      </Grid>
    );
  };

  renderCasualWorker = () => {
    const { classes } = this.props;
    return (
      <Grid item xs={12} sm={6}>
        <h1 className={classes.title}>Trabalhador Eventual</h1>
        <Container className={classes.root}>
          {this.state.casualWorker.length ? (
            this.state.casualWorker.map(worker => (
              <ExpansionPanel key={worker._id}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    {worker.name}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container className={classes.main} spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Typography className={classes.heading}>
                        <b>Nome:</b> {worker.name}
                      </Typography>
                    </Grid>
                    {worker.signings.length ? (
                      <Grid item xs={12} sm={12}>
                        <Typography className={classes.heading}>
                          <b>Contratos:</b>
                        </Typography>
                        <Divider />
                      </Grid>
                    ) : null}
                    {worker.signings.map((signing, key) => {
                      return (
                        <React.Fragment key={key}>
                          <Grid item xs={12} sm={2}>
                            <Typography className={classes.heading}>
                              <b>Ano:</b> {signing.year}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Typography className={classes.heading}>
                              <b>Jor. de Trabalho:</b> {signing.workday}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Typography className={classes.heading}>
                              <b>Unidade:</b> {signing.unit}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Typography className={classes.heading}>
                              <b>Pagamento:</b> R${" "}
                              {signing.payment.toLocaleString("pt-BR")}
                            </Typography>
                          </Grid>
                        </React.Fragment>
                      );
                    })}
                  </Grid>
                </ExpansionPanelDetails>
                <ExpansionPanelActions>
                  <Button
                    size="small"
                    onClick={() => this.registerHandleClickOpen(worker)}
                    startIcon={<Icon>create</Icon>}
                  >
                    Editar
                  </Button>
                  <Button
                    size="small"
                    onClick={() => this.confirmHandleClickOpen(worker._id)}
                    startIcon={<Icon>delete</Icon>}
                  >
                    Excluir
                  </Button>
                </ExpansionPanelActions>
              </ExpansionPanel>
            ))
          ) : (
            <Empty message="Ops, nenhum trabalhador eventual ainda cadastrado" />
          )}
        </Container>
        <Grid container spacing={2}>
          <Grid item sm={3}></Grid>
          <Grid item sm={6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => this.registerHandleClickOpen("casualWorker")}
            >
              Adicionar Eventual
            </Button>
          </Grid>
          <Grid item sm={3}></Grid>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { classes } = this.props;
    const canReturn = _.get(this.props, "location.state.canReturn");
    return (
      <React.Fragment>
        <main className={classes.main}>
          <Paper className={classes.container}>
            <Grid container>
              <Grid item sm={2} className={classes.spacing}>
                {canReturn ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={this.rollback}
                    startIcon={<ArrowBackIcon />}
                  >
                    Voltar
                  </Button>
                ) : null}
              </Grid>
              <Grid item sm={8}>
                <h1 className={classes.title}>Funcionários Cadastrados</h1>
              </Grid>
              {this.renderCasualWorker()}
              {this.renderFixedWork()}
            </Grid>
          </Paper>
        </main>
        <ConfirmDialog
          title="Excluir Funcionário"
          content="Deseja realmente excluir esse funcionário?"
          open={this.state.confirmOpen}
          handleClose={this.confirmHandleClose}
          action={this.remove}
        />
        <DialogRegister
          form={Register}
          type="worker"
          title="Funcionário"
          open={this.state.registerOpen}
          handleClose={this.registerHandleClose}
          selected={this.state.selected}
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          key="message"
          open={this.state.snackmessage ? true : false}
          onClose={() => this.setState({ snackmessage: "" })}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.snackmessage}</span>}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(Worker));
