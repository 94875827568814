import React from "react";

import FormDefault from "../../../../FormDefault";
import MoneyMask from "../../../../Masks/Money";
import NumberMask from "../../../../Masks/Number";

import { Grid } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

export const fields = {};

class Ovens extends FormDefault {
  getInputs = () => {
    const { classes } = this.props;
    return [
      {
        id: "planningHorizon",
        label: "Vida útil dos fornos (anos)",
        disabled: true,
        inputProps: { inputComponent: NumberMask },
      },
      {
        id: "ovens.ovenAmmount",
        label: "Quantidade de fornos",
        disabled: true,
        inputProps: { inputComponent: NumberMask }
      },
      {
        render: (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <h3 className={classes.insideHeader}>1) Construção</h3>
            </Grid>
          </React.Fragment>
        )
      },
      {
        id: "ovens.avgCost",
        label: "Custo médio (/forno)",
        helperText: "Custo médio de construção dos fornos.",
        inputProps: { inputComponent: MoneyMask },
        sm: 12
      },
      {
        render: (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <h3 className={classes.insideHeader}>2) Manutenção</h3>
            </Grid>
          </React.Fragment>
        )
      },
      {
        id: "ovens.avgYearlyCost",
        label: "Custo médio anual (/forno)",
        helperText: "Custo médio anual de manutenção dos fornos.",
        inputProps: { inputComponent: MoneyMask },
        sm: 12
      }
    ];
  };
}

export default withStyles(styles)(Ovens);
