import FormDefault from "../../FormDefault";
import MoneyMask from "../../Masks/Money";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

export const fields = {};

class Financial extends FormDefault {
  getInputs = () => {
    return [
      {
        id: "coalProductionCost",
        label: "Custo de produção do carvão (/mdc)",
        helperText: "Custo médio para produção do carvão.",
        inputProps: { inputComponent: MoneyMask },
        sm: 12
      },
      {
        id: "fareCost",
        label: "Custo do frete (/mdc)",
        inputProps: { inputComponent: MoneyMask }
      },
      {
        id: "coalPrice",
        label: "Preço do carvão (/mdc)",
        helperText: "Preço médio de venda do carvão.",
        inputProps: { inputComponent: MoneyMask }
      }
    ];
  };
}

export default withStyles(styles)(Financial);
