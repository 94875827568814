import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

import ReportGenerate from "./ReportGenerate";

class SteelEmissions extends ReportGenerate {
  constructor(props) {
    super(props);
    this.title = "Emissões da Siderurgia pela carbonização";
  }

  getResult = (emission = "") => {
    let number = emission.replace(/\./g, "");
    number = number.replace(",", ".")
    if(parseFloat(number) > 0) return `PARABÉNS! Cada tonelada de ferro gusa produzida com o seu carvão vegetal contribui para a remoção de ${emission} tCO2e da atmosfera.`;
    return `Cada tonelada de ferro gusa produzida com o seu carvão vegetal contribui para a emissão de ${emission} tCO2e para a atmosfera.`
  }


  getBase = () => {
    return [
      {
        title: "EMISSÕES DA SIDERURGIA PELA CARBONIZAÇÃO",
        papers: [
          {
            fields: [
              { label: "Nome Completo:", value: window.localStorage.username },
              {
                label: "Nome da propriedade:",
                value: this.state.property.name,
                sm: 7
              },
              {
                label: "Tamanho:",
                value: this.state.property.size,
                unit: "ha",
                sm: 5
              },
              {
                label: "Cidade:",
                value: this.state.property.city,
                sm: 7
              },
              {
                label: "Estado:",
                value: this.state.property.state,
                sm: 5
              }
            ]
          },
          {
            fields: [
              { label: "Número do projeto:", value: this.state.project.number },
              {
                label: "Tipo de forno:",
                value: this.state.project.ovenType,
                sm: 7
              },
              {
                label: "Quantidade de fornos:",
                value: this.state.project.ovenAmmount,
                sm: 5
              },
              {
                label: "Queimador de gases:",
                value: this.state.project.gasBurner,
                sm: 7
              },
              {
                label: "Tipo de Queimador:",
                value: this.state.project.gasBurnerType || "-",
                sm: 5
              },
              {
                label: "Monitoramento da temperatura de carbonização:",
                value: this.state.project.carbTempMonitoring,
                sm: 7
              },
              {
                label: "Tipo de Ferramenta:",
                value: this.state.project.toolType || "-",
                sm: 5
              }
            ]
          },
          {
            sm: 6,
            fields: [
              {
                label: "Produção de carvão",
                value: this.getFixed("totalProduction['mdc/ano']", 1),
                unit: "mdc/ano"
              },
              {
                value: this.getFixed("totalProduction['t/ano']", 1),
                unit: "t/ano"
              }
            ]
          },
          {
            sm: 6,
            fields: [
              {
                label: "Teor de carbono fixo",
                value: this.getFixed("fixedCarbonContent", 1),
                unit: "%"
              },
              {
                label: "Total de carbono fixo",
                value: this.getFixed("totalCarbonContent", 1),
                unit: "t Carbono fixo"
              }
            ]
          },
          {
            fields: [
              {
                label: "Balanço de Carbono",
                value: this.getFixed("totalBalance['tCO2e/ano']", 3),
                unit: "tCO2e/ano"
              },
              {
                value: this.getFixed("totalBalance['tCO2e/t Carbono fixo']", 4),
                unit: "tCO2e/t Carbono fixo"
              }
            ]
          },
          {
            sm: 12,
            fields: [
              {
                label: "Demanda para produção de gusa",
                value: this.getFixed(
                  "pigIronProduction['t Carbono fixo/t Gusa']"
                ),
                unit: "t Carbono fixo/t Gusa"
              }
            ]
          }
        ]
      },
      {
        title: "RESULTADO",
        papers: [
          {
            sm: 12,
            fields: [
              {
                label: "Emissão da Siderurgia pela carbonização",
                value: this.getFixed("pigIronProduction['tCO2e/t Gusa']", 4),
                unit: "tCO2e/t Gusa"
              },
              {
                label: "Resultado: ",
                value: this.getResult(this.getFixed("pigIronProduction['tCO2e/t Gusa']", 4))
              }
            ]
          }
        ]
      }
    ];
  };
}

export default withRouter(withStyles(styles)(SteelEmissions));
