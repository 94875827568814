import FormDefault from "../../../../FormDefault";
import MoneyMask from "../../../../Masks/Money";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

export const fields = {};

class Earth extends FormDefault {
  getInputs = () => {
    return [
      {
        type: "number",
        id: "charcoalProductionUnitArea",
        label: "Área da Unidade de Produção de Carvão Vegetal (UPC) (ha)",
        inputProps: { inputProps: { min: 0 } },
        sm: 12
      },
      {
        id: "avgLandPrice",
        label: "Preço médio da terra na região (/ha)",
        inputProps: { inputComponent: MoneyMask },
        sm: 12
      }
    ];
  };
}

export default withStyles(styles)(Earth);
