import MoneyMask from "../../../../Masks/Money";
import FormDefault from "../../../../FormDefault";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

export const fields = {
  woodPurchase: []
};

class Register extends FormDefault {
  constructor(props) {
    super(props);
    this.state = {
      Plantio: [
        {
          id: "plantingInputs.0.year",
          label: "Ano",
          helperText: "Ano de compra do insumo.",
          rightBaloon: true,
          type: "select",
          options: [],
          sm: 1
        },
        {
          type: "select",
          id: "plantingInputs.0.item",
          label: "Item",
          options: [
            "Calcário",
            "Combustível",
            "Cupinicida",
            "Fertilizantes",
            "Formicida",
            "Mudas",
            "Outro"
          ],
          sm: 3
        },
        {
          type: "select",
          id: "plantingInputs.0.unit",
          label: "Unidade",
          options: ["kg", "Litros", "Saca", "Toneladas", "Unidades", "Outra"],
          sm: 2
        },
        {
          id: "plantingInputs.0.count",
          label: "Quantidade",
          type: "number",
          inputProps: { inputProps: { min: 0 } },
          sm: 2
        },
        {
          id: "plantingInputs.0.value",
          label: "Valor (R$/Unidade)",
          inputProps: { inputComponent: MoneyMask },
          sm: 2
        }
      ],
      Manutenção: [
        {
          id: "maintenanceInputs.0.year",
          label: "Ano",
          helperText: "Ano de compra do insumo.",
          rightBaloon: true,
          type: "select",
          options: [],
          sm: 1
        },        
        {
          type: "select",
          id: "maintenanceInputs.0.item",
          label: "Item",
          options: ["Fertilizantes", "Formicida", "Serrote de poda", "Outro"],
          sm: 3
        },
        {
          type: "select",
          id: "maintenanceInputs.0.unit",
          label: "Unidade",
          options: ["kg", "Litros", "Saca", "Toneladas", "Unidades", "Outra"],
          sm: 2
        },
        {
          id: "maintenanceInputs.0.count",
          label: "Quantidade",
          type: "number",
          inputProps: { inputProps: { min: 0 } },
          sm: 2
        },
        {
          id: "maintenanceInputs.0.value",
          label: "Valor (R$/Unidade)",
          inputProps: { inputComponent: MoneyMask },
          sm: 2
        }
      ],
      Colheita: [
        {
          id: "harvestInputs.0.year",
          label: "Ano",
          helperText: "Ano de compra do insumo.",
          rightBaloon: true,
          type: "select",
          options: [],
          sm: 1
        },
        {
          type: "select",
          id: "harvestInputs.0.item",
          label: "Item",
          options: ["Diesel", "Gasolina", "Outro"],
          sm: 3
        },
        {
          type: "select",
          id: "harvestInputs.0.unit",
          label: "Unidade",
          options: ["kg", "Litros", "Saca", "Toneladas", "Unidades", "Outra"],
          sm: 2
        },
        {
          id: "harvestInputs.0.count",
          label: "Quantidade",
          type: "number",
          inputProps: { inputProps: { min: 0 } },
          sm: 2
        },
        {
          id: "harvestInputs.0.value",
          label: "Valor (R$/Unidade)",
          inputProps: { inputComponent: MoneyMask },
          sm: 2
        }
      ],
      Transporte: [
        {
          id: "transportInputs.0.year",
          label: "Ano",
          helperText: "Ano de compra do insumo.",
          rightBaloon: true,
          type: "select",
          options: [],
          sm: 1
        },        
        {
          type: "select",
          id: "transportInputs.0.item",
          label: "Item",
          options: ["Diesel", "Gasolina", "Frete", "Outro"],
          sm: 3
        },
        {
          type: "select",
          id: "transportInputs.0.unit",
          label: "Unidade",
          options: ["kg", "Litros", "Saca", "Toneladas", "Unidades", "Outra"],
          sm: 2
        },
        {
          id: "transportInputs.0.count",
          label: "Quantidade",
          type: "number",
          inputProps: { inputProps: { min: 0 } },
          sm: 2
        },
        {
          id: "transportInputs.0.value",
          label: "Valor (R$/Unidade)",
          inputProps: { inputComponent: MoneyMask },
          sm: 2
        }
      ],
      inputs: [
        {
          title: "Plantio",
          value: "plantingInputs",
          fields: []
        },
        {
          title: "Manutenção",
          value: "maintenanceInputs",
          fields: []
        },
        {
          title: "Colheita",
          value: "harvestInputs",
          fields: []
        },
        {
          title: "Transporte",
          value: "transportInputs",
          fields: []
        }
      ]
    };
  }

  setOptions = array => {
    array.options = [];

    const { values } = this.props;

    for (let i = values.startProject; i <= values.endProject; i++)
      array.options.push(i);
  }

  setYearOptions = () => {
    this.setOptions(this.state["Plantio"][0]);
    this.setOptions(this.state["Manutenção"][0]);
    this.setOptions(this.state["Colheita"][0]);
    this.setOptions(this.state["Transporte"][0]);
  };

  getInputs = () => {
    this.setYearOptions();
    return this.state.inputs;
  };
}

export default withStyles(styles)(Register);
