import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import WithAuth from "./WithAuth";
import Login from "./Login/Login";
import Home from "./Home/Base/Home";
import Register from "./Register/Register";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#284243"
    },
    secondary: {
      main: "#FFF"
    }
  },
  typography: {
    fontFamily: [
      "Khmer MN",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(",")
  }
});

export default class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route path="/login" exact component={Login} />
            <Route path="/" exact component={Login} />
            <Route path="/home" component={WithAuth(Home)} />
            <Route path="/registrar" exact component={Register} />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}
// <Route path="/secret" component={Secret} />
