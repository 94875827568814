const styles = theme => ({
  root: {
    margin: theme.spacing(2),
    height: "62vh",
    maxWidth: "98%",
    overflow: "auto"
  },
  main: {
    height: "99%",
    width: "100%"
  },
  container: {
    height: "99%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  spacing: {
    padding: theme.spacing(1)
  },
  fab: {
    float: "right",
    right: theme.spacing(4)
  },
  title: {
    textAlign: "center",
    fontSize: "3vh",
    fontFamily: "Khmer MN"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  formSpace: {
    paddingRight: theme.spacing(24),
    paddingLeft: theme.spacing(24)
  }
});

export default styles;
