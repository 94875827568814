import FormDefault from "../../../FormDefault";
import { withStyles } from "@material-ui/core/styles";
import styles from "../styles";

import NumberMask from "../../../Masks/Number";

import React from "react";
import { Grid } from "@material-ui/core";

import ages from "./ages";

class Native extends FormDefault {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getInputs = () => {
    const { classes } = this.props;
    return [
      {
        render: (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <h3 className={classes.subheader}>Localização</h3>
            </Grid>
          </React.Fragment>
        )
      },
      {
        type: "select",
        id: "carbonRemoval.localization",
        label: "Localização",
        options: ["Amazônia Legal", "Demais regiões do país"]
      },
      {
        type: "select",
        id: "carbonRemoval.biome",
        label: "Bioma",
        options: [
          "Amazônia",
          "Mata Atlântica",
          "Caatinga",
          "Cerrado",
          "Pampa",
          "Pantanal"
        ]
      },
      {
        type: "select",
        id: "carbonRemoval.situation",
        label: "Situação do Imóvel",
        options: [
          "Situado em área de florestas",
          "Situado em área de cerrado",
          "Situado em área de campos gerais"
        ],
        show: this.props.values.carbonRemoval.localization === "Amazônia Legal",
        sm: 12
      },
      {
        render: (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <h3 className={classes.subheader}>Idade e Área</h3>
            </Grid>
          </React.Fragment>
        )
      },
      {
        type: "select",
        id: "carbonRemoval.age",
        label: "Idade da Floresta (anos)",
        options: ages[this.props.values.carbonRemoval.biome] || [],
        show: ages[this.props.values.carbonRemoval.biome] ? true : false,
        sm: 12
      },
      {
        label: "% de Reserva Legal (RL)",
        id: "carbonRemoval.legalReservePercent",
        disabled: true,
        inputProps: { inputComponent: NumberMask }
      },
      {
        label: "Reserva Legal (em ha)",
        id: "carbonRemoval.legalReserve",
        disabled: true,
        inputProps: { inputComponent: NumberMask }
      },
      {
        type: "number",
        id: "carbonRemoval.app",
        label: "Área de preservação permanente (ha)",
        inputProps: { inputProps: { min: 0 } },
        sm: 12
      }
    ];
  };
}

export default withStyles(styles)(Native);
