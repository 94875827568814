import React from "react";

import {
  Button,
  Grid,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@material-ui/core";

import FormDefault from "../../../../FormDefault";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

import DialogRegister from "../../../Registers/DialogRegister";
import Register from "./Register";

class Input extends FormDefault {
  getInputs = () => {
    const { classes } = this.props;
    return [
      {
        type: "select",
        id: "woodProcurement",
        label: "Tipo da Compra",
        options: ["Em pé", "No pátio"],
        sm: 4
      },
      { render: <Grid item sm={4}></Grid> },
      {
        render: (
          <Grid item sm={4}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={this.props.registerHandleClickOpen}
              className={classes.button}
            >
              Adicionar Compra
            </Button>
          </Grid>
        )
      }
    ];
  };
}

Input = withStyles(styles)(Input);

class WoodPurchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      registerOpen: false,
      confirmOpen: false,
      selectedId: "",
      selected: null
    };
  }

  refresh = () => {
    this.props.reset();
  };

  getFixed = value => {
    if (typeof value === "string") {
      value = value
        .replace("R$", "")
        .replace(/\./g, "")
        .replace(",", ".");
    }
    value = parseFloat(value) || 0;
    return value.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
  };

  registerHandleClickOpen = () => {
    this.props.save();
    this.setState({ registerOpen: true, selected: this.props.values });
  };
  registerHandleClose = refresh => {
    this.setState({ registerOpen: false, selected: null });
    if (refresh) this.refresh();
  };
  confirmHandleClickOpen = selectedId =>
    this.setState({ confirmOpen: true, selectedId });
  confirmHandleClose = () => {
    delete window.localStorage.propertyId;
    this.setState({ confirmOpen: false });
  };

  renderTable = woodProcurement => {
    const { classes } = this.props;
    if (woodProcurement === "Em pé") {
      return (
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Ano</TableCell>
              <TableCell align="center">Quantidade</TableCell>
              <TableCell align="center">Unidade</TableCell>
              <TableCell align="center">Preço Médio (R$/Unidade)</TableCell>
              <TableCell align="center">
                Custo de Colheita (R$/Unidade)
              </TableCell>
              <TableCell align="center">
                Custo de Transporte (R$/Unidade)
              </TableCell>
              <TableCell align="center">Total (R$)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.values.footWoodPurchase.map((row, key) => (
              <TableRow key={key}>
                <TableCell align="center">{row.year}</TableCell>
                <TableCell align="center">{row.count}</TableCell>
                <TableCell align="center">{row.unit}</TableCell>
                <TableCell align="center">
                  R$ {this.getFixed(row.avgPrice)}
                </TableCell>
                <TableCell align="center">
                  R$ {this.getFixed(row.harvestCost)}
                </TableCell>
                <TableCell align="center">
                  R$ {this.getFixed(row.transportCost)}
                </TableCell>
                <TableCell align="center">
                  R$ {this.getFixed(row.total)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    } else if (woodProcurement === "No pátio") {
      return (
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Ano</TableCell>
              <TableCell align="center">Quantidade</TableCell>
              <TableCell align="center">Unidade</TableCell>
              <TableCell align="center">Preço Médio (R$/Unidade)</TableCell>
              <TableCell align="center">Total (R$)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.values.courtyardWoodPurchase.map((row, key) => (
              <TableRow key={key}>
                <TableCell align="center">{row.year}</TableCell>
                <TableCell align="center">{row.count}</TableCell>
                <TableCell align="center">{row.unit}</TableCell>
                <TableCell align="center">
                  R$ {this.getFixed(row.avgPrice)}
                </TableCell>
                <TableCell align="center">
                  R$ {this.getFixed(row.total)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <main>
          <Grid container>
            <Grid item sm={12}>
              <h2 className={classes.subtitle}>Compra da Madeira</h2>
            </Grid>
            <Grid item sm={12}>
              <Paper className={classes.subtitlePaper}>
                <h2 className={classes.subheader}>Custos</h2>
              </Paper>
            </Grid>
          </Grid>
          <Grid item sm={12} className={classes.container}>
            <Input
              values={this.props.values}
              handleChange={this.props.handleChange}
              validate={this.props.validate}
              registerHandleClickOpen={this.registerHandleClickOpen}
            />
          </Grid>
          <TableContainer component={Paper}>
            {this.renderTable(this.props.values.woodProcurement)}
          </TableContainer>
        </main>
        <DialogRegister
          form={Register}
          type="wood"
          title="Compra de Madeira"
          open={this.state.registerOpen}
          handleClose={this.registerHandleClose}
          selected={this.props.values}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(WoodPurchase);
