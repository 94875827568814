import FormDefault from "../../../FormDefault";
import { withStyles } from "@material-ui/core/styles";
import styles from "../styles";

import NumberMask from "../../../Masks/Number";

import React from "react";
import { Divider, Grid } from "@material-ui/core";

class Cooproducts extends FormDefault {
  getInputs = () => {
    const { classes } = this.props;
    return [
      {
        render: (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <h3 className={classes.subheader}>Produção de Atiços</h3>
            </Grid>
          </React.Fragment>
        ),
      },
      {
        id: "generationOfServings",
        label: "Geração de atiços (%)",
        helperText: "O sistema fornece valor default, mas que pode ser alterado.",
        inputProps: {
          inputComponent: NumberMask,
        },
      },
      {
        id: "totalGenerationOfServings",
        disabled: true,
        label: "Geração total de atiços (t/ano)",
        inputProps: { inputComponent: NumberMask },
      },
      {
        render: (
          <Grid item xs={12} sm={12}>
            <Divider />
          </Grid>
        ),
      },
      {
        render: (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <h3 className={classes.subheader}>Produção de Finos</h3>
            </Grid>
          </React.Fragment>
        ),
      },
      {
        render: (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <h3 className={classes.subheader}>
                Unidade de produção de carvão (UPC) - forno
              </h3>
            </Grid>
          </React.Fragment>
        ),
      },
      {
        type: "number",
        id: "coalProductionUnit.value",
        label: "Geração de finos (%)",
        inputProps: {
          inputProps: { min: 0 },
        },
      },
      {
        id: "coalProductionUnit.total",
        disabled: true,
        label: "Geração total de finos (t/ano)",
        inputProps: { inputComponent: NumberMask },
      },
      {
        render: (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <h3 className={classes.subheader}>Carregamento e transporte</h3>
            </Grid>
          </React.Fragment>
        ),
      },
      {
        type: "number",
        id: "loadingAndShipping.value",
        label: "Geração de finos (%)",
        inputProps: {
          inputProps: { min: 0 },
        },
      },
      {
        id: "loadingAndShipping.total",
        label: "Geração total de finos (t/ano)",
        disabled: true,
        inputProps: { inputComponent: NumberMask },
      },
      {
        render: (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <h3 className={classes.subheader}>Peneiramento</h3>
            </Grid>
          </React.Fragment>
        ),
      },
      {
        type: "number",
        id: "sifting.value",
        label: "Geração de finos (%)",
        inputProps: { inputProps: { min: 0 } },
      },
      {
        id: "sifting.total",
        label: "Geração total de finos (t/ano)",
        disabled: true,
        inputProps: { inputComponent: NumberMask },
      },
    ];
  };
}

export default withStyles(styles)(Cooproducts);
