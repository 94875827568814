import FormDefault from "../../../../FormDefault";
import MoneyMask from "../../../../Masks/Money";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

export const fields = {};

class Maintenance extends FormDefault {
  getInputs = () => {
    return [
      {
        id: "maintenance.avgCost",
        label: "Custo médio de manutenção (/ha)",
        helperText: "Custo médio de manutenção do plantio, estradas e aceiros.",
        inputProps: { inputComponent: MoneyMask },
        sm: 12
      },
      {
        id: "maintenance.totalCost",
        label: "Custo total anual (/ano)",
        inputProps: { inputComponent: MoneyMask },
        disabled: true,
        sm: 12
      }
    ];
  };
}

export default withStyles(styles)(Maintenance);
