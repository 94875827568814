import _ from "lodash";
import CalcMachine from "../CalcMachine/CalcMachine";

import divide from "../CalcMachine/divide";
import estimated from "./Charcoal/estimatedRG";
import legalReservePercent from "./CarbonRemoval/legalReservePercent";
import increment from "./increment";

const classCenter = {
  "< 30%": 28,
  "31% - 35%": 33,
  "36% - 40%": 38,
  "> 40%": 43,
};

export default class GEECalcMachine extends CalcMachine {
  calcHangedCicle = (values) => {
    if (values.woodHangedByCicleType === "") {
      const hanged = _.get(values, "woodHangedByCicle['']");
      _.set(values, "woodHangedByCicle['m³/forno']", hanged);
      _.set(values, "woodHangedByCicle['st/forno']", hanged);
    } else {
      _.set(values, "woodHangedByCicle['']", "");
      if (values.woodHangedByCicleType === "st/forno")
        this.calculate("woodHangedByCicle['m³/forno']");
      else this.calculate("woodHangedByCicle['st/forno']");
    }
    this.calculate("woodHangedByCicle['t/forno']");
  };

  calcRG = () => {
    const values = this.state.values,
      project = this.state.project;

    if (
      _.get(values, "rg.estimatedParam") ===
      "Produção total de carvão e quantidade de madeira enfornada"
    ) {
      this.calculate("rg.value");
    } else if (
      _.get(values, "rg.estimatedParam") ===
      "Característica da carbonização e teor de umidade base seca da madeira"
    ) {
      _.set(
        values,
        "rg.value",
        _.get(
          estimated,
          `${project.ovenType}.${project.carbTempMonitoring}.${values.dryBaseMoistureContent}`
        ) || 0
      );
    }
  };

  calcRV = () => {
    const values = this.state.values;

    const set = (type) => {
      _.set(
        values,
        "rv.value",
        divide(
          this.get(`totalHangingWood[${type}]`),
          this.get("totalProduction['mdc/ano']")
        )
      );
    };

    if (_.get(values, "rv.type") === "m³/mdc") set("m³/ano");
    else if (_.get(values, "rv.type") === "st/mdc") set("st/ano");
  };

  calcDemand = (values) => {
    let type = _.get(values, "carbonRemoval.avgProductivityUnit");
    if (type) type = type.replace("ha", "ano");
    _.set(
      values,
      "carbonRemoval.woodDemand",
      divide(
        this.get(`totalHangingWood[${type}]`),
        this.get("carbonRemoval.avgProductivity")
      )
    );
  };

  calcLegalReserve = (values) => {
    const localization = _.get(values, "carbonRemoval.localization") || {};
    if (localization === "Amazônia Legal") {
      const situation = _.get(values, "carbonRemoval.situation");
      _.set(
        values,
        "carbonRemoval.legalReservePercent",
        _.get(legalReservePercent, `${localization}.${situation}`) || 0
      );
    } else {
      _.set(
        values,
        "carbonRemoval.legalReservePercent",
        legalReservePercent[localization] || 0
      );
    }
  };

  calcBulkDensity = (values) => {
    if (values.bulkDensity === 0) this.calculate("bulkDensity");
  };

  calcGasesEmission = (values, project) => {
    const emission = _.get(values, `efectiveEmission['${project.gasBurner}']`);
    _.set(values, "carbonEmission.year['tCO2e']", emission);
  };

  calcMgAvgProductivity = (values) => {
    const avg = _.get(
      values,
      `mgAvgProductivity['${values.carbonRemoval.avgProductivityUnit}']`
    );
    _.set(values, "avgProductivityMg", avg);
  };

  calcIncrement = (values) => {
    this.calculate("increment['plantedArea']");
    this.calculate("plantedArea");

    let nativeFlorest = _.get(increment, _.get(values, "carbonRemoval.biome"));
    if (nativeFlorest && typeof nativeFlorest !== "number")
      nativeFlorest = nativeFlorest[_.get(values, "carbonRemoval.age")];
    _.set(values, "increment['nativeFlorest']", nativeFlorest);

    this.calculate("nativeFlorest");
    this.calculate("totalRemotion");
    this.calculate("totalBalance['tCO2e/ano']");
  };

  updateCalculatedValues = (calcRGeRV = true) => {
    const values = this.state.values,
      project = this.state.project;

    if (!values.stackingFactor) this.calculate("stackingFactor");

    this.calcHangedCicle(values);
    this.calcDemand(values);
    this.calcLegalReserve(values);
    values.classCenter = classCenter[values.dryBaseMoistureContent];
    this.calcBulkDensity(values);
    if (calcRGeRV) {
      this.calcRV();
      this.calcRG();
    }
    this.refreshAlways();
    this.calcGasesEmission(values, project);
    this.calcMgAvgProductivity(values);
    this.calcIncrement(values);
    this.updateValue("", "");
  };
}
