import _ from "lodash";
import React from "react";
import ReactToPrint from "react-to-print";

import { Backdrop, Button, Grid, Snackbar } from "@material-ui/core";

import authRequest from "../authRequest";
import FormControl from "./FormControl";

import SaveIcon from "@material-ui/icons/Save";
import PrintIcon from "@material-ui/icons/Print";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import Empty from "./Empty";

export default class Control extends FormControl {
    componentDidMount() {
        this.setState({
            controls: this.props.history.location.pathname.split("/")[2],
            open: false
        });
    }

    copy = (obj) => JSON.parse(JSON.stringify(obj));

    reset = async (reset = false) => {
        if (this.props.projectId) {
            localStorage.removeItem("saveQuestion")

            await this.getProperty();

            const project = await authRequest(
                "GET",
                `project/${this.props.propertyId}/${this.props.projectId}`
            );
            this.state.project = project.data;

            let values;

            if (reset === true) {
                const _id = this.state.values._id;
                values = _.assign(this.state.values, this.startValues);
                values._id = _id;
                await this.saveAndRefresh()
            } else {
                values = await this.getCurrent(reset);
            }

            this.setState({ values });
            if (this.updateCalculatedValues) this.updateCalculatedValues(false);
            this.setState({ loading: false });
        }
    };

    getProperty = async () => {
        const property = await authRequest(
            "GET",
            `property/${window.localStorage.propertyId}`
        );
        this.setState({ property: property.data, loading: true });
    };

    getCurrent = async (type) => {
        try {
            const gee =
                (await authRequest("GET", `gee/${this.props.projectId}`)) || {};
            if (!gee.data) gee.data = this.copy(this.startValues);
            const wood =
                (await authRequest("GET", `wood/${this.props.projectId}`)) ||
                {};
            if (!wood.data) wood.data = this.copy(this.startValues);
            const coal =
                (await authRequest("GET", `coal/${this.props.projectId}`)) ||
                {};
            if (!coal.data) coal.data = this.copy(this.startValues);

            let current = this.copy(this.startValues);
            if (this.props.type === "gee") {
                current = _.assign(current, coal.data, wood.data, gee.data);
                current._id = gee.data._id;
            } else if (this.props.type === "wood") {
                current = _.assign(current, coal.data, gee.data, wood.data);
                current._id = wood.data._id;
                current.permanentEmployee = wood.data.permanentEmployee;
                current.casualWorker = wood.data.casualWorker;
                current.machines = wood.data.machines;
                current.equipaments = wood.data.equipaments;
                current.maintenanceInputs = wood.data.maintenanceInputs;
            } else {
                current = _.assign(current, gee.data, wood.data, coal.data);
                current._id = coal.data._id;
                current.permanentEmployee = coal.data.permanentEmployee;
                current.casualWorker = coal.data.casualWorker;
                current.machines = coal.data.machines;
                current.equipaments = coal.data.equipaments;
                current.maintenanceInputs = coal.data.maintenanceInputs;
            }

            return current;
        } catch (ex) {
            return this.copy(this.startValues);
        }
    };

    updateValue = (name, value) => {
        if(name && value) {
            localStorage.setItem("saveQuestion", true)
            const values = this.state.values;
            _.set(values, name, value);
            this.setState({ values })
        }
    };

    getFixed = (type, fraction = 2) => {
        let value = _.get(this.state.values, type) || 0;
        value = parseFloat(value);
        return value.toLocaleString("pt-BR", {
            maximumFractionDigits: fraction,
            minimumFractionDigits: fraction,
        });
    };

    renderMain = (classes) => {
        if (!this.props.propertyId)
            return (
                <Empty
                    message="Selecione uma propriedade"
                    img="/assets/seta.png"
                />
            );
        else if (!this.props.projectId)
            return (
                <Empty message="Selecione um projeto" img="/assets/seta.png" />
            );

        const component = this.main(classes);

        if (this.state.controls !== "relatorios") return component;

        return (
            <React.Fragment>
                {component}
                <Empty
                    ref={(el) => {
                        this.componentRef = this;
                    }}
                />
                ;
            </React.Fragment>
        );
    };

    saveAndRefresh = async () => {
        try {
            await this.save();
            this.setState({ snackmessage: "Dados atualizados" });
            await this.reset();
        } catch (e) {
            this.setState({ snackmessage: e.response.data });
        }
    };

    next = async () => {
        await this.saveAndRefresh();
        this.props.history.push(this.nextStep);
    };

    handleSave = async () => {
        this.setState({ open: false });
        this.save()
    }

    renderControls = () => {
        const { classes } = this.props;

        if (this.state.controls === "relatorios") {
            return (
                <React.Fragment>
                    <Grid item sm={2}></Grid>
                    <Grid item sm={2}></Grid>
                    <Grid item sm={2}>
                        <ReactToPrint
                            trigger={() => (
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    startIcon={<PrintIcon />}
                                >
                                    Imprimir
                                </Button>
                            )}
                            content={() => this.componentRef}
                        />
                    </Grid>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <Grid item sm={2}>
                    <Button
                        fullWidth
                        variant="outlined"
                        onClick={this.reset}
                        className={classes.button}
                    >
                        Cancelar
                    </Button>
                </Grid>
                <Grid item sm={2}>
                    <Button
                        fullWidth
                        variant="outlined"
                        onClick={() => this.reset(true)}
                        className={classes.button}
                    >
                        Limpar e Recalcular
                    </Button>
                </Grid>
                <Grid item sm={2}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={this.saveAndRefresh}
                        className={classes.button}
                        startIcon={<SaveIcon />}
                    >
                        Salvar
                    </Button>
                </Grid>
                <Grid item sm={2}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={this.next}
                        className={classes.button}
                        endIcon={<ArrowForwardIcon />}
                    >
                        Próximo
                    </Button>
                </Grid>
            </React.Fragment>
        );
    };

    renderLoading = () => {
        return (
            <Backdrop
                open={this.state.loading ? true : false}
                className="backdrop"
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div id="FireLoading">
                            <img src="/assets/fireloading.gif" alt="Fire" />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div id="Loading">
                            <img src="/assets/loading.gif" alt="Loading" />
                        </div>
                    </Grid>
                </Grid>
            </Backdrop>
        );
    };

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <main className={classes.main}>
                    <Grid container className={classes.buttons} spacing={2}>
                        <Grid
                            item
                            sm={this.state.controls === "relatorios" ? 6 : 4}
                        >
                            <h1 className={classes.title}>{this.title}</h1>
                        </Grid>
                        {this.props.projectId ? this.renderControls() : null}
                    </Grid>
                    {this.renderLoading()}
                    {this.renderMain(classes)}
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        key="message"
                        open={this.state.snackmessage ? true : false}
                        onClose={() => this.setState({ snackmessage: "" })}
                        ContentProps={{
                            "aria-describedby": "message-id",
                        }}
                        message={
                            <span id="message-id">
                                {this.state.snackmessage}
                            </span>
                        }
                    />
                </main>
            </React.Fragment>
        );
    }
}
