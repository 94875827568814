import React from "react";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import authRequest from "../../authRequest";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles.js";

import {
  AppBar,
  FormControl,
  Grid,
  Menu,
  MenuItem,
  InputLabel,
  Select,
  Toolbar,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
  IconButton
} from "@material-ui/core";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import PersonIcon from "@material-ui/icons/Person";

const ocultControls = [
  "/home/dashboard",
  "/home/cadastro/propriedades",
  "/home/cadastro/equipamentos",
  "/home/cadastro/funcionarios",
  "/home/cadastro/maquinas",
  "/home/sobre",
  "/home/contatos"
];

const onlyProperties = ["/home/cadastro/projetos"];

class TopBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      ocultControls: ocultControls.indexOf(this.props.location.pathname) > -1,
      onlyProperties: onlyProperties.indexOf(this.props.location.pathname) > -1
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        ocultControls: ocultControls.indexOf(this.props.location.pathname) > -1,
        onlyProperties:
          onlyProperties.indexOf(this.props.location.pathname) > -1
      });
    }
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  logout = () => {
    window.localStorage.clear();
    this.props.history.push("/login");
  };

  mydata = async () => {
    let values = await authRequest(
      "GET",
      `users/${window.localStorage.userId}`
    );

    values = {
      _id: values.data._id,
      email: values.data.email,
      roles: values.data.roles,
      ...values.data.profile
    };

    this.props.history.push("/registrar", values);
  };

  controls = () => {
    if (this.state.ocultControls) return null;

    const properties = (
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth className={this.props.classes.fullWidth}>
          <InputLabel id="property">Propriedade</InputLabel>
          <Select
            id="property"
            value={this.props.values.propertyId}
            onChange={this.props.handleChange("propertyId")}
            className={this.props.classes.select}
            inputProps={{
              classes: {
                icon: this.props.classes.icon
              }
            }}
          >
            {this.props.properties.map(property => (
              <MenuItem key={property._id} value={property._id}>
                {property.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );

    if (this.state.onlyProperties)
      return (
        <React.Fragment>
          <Grid container spacing={2} className={this.props.classes.controls}>
            {properties}
          </Grid>
        </React.Fragment>
      );

    return (
      <React.Fragment>
        <Grid container spacing={2} className={this.props.classes.controls}>
          {properties}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth className={this.props.classes.fullWidth}>
              <InputLabel id="project">Projeto</InputLabel>
              <Select
                id="project"
                value={this.props.values.projectId}
                onChange={this.props.handleChange("projectId")}
                className={this.props.classes.select}
                inputProps={{
                  classes: {
                    icon: this.props.classes.icon
                  }
                }}
              >
                {this.props.projects.map(project => (
                  <MenuItem key={project._id} value={project._id}>
                    N º {project.number} - {project.ovenAmmount} Fornos
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  render() {
    return (
      <AppBar
        id="topBar"
        position="absolute"
        className={clsx(
          this.props.classes.appBar,
          this.props.open && this.props.classes.appBarShift
        )}
      >
        <Toolbar className={this.props.classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={this.props.handleDrawerToogle}
            className={clsx(
              this.props.classes.menuButton,
              this.props.open && this.props.classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={this.props.classes.title}
          >
            Charcoal System
          </Typography>
          <div className={this.props.classes.controlsContent}>
            {this.controls()}
          </div>
          <Button
            className={this.props.classes.userMenuButton}
            aria-controls="user-menu"
            aria-haspopup="true"
            size="medium"
            startIcon={<AccountCircleIcon />}
            endIcon={<KeyboardArrowDownIcon />}
            onClick={this.handleClick}
          >
            {window.localStorage.username}
          </Button>
          <Menu
            id="user-menu"
            anchorEl={this.state.anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose}
          >
            <MenuItem onClick={this.mydata}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="Meus dados" />
            </MenuItem>
            <MenuItem onClick={this.logout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withRouter(withStyles(styles)(TopBar));
