import React from "react";

import {
    AppBar,
    Button,
    IconButton,
    Container,
    Dialog,
    Grid,
    Toolbar,
    Slide,
    Typography,
    Snackbar,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import FormControl from "../FormControl";

import CloseIcon from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class DialogRegister extends FormControl {
    saveAndClose = async () => {
        try {
            await this.save();
            const type = this.state.values._id
                ? "Atualizado(a)"
                : "Cadastrado(a)";
            this.resetState(`${this.props.title} ${type} com sucesso`);
            this.props.handleClose(true);
        } catch (e) {
            this.setState({
                snackmessage: e.response.data || "Ocorreu algum erro.",
            });
        }
    };

    fixSalary = (selected) => {
        if (selected.salary.indexOf(",") < 0)
            selected.salary = selected.salary * 1000;
        return selected.salary;
    };

    openDialog = async () => {
        if (this.props.selected._id) {
            if (this.props.selected.salary)
                this.props.selected.salary = this.fixSalary(
                    this.props.selected
                );
            this.setState({ values: this.props.selected });
        } else await this.resetState(null, this.props.selected);
    };

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={this.props.open}
                    onEntered={this.openDialog}
                    onClose={this.props.handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar position="static" className={classes.appBar}>
                        <Toolbar>
                            <Typography variant="h6" className={classes.title}>
                                {this.state.values._id
                                    ? "Atualizar "
                                    : "Cadastrar "}
                                {this.props.title}
                            </Typography>
                            <IconButton
                                color="secondary"
                                aria-label="Fechar"
                                onClick={this.props.handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Container className={classes.content}>
                        {
                            <this.props.form
                                values={this.state.values}
                                handleChange={this.handleChange}
                                updateValue={this.updateValue}
                                errors={this.state.errors}
                                validate={this.validate}
                                changeChip={this.changeChip}
                            />
                        }
                        <Grid container className={classes.buttons} spacing={2}>
                            <Grid item sm={2}></Grid>
                            <Grid item sm={4}>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    onClick={this.props.handleClose}
                                    className={classes.button}
                                >
                                    Cancelar
                                </Button>
                            </Grid>
                            <Grid item sm={4}>
                                <Button
                                    fullWidth
                                    disabled={!this.freeButton()}
                                    variant="contained"
                                    color="primary"
                                    onClick={this.saveAndClose}
                                    className={classes.button}
                                >
                                    Salvar
                                </Button>
                            </Grid>
                            <Grid item sm={2}></Grid>
                        </Grid>
                    </Container>
                </Dialog>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    key="message"
                    open={this.state.snackmessage ? true : false}
                    onClose={() => this.setState({ snackmessage: "" })}
                    ContentProps={{
                        "aria-describedby": "message-id",
                    }}
                    message={
                        <span id="message-id">{this.state.snackmessage}</span>
                    }
                />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(DialogRegister);
