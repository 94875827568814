import React, { Component } from 'react'

import "./style.css";

export default class Contacts extends Component {
    render() {
        return (
            <section className="contacts">
                <h1>Contatos</h1>
                <div className="row">
                    <div className="column">
                        <h2>Equipe técnica:</h2>

                        <div className="contact">
                            <h3>Lauana Blenda Silva</h3>
                            <p>Coordenação Geral – GEEA/DEF/UFV</p>
                            <p>E-mail: lauana.silva@ufv.br</p>
                        </div>

                        <div className="contact">
                            <h3>Laércio Antônio Gonçalves Jacovine</h3>
                            <p>Professor – GEEA/DEF/UFV</p>
                            <p>E-mail: jacovine@ufv.br</p>
                            <p>Tel.: (31) 3612-4162</p>                    
                        </div>

                        <div className="contact">
                            <h3>Angélica de Cássia Oliveira Carneiro</h3>
                            <p>Professora – LAPEM/DEF/UFV</p>
                            <p>E-mail: cassiacarneiro@ufv.br</p>
                            <p>Tel.: (31) 3612-4211</p> 
                        </div>
                    </div>
                    <div className="column">
                        <h2>Desenvolvido por:</h2>
                        <img src="../assets/cflogo.png" alt="logo-cf" />
                    </div>
                </div>

                <section className="bottomBar">
                    <div className="whiteBar">
                        <p>Equipe Técnica: </p>
                        <div className="lastP">
                        <img src="../assets/logo-geea.png" alt="logo-geea" />
                        <img src="../assets/logo-lapem.png" alt="logo-lapem" />
                        <img src="../assets/logo-ufv.png" alt="logo-ufv" />
                        </div>
                    </div>
                    <div className="whiteBar">
                        <p>Apoiadores: </p>
                        <img src="../assets/footer-home-transparent.png" alt="Rodapé" />
                    </div>
                </section>
            </section>
        )
    }
}
