import _ from "lodash";
import React from "react";

import {
  Button,
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanelActions,
  Grid,
  Icon,
  Typography
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Empty from "../../Empty";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

class List extends React.Component {
  renderMeasures = (project, classes) => {
    if (project.ovenType === "Circular") {
      return (
        <React.Fragment>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.heading}>
              <b>Diâmetro: </b>
              {_.get(project, "ovenMeasures.diameter")} (m)
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.heading}>
              <b>Altura :</b>
              {_.get(project, "ovenMeasures.height")} (m)
            </Typography>
          </Grid>
        </React.Fragment>
      );
    } else if (project.ovenType === "Retangular") {
      return (
        <React.Fragment>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.heading}>
              <b>Comprimento :</b>
              {_.get(project, "ovenMeasures.length")} (m)
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.heading}>
              <b>Altura :</b>
              {_.get(project, "ovenMeasures.height")} (m)
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.heading}>
              <b>Largura :</b>
              {_.get(project, "ovenMeasures.width")} (m)
            </Typography>
          </Grid>
        </React.Fragment>
      );
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Container className={classes.mini || classes.root}>
          {this.props.projects.length ? (
            this.props.projects.map(project => (
              <ExpansionPanel key={project._id}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Projeto Nº {project.number}
                  </Typography>
                  {this.props.type === "dashboard" ? null : (
                    <Typography className={classes.secondaryHeading}>
                      {project.ovenAmmount} Fornos - {project.ovenType}
                    </Typography>
                  )}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container className={classes.main} spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Typography className={classes.heading}>
                        <b>Nº Projeto:</b> {project.number}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography className={classes.heading}>
                        <b>Tipo do Forno:</b> {project.ovenType}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography className={classes.heading}>
                        <b>Quantidade:</b> {project.ovenAmmount}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography className={classes.heading}>
                        <b>Monitoramento da Temperatura de Carbonização: </b>
                        {project.carbTempMonitoring}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography className={classes.heading}>
                        <b>Tipo de Ferramenta:</b> {project.toolType}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography className={classes.heading}>
                        <b>Queimador de Gases:</b> {project.gasBurner}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography className={classes.heading}>
                        <b>Tipo do Queimador de Gases:</b>{" "}
                        {project.gasBurnerType}
                      </Typography>
                    </Grid>
                    {this.renderMeasures(project, classes)}
                  </Grid>
                </ExpansionPanelDetails>
                <ExpansionPanelActions>
                  <Button
                    size="small"
                    onClick={() => this.props.registerHandleClickOpen(project)}
                    startIcon={<Icon>create</Icon>}
                  >
                    Editar
                  </Button>
                  <Button
                    size="small"
                    onClick={() =>
                      this.props.confirmHandleClickOpen(project._id)
                    }
                    startIcon={<Icon>delete</Icon>}
                  >
                    Excluir
                  </Button>
                </ExpansionPanelActions>
              </ExpansionPanel>
            ))
          ) : this.props.propertyId ? (
            <Empty message="Ops, nenhum projeto ainda cadastrado" />
          ) : (
            <Empty
              img="/assets/seta.png"
              message="Selecione uma propriedade para ver seus projetos"
            />
          )}
        </Container>
        {this.props.propertyId ? (
          <Grid container spacing={2}>
            <Grid item sm={3}></Grid>
            <Grid item sm={6}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.props.registerHandleClickOpen}
              >
                Cadastrar Projeto
              </Button>
            </Grid>
            <Grid item sm={3}></Grid>
          </Grid>
        ) : null}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(List);
