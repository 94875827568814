import _ from "lodash";
import CalcMachine from "../CalcMachine/CalcMachine";

export default class CoalCalcMachine extends CalcMachine {
  calcCaptionPeriods = values => {
    const times = {
      Anual: 1,
      Mensal: 12,
      Bimestral: 6,
      Trimestral: 4,
      Semestral: 2
    };
    values.capturePeriods = values.planningHorizon * times[values.charge];
  };

  _parse = (number = 0) => {
    number = number.toString().replace("R$", "");
    number = number.replace(/\./g, "");
    number = number.replace(/,/g, ".");
    return parseFloat(number);
  };  

  getMachinesAndEquipaments = (values, array, year) => {
    const machinesAndEquipaments = _.filter(values[array], 
      (o) => o.yearsOfUse && o.yearsOfUse.indexOf(values.startProject + year) > -1
    ) || [];        

    return _.sumBy(machinesAndEquipaments, "yearlyCost") || 0;    
  };

  getCasualWorkers = (values, array, year) => {
    const f = n => parseFloat(n);
    let yearCost = 0;
    values[array].forEach((worker) => {
      yearCost += _.sumBy(_.get(worker, "signings"), (o) => {
        if(o.year === values.startProject + year) return f(o.workday) * f(o.payment);
      }) || 0;
    });
    return yearCost;
  }

  getPermanentEmployees = (values, array, year) => {
    const f = n => parseFloat(n);
    let yearCost = 0;
    values[array].forEach((worker) => {
      yearCost = worker.salary * 12;
      const charges = _.reduce(worker.charges, (r, charge) => { return r + f(charge.percent) }, 0);
      yearCost += (charges / 100) * yearCost;
      yearCost += _.sumBy(_.get(worker, "ppes"), (o) => {
        if(o.year === values.startProject + year) return o.total;
      }) || 0;
    });

    return yearCost;
  }

  calcCoalCount = values => {
    const coalCount = { mdc: 0, t: 0 };

    values.recipes.forEach(p => (coalCount[p.unit] += p.count));

    _.set(values, "coalCount", coalCount);
  };

  calcPlanningHorizon = values => {
    const planningHorizon = values.endProject - values.startProject;
    _.set(values, "planningHorizon", planningHorizon.toFixed(0));
  }

  getYearPurchase = (values, year, type) => {
    const f = n => parseFloat(n);
    return _.sumBy(values[type], (o) => {
      if(o.year === values.startProject + year) return f(o.total);
    }) || 0;
  }

  getYearQuant = (values, year, type) => {
    const f = n => parseFloat(n);
    return _.sumBy(values[type], (o) => {
      if(o.year === values.startProject + year) return f(o.count);
    }) || 0;
  }

  getFreight = (year) => {
    const f = n => parseFloat(n);

    const { values } = this.state,
      recipes = _.filter(values.recipes, { year: year + values.startProject }),
      count = _.reduce(recipes, (r, recipe) => r + f(recipe.count), 0);

    return this._parse(_.get(values, "freight.avgFreightCost")) * count;
  }

  getCostDiscriminate = (currentCost, year) => {
    const { values } = this.state;
    const f = (n) => parseFloat(n);

    const fields = ["constructionInputs", "maintenanceInputs", "permanentEmployee", "casualWorker", "machines", "equipaments"];
    let yearCost = fields.reduce((r, array) => {
      let yearCost = 0;
      yearCost += _.sumBy(values[array], (o) => { 
        if(o.year === values.startProject + year) return f(o.total);
      }) || 0;

      yearCost += this.getMachinesAndEquipaments(values, array, year);
      if(array === "casualWorker")
        yearCost += this.getCasualWorkers(values, array, year);
      if(array === "permanentEmployee")
        yearCost += this.getPermanentEmployees(values, array, year);

      return r + yearCost;
    }, f(values.earthYearlyCost) + this.getFreight(year));

    const admin = yearCost * (values.administrationFee / 100);
    const total = admin + yearCost + currentCost;

    return total + this._parse(_.get(values, "otherTaxes"));
  }

  getDivided = type => {
    const divided = {
      ovens: _.get(this.state.values, "planningHorizon"),
      infrastructure: _.get(this.state.values, "infrastructure.lifespan"),
      furnace: _.get(this.state.values, "furnace.lifespan")
    };
    return divided[type];
  }

  getCostFixedByStep = (currentCost, year) => {
    const { values } = this.state;

    const getStepCost = type => {
      let stepCost = 0;
      if(year === 0) stepCost += this._parse(_.get(values, `${type}.totalCost`));
      else if(year <= parseInt(this.getDivided(type))) {
        stepCost += this._parse(_.get(values, `${type}.totalYearlyCost`));
        stepCost += this._parse(_.get(values, `${type}.totalCost`)) / this._parse(this.getDivided(type));
      }
      return stepCost;
    }

    currentCost += getStepCost("ovens");
    currentCost += getStepCost("infrastructure");
    currentCost += getStepCost("furnace");
    currentCost += this.getFreight(year);
    return currentCost;
  }

  getYearlyCost = (currentCost, year, wayOfObtainingData) => {
    if(wayOfObtainingData === "Valor fixo por etapa")
      return this.getCostFixedByStep(currentCost, year);
    return this.getCostDiscriminate(currentCost, year);
  };

  getYearlyRecipe = year => {
    const { values } = this.state;
    const recipes = _.filter(values.recipes, { year: year + values.startProject });
    return _.sumBy(recipes, "total");
  }

  calcTotalObtentionCost = values => {
    const costs = [],
      cjs = [],
      recipes = [],
      rjs = [],
      size = parseInt(values.planningHorizon) + 1 || 0;

    if(size < 0) return;

    const f = n => parseFloat(n);
    const _setCj = (year) => 
      cjs.push(costs[year] * Math.pow(1 + (values.interestRate / 100), - year));

    const _setRj = (year) => 
        rjs.push(recipes[year] * Math.pow(1 + (values.interestRate / 100), - year));

    [...Array(size).keys()].forEach((year) => {
      let yearCost = 0,
        countPurchaseWood = 0;

      yearCost += this.getYearPurchase(values, year, "footWoodPurchase");
      countPurchaseWood += this.getYearQuant(values, year, "footWoodPurchase");
      yearCost += this.getYearPurchase(values, year, "courtyardWoodPurchase");
      countPurchaseWood += this.getYearQuant(values, year, "courtyardWoodPurchase");

      const quant = f(_.get(values, "totalHangingWood['m³/ano']")) - countPurchaseWood,
        woodPlantingCost = f(_.get(values, "detailedObtentionCost")) * quant;

      const woodObtantionCost = yearCost + woodPlantingCost,
        yearlyCost = this.getYearlyCost(woodObtantionCost, year, values.wayOfObtainingData);

      const yearlyRecipes = this.getYearlyRecipe(year);

      costs.push(-yearlyCost);
      recipes.push(yearlyRecipes);
      _setCj(year);
      _setRj(year);
    });

    values.cjs = cjs;
    values.costs = costs;
    values.cj = -(_.sum(cjs));
    values.totalCost = -(_.sum(costs));
    
    values.rjs = rjs;
    values.bruteRecipes = recipes;
    values.rj = _.sum(rjs);
  }

  calcCoalTotalProduction = values => {
    const f = n => parseFloat(n);
    values.coalTotalProduction = _.reduce(values.recipes, (r, recipe) => r + f(recipe.count), 0);
    this.calculate("detailedCoalObtentionCost");
  }

  updateCalculatedValues = () => {
    const values = this.state.values;

    this.calcCaptionPeriods(values);
    this.calcPlanningHorizon(values);
    this.calcCoalCount(values);
    this.calcTotalObtentionCost(values);
    _.set(values, "tir", this.calcTir(values));
    this.calcCoalTotalProduction(values);
    this.refreshAlways();
    this.updateValue("", "");
  };
}
