import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

import ReportGenerate from "./ReportGenerate";

class CarbonBalance extends ReportGenerate {
  constructor(props) {
    super(props);
    this.title = "Balanço de Carbono";
  }

  getResult = (balance = "") => {
    balance = balance.replace(/\./g, "");
    balance = balance.replace(",", ".")
    if(parseFloat(balance) > 0) return "PARABÉNS! O balanço de carbono da sua produção de carvão vegetal é positivo. Isso significa que sua atividade está contribuindo para a mitigação das mudanças climáticas.";
    return "O balanço de carbono da sua produção de carvão vegetal é negativo. Isso significa que sua atividade está contribuindo para a intensificação das mudanças climáticas."
  }

  getBase = () => {
    return [
      {
        title: "BALANÇO DE CARBONO",
        papers: [
          {
            fields: [
              { label: "Nome Completo:", value: window.localStorage.username },
              {
                label: "Nome da propriedade:",
                value: this.state.property.name,
                sm: 7
              },
              {
                label: "Tamanho:",
                value: this.state.property.size,
                unit: "ha",
                sm: 5
              },
              {
                label: "Cidade:",
                value: this.state.property.city,
                sm: 7
              },
              {
                label: "Estado:",
                value: this.state.property.state,
                sm: 5
              }
            ]
          },
          {
            fields: [
              { label: "Número do projeto:", value: this.state.project.number },
              {
                label: "Tipo de forno:",
                value: this.state.project.ovenType,
                sm: 7
              },
              {
                label: "Quantidade de fornos:",
                value: this.state.project.ovenAmmount,
                sm: 5
              },
              {
                label: "Queimador de gases:",
                value: this.state.project.gasBurner,
                sm: 7
              },
              {
                label: "Tipo de Queimador:",
                value: this.state.project.gasBurnerType || "-",
                sm: 5
              },
              {
                label: "Monitoramento da temperatura de carbonização:",
                value: this.state.project.carbTempMonitoring,
                sm: 7
              },
              {
                label: "Tipo de Ferramenta:",
                value: this.state.project.toolType || "-",
                sm: 5
              }
            ]
          }
        ]
      },
      {
        title: "PRODUÇÃO DE CARVÃO VEGETAL E COPRODUTOS",
        papers: [
          {
            subtitle: "Produção líquida de carvão",
            sm: 6,
            fields: [
              {
                label: "Anual",
                value: this.getFixed("totalProduction['mdc/ano']", 1),
                unit: "mdc/ano"
              },
              {
                label: "Anual",
                value: this.getFixed("totalProduction['t/ano']", 1),
                unit: "t/ano"
              }
            ]
          },
          {
            subtitle: "Coprodutos",
            sm: 6,
            fields: [
              {
                label: "Atiços",
                value: this.getFixed("totalGenerationOfServings", 1),
                unit: "t/ano"
              },
              {
                label: "Finos",
                value: this.getFixed("totalGenerationOfFines", 1),
                unit: "t/ano"
              }
            ]
          }
        ]
      },
      {
        title: "EMISSÕES DE GEE DA PRODUÇÃO DE CARVÃO VEGETAL",
        papers: [
          {
            subtitle: "Emissões de metano (CH4)",
            sm: 6,
            fields: [
              {
                label: "Anual",
                value: this.getFixed("methane.year['tCH4']", 3),
                unit: "tCH4"
              },
              {
                label: "Por ciclo",
                value: this.getFixed("methane.cicle['tCH4']", 3),
                unit: "tCH4"
              },
              {
                label: "Por tonelada de carvão",
                value: this.getFixed("methane.tonne['kgCH4']", 3),
                unit: "kgCH4"
              },
              {
                label: "Por MDC",
                value: this.getFixed("methane.mdc['kgCH4']", 3),
                unit: "kgCH4"
              }
            ]
          },
          {
            subtitle: "-",
            sm: 6,
            fields: [
              {
                value: this.getFixed("methane.year['tCO2e']", 3),
                unit: "tCO2e"
              },
              {
                value: this.getFixed("methane.cicle['tCO2e']", 3),
                unit: "tCO2e"
              },
              {
                value: this.getFixed("methane.tonne['kgCO2e']", 3),
                unit: "kgCO2e"
              },
              {
                value: this.getFixed("methane.mdc['kgCO2e']", 3),
                unit: "kgCO2e"
              }
            ]
          },
          {
            subtitle: "Emissão de dióxido de carbono (CO2)",
            sm: 12,
            fields: [
              {
                label: "Anual",
                value: this.getFixed("carbonEmission.year['tCO2e']", 3),
                unit: "tCO2e"
              },
              {
                label: "Por ciclo",
                value: this.getFixed("carbonEmission.cicle['tCO2e']", 3),
                unit: "tCO2e"
              },
              {
                label: "Por tonelada de carvão",
                value: this.getFixed("carbonEmission.tonne['kgCO2e']", 3),
                unit: "kgCO2e"
              },
              {
                label: "Por MDC",
                value: this.getFixed("carbonEmission.mdc['kgCO2e']", 3),
                unit: "kgCO2e"
              }
            ]
          },
          {
            subtitle: "Emissão total",
            sm: 12,
            fields: [
              {
                label: "Anual",
                value: this.getFixed("totalEmission.year['tCO2e']", 3),
                unit: "tCO2e"
              },
              {
                label: "Por ciclo",
                value: this.getFixed("totalEmission.cicle['tCO2e']", 3),
                unit: "tCO2e"
              },
              {
                label: "Por tonelada de carvão",
                value: this.getFixed("totalEmission.tonne['tCO2e']", 3),
                unit: "tCO2e"
              },
              {
                label: "Por MDC",
                value: this.getFixed("totalEmission.mdc['tCO2e']", 3),
                unit: "tCO2e"
              }
            ]
          }
        ]
      },
      {
        title: "REMOÇÃO DE CARBONO",
        papers: [
          {
            subtitle: "Áreas Plantadas",
            sm: 6,
            fields: [
              {
                label: "Estoque total",
                value: this.getFixed("plantedArea", 3),
                unit: "tCO2e"
              }
            ]
          },
          {
            subtitle: "Florestas Nativas",
            sm: 6,
            fields: [
              {
                label: "Anual",
                value: this.getFixed("nativeFlorest", 3),
                unit: "tCO2e"
              }
            ]
          },
          {
            subtitle: "Remoção total",
            fields: [
              {
                label: "Anual",
                value: this.getFixed("totalRemotion", 3),
                unit: "tCO2e"
              }
            ]
          }
        ]
      },
      {
        title: "BALANÇO DE CARBONO TOTAL",
        papers: [
          {
            fields: [
              {
                label: "Emissão Total",
                value: this.getFixed("totalEmission.year['tCO2e']", 3),
                unit: "tCO2e/ano"
              },
              {
                label: "Remoção Total",
                value: this.getFixed("totalRemotion", 3),
                unit: "tCO2e/ano"
              },
              {
                label: "Balanço de Carbono Total",
                value: this.getFixed("totalBalance['tCO2e/ano']", 3),
                unit: "tCO2e/ano"
              },
              {
                label: "Resultado: ",
                value: this.getResult(this.getFixed("totalBalance['tCO2e/ano']", 3)),
              }
            ]
          }
        ]
      }
    ];
  };
}

export default withRouter(withStyles(styles)(CarbonBalance));
