import FormDefault from "../../../../FormDefault";

import NumberMask from "../../../../Masks/Number";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles";

export const fields = {};

class Taxes extends FormDefault {
    changeInterestRate = (event) => {
        this.props.updateValue("interestRate", event.target.value);
        this.props.updateValue(
            "minimumRateOfAttractiveness",
            event.target.value
        );
    };

    getInputs = () => {
        return [
            {
                type: "number",
                id: "interestRate",
                label: "Taxa de juros (%)",
                inputProps: {
                    inputProps: { min: 0 },
                    onChange: this.changeInterestRate,
                },
            },
            {
                type: "select",
                id: "charge",
                label: "Cobrança",
                options: [
                    "Anual",
                    "Mensal",
                    "Bimestral",
                    "Trimestral",
                    "Semestral",
                ],
            },
            {
                type: "number",
                id: "administrationFee",
                label: "Taxa de administração e tributação (%)",
                inputProps: { inputProps: { min: 0 } },
            },
            {
                type: "number",
                id: "minimumRateOfAttractiveness",
                label: "Taxa min de atratividade (%)",
                inputProps: { inputProps: { min: 0 } },
            },
            {
                id: "capturePeriods",
                label: "Períodos de captação",
                disabled: true,
                inputProps: { inputComponent: NumberMask },
                sm: 12,
            },
        ];
    };
}

export default withStyles(styles)(Taxes);
