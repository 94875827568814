import axios from "axios";

const methods = {
  GET: async (url, params, headers) =>
    await axios.get(url, { ...params, headers }),
  POST: async (url, data, headers) => await axios.post(url, data, { headers }),
  PUT: async (url, data, headers) => await axios.put(url, data, { headers }),
  DELETE: async (url, params, headers) =>
    await axios.delete(url, { ...params, headers })
};

const authRequest = async (method, endpoint, data = {}) => {
  const headers = { authToken: window.localStorage.authToken };

  try {
    return await methods[method](
      `${process.env.REACT_APP_BACKEND_URL}/${endpoint}`,
      data,
      headers
    );
  } catch (e) {
    if (e.response.status === 401) {
      window.localStorage.clear();
      window.location.href = "/login";
    }
    throw e;
  }
};

export default authRequest;
