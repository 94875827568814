import _ from "lodash";
import React from "react";

import { Fab, Divider, Grid, Typography } from "@material-ui/core";

import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

import Input from "./FormInputs";

export default class FormDefault extends React.Component {
    renderInput = (input, key) => {
        return (
            <React.Fragment key={key}>
                {input.show === false ? null : input.render ? (
                    input.render
                ) : (
                    <Input
                        type={input.type}
                        inputProps={input.inputProps}
                        id={input.id}
                        label={input.label}
                        required={input.required}
                        disabled={input.disabled}
                        options={input.options}
                        helperText={input.helperText}
                        rightBaloon={input.rightBaloon}
                        props={this.props}
                        sm={input.sm}
                    />
                )}
            </React.Fragment>
        );
    };

    onEdit = (input) => {
        this.props.values[input.value].forEach((value) => {
            const index = _.findIndex(this.state.inputs, {
                    title: input.title,
                }),
                render = this.state.inputs[index];

            render.fields.push(this.state[input.title]);

            this.setState({
                [`inputs.${index}`]: render,
                current: this.props.values._id,
            });
        });
    };

    componentDidUpdate() {
        const current = _.get(this.props, "values._id");
        if (current && current !== _.get(this.state, "current")) {
            this.getInputs().forEach((input) => {
                if (input.fields && this.props.values[input.value])
                    this.onEdit(input);
            });
        }
    }

    add = (title) => {
        const index = _.findIndex(this.state.inputs, { title }),
            input = this.state.inputs[index];

        console.log(input.fields)
        input.fields.push(this.state[title]);
        this.props.values[input.value].push({});

        this.setState({ [`inputs.${index}`]: input });
    };

    remove = (title) => {
        const index = _.findIndex(this.state.inputs, { title }),
            input = this.state.inputs[index];

        input.fields.pop();
        this.props.values[input.value].pop();

        this.setState({ [`inputs.${index}`]: input });
    };

    controls = (input, key) => {
        return (
            <React.Fragment key={key}>
                <Grid item sm={2}>
                    <Fab
                        size="small"
                        onClick={() => this.remove(input.title)}
                        color="primary"
                        aria-label="remove"
                    >
                        <RemoveIcon />
                    </Fab>
                </Grid>
            </React.Fragment>
        );
    };

    getHeader = (input) => {
        return (
            <React.Fragment key={input.title}>
                <Grid item sm={10}>
                    <Typography component="h1" variant="h6" color="primary">
                        {input.title}
                    </Typography>
                </Grid>
                <Grid key="add" item sm={2}>
                    <Fab
                        size="small"
                        onClick={() => this.add(input.title)}
                        color="primary"
                        aria-label="add"
                    >
                        <AddIcon />
                    </Fab>
                </Grid>
                <Grid item sm={12}>
                    <Divider />
                </Grid>
            </React.Fragment>
        );
    };

    interpretInputs = (input, key) => {
        if (input.fields) {
            let inputs = [this.getHeader(input)];

            input.fields.forEach((line, index) => {
                inputs = inputs.concat(
                    line.map((field) => {
                        const valueArray = field.id.split(".")[0];
                        const property = field.id.split(".")[2];
                        if (field.id.indexOf(index) < 0) {
                            field.id = `${valueArray}.${index}.${property}`;
                            field.id = field.id.replace(
                                /.[0-9]+./g,
                                `.${index}.`
                            );
                        }
                        return this.renderInput(field, field.id);
                    })
                );
                if (index !== input.fields.length - 1)
                    inputs.push(
                        <Grid key={`white${index}`} item sm={2}></Grid>
                    );
            });

            if (inputs.length > 1)
                inputs.push(this.controls(input, `control${key}`));

            return inputs;
        } else {
            return this.renderInput(input, key);
        }
    };

    render() {
        const { classes } = this.props;
        const inputs = this.getInputs();
        return (
            <React.Fragment>
                <Grid container className={classes.formSpace} spacing={2}>
                    {inputs.map((input, key) =>
                        this.interpretInputs(input, key)
                    )}
                </Grid>
            </React.Fragment>
        );
    }
}
