import React from "react";
import MaskedInput from "react-text-mask";

const mask = [
  /\d/,
  /\d/,
  "º",
  " ",
  /\d/,
  /\d/,
  "'",
  " ",
  /\d/,
  /\d/,
  '"',
  " ",
  /./,
  /./,
  /./,
  /./,
  /./
];

export default function ZipcodeMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}
