import FormDefault from "../../../FormDefault";
import { withStyles } from "@material-ui/core/styles";
import styles from "../styles";

import NumberMask from "../../../Masks/Number";

import React from "react";
import { Grid } from "@material-ui/core";

class RV extends FormDefault {
  changeRV = event => {
    this.props.updateValue("rv.value", event.target.value);
    this.props.updateCalculatedValues(false);
  };

  getInputs = () => {
    const { classes } = this.props;
    return [
      {
        render: (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <h3 className={classes.subheader}>Rendimento Volumétrico</h3>
            </Grid>
          </React.Fragment>
        )
      },
      {
        id: "rv.value",
        label: "Rendimento Volumétrico",
        helperText: "Selecione primeiro a unidade. O sistema fornece um valor estimado, mas que pode ser alterado.",
        inputProps: { 
          inputComponent: NumberMask,
          onChange: this.changeRV 
        },
        sm: 12
      },
      {
        type: "select",
        id: "rv.type",
        label: "Unidade",
        options: ["m³/mdc", "st/mdc"],
        sm: 12
      }
    ];
  };
}

export default withStyles(styles)(RV);
