import React from "react";
import _ from "lodash";
import "../../core.css";

import authRequest from "../../authRequest";
import { Route } from "react-router-dom";

import { CssBaseline, Box, Container, Grid } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles.js";

import TopBar from "./TopBar";
import LeftBar from "./LeftBar";
import Copyright from "../../Copyright";

import routes from "./Routes";

class Home extends React.Component {
  constructor(props) {
    super(props);

    const url = this.getUrl(),
      initialOpen = url.split("/")[0],
      side = window.localStorage.sideMenu;

    this.state = {
      open: side ? side === "true" : true,
      selectedIndex: `/${url}`,
      initialOpen: `/${initialOpen}`,
      properties: [],
      projects: [],
      values: {
        propertyId: "",
        projectId: ""
      }
    };
  }

  getUrl = () => {
    const url = this.props.history.location.pathname.split("/");
    return url.splice(2, url.length).join("/");
  };

  async componentDidMount() {
    await this.load();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) await this.load();
  }

  handleDrawerToogle = () => {
    window.localStorage.sideMenu = !this.state.open;
    this.setState({ open: !this.state.open });
  };

  handleListItemClick = (event, index) => {
    const redirect = () => {
      localStorage.removeItem("saveQuestion")
      this.props.history.push(`/home${index}`);
      this.setState({ selectedIndex: index });
    }

    if(localStorage.getItem("saveQuestion")) {
      const response = window.confirm("Existem alterações não salvas. Deseja sair mesmo assim?")
      if(response) redirect()
    } else {
      redirect()
    }
  };

  _getProjects = async () => {
    let projects = this.state.projects;
    projects = await authRequest(
      "GET",
      `project/${window.localStorage.propertyId}`
    );
    projects = _.get(projects, "data");
    return projects;
  };

  handleChange = name => async event => {
    const values = this.state.values;
    let projects = this.state.projects;
    window.localStorage.setItem(name, event.target.value);
    if (name === "propertyId") {
      projects = await this._getProjects();
      window.localStorage.removeItem("projectId");
      _.set(values, "projectId", "");
    }
    _.set(values, name, event.target.value);
    this.setState({ values, projects });
  };

  load = async () => {
    const url = this.getUrl();
    const properties = await authRequest("GET", "property");
    let projects = [];

    if (window.localStorage.propertyId) projects = await this._getProjects();

    this.setState({
      selectedIndex: `/${url}`,
      projects,
      properties: properties.data,
      values: {
        ...this.state.values,
        propertyId: window.localStorage.propertyId || "",
        projectId: window.localStorage.projectId || ""
      }
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <TopBar
          open={this.state.open}
          handleDrawerToogle={this.handleDrawerToogle}
          selectedIndex={this.selectedIndex}
          handleChange={this.handleChange}
          values={this.state.values}
          properties={this.state.properties}
          projects={this.state.projects}
        />
        <LeftBar
          open={this.state.open}
          handleDrawerToogle={this.handleDrawerToogle}
          selectedIndex={this.state.selectedIndex}
          handleListItemClick={this.handleListItemClick}
          initialOpen={this.state.initialOpen}
        />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Grid
              direction="column"
              container
              spacing={2}
              className={classes.columnGrid}
            >
              <img src="/logo.png" alt="logo" className={classes.backimage} />
              {routes.map((route, key) => (
                <Route
                  key={key}
                  path={route.path}
                  exact
                  component={props => (
                    <route.component
                      propertyId={this.state.values.propertyId}
                      projectId={this.state.values.projectId}
                      openProperty={this.openProperty}
                      type={route.type}
                    />
                  )}
                />
              ))}
            </Grid>
            <Box pt={2}>
              <Copyright />
            </Box>
          </Container>
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(Home);
