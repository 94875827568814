const increment = {
  Amazônia: {
    Primária: 1.2990000000000000,
    "Primária recém manejada": 1.9450000000000000,
    "< 2": 12.014375000000000000,
    "10 < idade < 15": 4.2829773636363600,
    "15 < idade < 20": 5.6572425426136400,
    "2 < idade < 5": 5.7803333333333300,
    "5 < idade < 10": 3.8297685185185200,
    "> 20": 2.4218880000000000
  },
  Caatinga: {
    "< 10": 3.0516666666666700,
    "> 10": 0.4576407407407410
  },
  Cerrado: 1.0333333333333300,
  "Mata Atlântica": {
    "< 10": 4.7528225108225100,
    "10 < idade < 20": 3.6801503759398500,
    "20 < idade < 30": 2.3201111111111100,
    "> 30": 1.7414061224489800
  },
  Pampa: 1.2791670512864500,
  Pantanal: 1.7560317460317500
};

export default increment;
