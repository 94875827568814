import React from "react";
import axios from "axios";

import FormDefault from "../FormDefault";
import ZipcodeMask from "../Masks/Zipcode";

import { CircularProgress, Grid } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

class Address extends FormDefault {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  getAddress = async () => {
    const rawzip = this.props.values.zipcode
      .replace(/\s/g, "")
      .replace("-", "");
    if (rawzip.length === 0)
      this.props.validate("zipcode", "CEP é obrigatório");
    else if (rawzip.length > 7) {
      this.setState({ loading: true });
      const response = await axios.get(
        `https://viacep.com.br/ws/${rawzip}/json/`
      );
      if (response.data.erro)
        this.props.validate("zipcode", "CEP Inválido", true);
      else {
        this.props.values.street = response.data.logradouro || "";
        this.props.values.district = response.data.bairro || "";
        this.props.values.city = response.data.localidade || "";
        this.props.values.state = response.data.uf || "";
        this.props.validate("zipcode", "CEP Inválido", false);
      }
      this.setState({ loading: false });
    } else {
      this.props.validate("zipcode", "CEP Inválido", rawzip.length <= 7);
    }
  };

  getInputs = () => {
    const { classes } = this.props;
    return [
      {
        id: "zipcode",
        label: "CEP",
        required: true,
        inputProps: {
          autoFocus: true,
          inputComponent: ZipcodeMask,
          onKeyDown: this.getAddress,
          onBlur: this.getAddress
        }
      },
      {
        render: (
          <Grid item xs={12} sm={6} className={classes.progress}>
            {this.state.loading === true ? <CircularProgress /> : null}
          </Grid>
        )
      },
      { id: "street", label: "Logradouro", required: true, sm: 8 },
      {
        type: "number",
        id: "number",
        label: "Número",
        required: true,
        sm: 4,
        inputProps: { inputProps: { min: 0 } }
      },
      { id: "complement", label: "Complemento" },
      { id: "district", label: "Bairro", required: true },
      { id: "city", label: "Cidade", required: true, sm: 8 },
      { id: "state", label: "UF", required: true, sm: 4 },
      {
        type: "select",
        id: "region",
        label: "Região do País",
        required: true,
        sm: 12,
        options: ["Centro-oeste", "Nordeste", "Norte", "Sul", "Sudeste"]
      }
    ];
  };
}

export default withStyles(styles)(Address);
