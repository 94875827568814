const styles = theme => ({
  root: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    height: "38vh",
    overflow: "auto"
  },
  mini: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    height: "15vh",
    overflow: "auto"
  },
  demo: {
    padding: "0"
  },
  main: {
    width: "100%"
  },
  "container-small": {
    padding: theme.spacing(2),
    height: "20.5vh"
  },
  "container-medium": {
    height: "34.5vh"
  },
  "container-larger": {
    padding: theme.spacing(2),
    height: "48.5vh"
  },
  external: {
    display: "table",
    height: "6vh"
  },
  internal: {
    fontSize: "2vh",
    verticalAlign: "middle",
    color: "theme.palette.primary.main",
    textAlign: "center",
    display: "table-cell"
  },
  title: {
    fontSize: "4vh",
    fontFamily: "Khmer MN"
  },
  subtitle: {
    fontSize: "2.5vh",
    fontFamily: "Khmer MN"
  },
  subheader: {
    fontSize: "2vh",
    fontFamily: "Khmer MN",
    color: theme.palette.primary.main,
    display: "block",
    textAlign: "center"
  },
  measures: {
    color: "#2B4F3C",
    textAlign: "center",
    fontSize: "2vh",
    fontWeight: "bold"
  },
  heading: {
    fontSize: theme.typography.pxToRem(13),
    flexBasis: "100%"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  buttons: {
    alignItems: "center"
  },
  progress: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    height: "100%",
    justifyContent: "left"
  },
  center: {
    margin: "auto"
  }
});

export default styles;
